<template>
  <div class="mb-5 md:mb-8">
    <section class="bg-gray-light pt-4 pb-2 md:pt-4 md:pb-6">
      <div class="container-md">
        <div class="flex flex-wrap">
          <div
            v-for="(item, idx) in items"
            :key="idx"
            class="w-1/2 md:w-1/4 text-center mb-4 md:mb-0"
          >
            <!-- @todo icon integration-->
            <icon
              v-if="item.icon"
              :icon="item.icon"
              :class="item.class"
              :width="item.width"
              :height="item.height"
            />
            <span
              v-if="item.heading"
              class="font-sans uppercase text-xs leading-tight block opacity-50"
              >{{ item.heading }}</span
            >
            <span
              v-if="item.subHeading"
              class="font-sans text-xs leading-tight block opacity-50"
              >{{ item.subHeading }}</span
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Icon from "@/js/components/shared/Icon.vue";
/**
 * @todo  wire up accordion content
 * @todo  wire up helpcentre url
 */
export default {
  components: {
    Icon,
  },
  data() {
    return {
      items: [
        {
          icon: "sp-trip-advisor",
          heading: "London's Favourite",
          subHeading: "Immersive Night Out *",
          class: "opacity-50 w-14 md:w-16 mx-auto mb-1",
          width: 32,
          height: 30,
        },
        {
          icon: "sp-98-survey",
          heading: "Of Customers",
          subHeading: "Recommend It *",
          class: "opacity-50 w-16 md:w-20 mx-auto mb-1 -mt-5",
          width: 32,
          height: 32,
        },
        {
          icon: "sp-250k",
          heading: "VR Experiences",
          subHeading: "Delivered since 2018",
          class: "opacity-50 w-12 md:w-16 mx-auto mb-1 mt-1",
          width: 32,
          height: 28,
        },
        {
          icon: "sp-thea-award",
          heading: "THEA Global",
          subHeading: "Award winner",
          class: "opacity-50 w-12 md:w-14 mx-auto mb-2 scale-150",
          width: 32,
          height: 32,
        },
      ],
    };
  },
};
</script>
