<template>
  <section class="border border-gray mb-3">
    <app-accordion
      a11y-title="order summary"
      wrapper-classes=""
      content-classes=""
      :start-expanded="true"
    >
      <template #heading>
        <span class="flex-1 pr-3 font-display text-xl md:text-2xl h-12">
          {{ selectedDate }}, {{ selectedTime }}
          <span class="text-xs block font-sans">
            {{
              totalQuantity > 1
                ? `${totalQuantity} items`
                : `${totalQuantity} item`
            }}, <span class="link">View Order</span></span
          >
        </span>
      </template>

      <table class="mb-2 w-full">
        <checkout-order-summary-item
          v-for="(ticket, idx) in ticketsForCheckout"
          :key="idx"
          :product-id="ticket.lineupId"
          :removeable="true"
          @remove="handleRemoveTicket"
        >
          <template #label
            >{{ ticket.quantity }} &times; {{ ticket.title }} Tickets @
            <span v-html="formatPrice(ticket.faceValueTotal)"
          /></template>
          <template #value
            ><span v-html="formatPrice(ticket.faceValueTotal * ticket.quantity)"
          /></template>
        </checkout-order-summary-item>
        <checkout-order-summary-item
          v-for="product in productsForCheckout"
          :key="product.lineupId"
          :product-id="product.lineupId"
          :removeable="true"
          @remove="handleRemoveProduct"
        >
          <template #label
            >{{ product.quantity }} &times; {{ product.title }} @
            <span v-html="formatPrice(product.price)"
          /></template>
          <template #value
            ><span v-html="formatPrice(product.price * product.quantity)"
          /></template>
        </checkout-order-summary-item>
        <checkout-order-summary-item
          v-if="eventTotalPrice.discount"
          :removeable="false"
        >
          <template #label
            >Your discount
            {{
              companionTicket ? "(Including carer ticket discount)" : ""
            }}</template
          >
          <template #value
            ><span v-html="`- ${formatPrice(eventTotalPrice.discount)}`"
          /></template>
        </checkout-order-summary-item>
      </table>
    </app-accordion>
    <div class="pt-2 p-3">
      <table class="w-full">
        <checkout-order-summary-item
            v-if="eventTotalPrice.bookingFee"
            :removeable="false">
          <template #label>
            <span id="subtotal" class="font-display text-lg">
              Subtotal
            </span>
          </template>
          <template #value>
            <span
              class="font-display text-lg"
              aria-labelled-by="subtotal"
              v-html="formatPrice(eventTotalPrice.subtotal)"
            />
          </template>
        </checkout-order-summary-item>

        <checkout-order-summary-item
            v-if="eventTotalPrice.bookingFee"
            :removeable="false">
          <template #label>
            <span id="bookingFee" class="font-display ">
              Booking Fees
            </span>
          </template>
          <template #value>
            <span
                class="font-display "
                aria-labelled-by="bookingFee"
                v-html="formatPrice(eventTotalPrice.bookingFee)"
            />
          </template>
        </checkout-order-summary-item>
        <checkout-order-summary-item :removeable="false">
          <template #label>
            <span id="eventTotalPrice" class="uppercase font-display font-semibold text-2xl">
              Total
            </span>
          </template>
          <template #value>
            <span
                class="uppercase font-display font-semibold text-2xl"
                aria-labelled-by="eventTotalPrice"
                v-html="formatPrice(eventTotalPrice.afterDiscount)"
            />
          </template>
        </checkout-order-summary-item>
      </table>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { formatPrice, formatPriceShort } from "@/js/helpers/NumberHelper.js";
import { formatDateWithYear, formatTime } from "@/js/helpers/DateHelper.js";

import AppAccordion from "@/js/components/shared/AppAccordion.vue";
import CheckoutOrderSummaryItem from "@/js/components/checkout/CheckoutOrderSummaryItem.vue";

export default {
  components: {
    AppAccordion,
    CheckoutOrderSummaryItem,
  },
  computed: {
    ...mapGetters([
      "eventTotalPrice",
      "totalQuantity",
      "productsForCheckout",
      "ticketsForCheckout",
    ]),
    ...mapState(["suppliedDiscountCode", "companionTicket"]),
    ...mapState({
      selectedDate: ({ selectedTicketGroup }) =>
        formatDateWithYear(selectedTicketGroup?.date),
      time: ({ selectedPerformance }) => selectedPerformance?.time,
    }),
    selectedTime() {
      return formatTime(`${this.selectedDate} ${this.time}`);
    },
  },
  methods: {
    formatPrice,
    formatPriceShort,
    handleRemoveTicket() {
      this.$store.commit("setClearBasketConfirmationActive", true);
    },
    handleRemoveProduct(productId) {
      this.$store.commit("removeSelectedProduct", productId);
      this.$emit("change");
    },
  },
};
</script>
