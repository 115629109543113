<template>
  <label :for="id" class="focus:ring focus:outline-none text-sm">
    <span class="mr-2 cursor-pointer font-sans">{{ label }}</span>
    <div class="toggle">
      <input
        :id="id"
        :name="id"
        :checked="isChecked"
        value="1"
        type="checkbox"
        class="sr-only"
        @change="$emit('toggle', !isChecked)"
      />
      <div class="track" aria-hidden="true"></div>
      <div class="thumb" aria-hidden="true"></div>
    </div>
  </label>
</template>
<script>
import { toKebabCase } from "@/js/helpers/StringHelper.js";

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return `${toKebabCase(this.label)}-${this._uid}`;
    },
  },
};
</script>
