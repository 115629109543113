<template>
  <app-modal max-w="xl">
    <h2 class="text-center">Your ticket reservation has expired</h2>
    <p class="text-center">
      Please choose some new tickets to complete your order.
    </p>
    <div class="bg-gray-light p-3 flex flex-wrap justify-center">
      <app-button
        class="w-full sm:w-1/2"
        :variants="['block', 'gradient']"
        @click="submit()"
        >OK</app-button
      >
    </div>
  </app-modal>
</template>
<script>
import AppButton from "@/js/components/shared/AppButton.vue";
import AppModal from "@/js/components/shared/AppModal.vue";

export default {
  components: {
    AppButton,
    AppModal,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
