/* eslint-disable */

import axios from "axios";

import { CRAFT } from "@/js/services/config.js";

const opts = {};
if (CRAFT.API_TOKEN) {
  opts.auth = {
    username: "admin",
    password: CRAFT.API_TOKEN,
  };
}

const craftApiBaseUrl = `${CRAFT.API_BASE_URL}/${CRAFT.SITE_ID}`;

const craftApi = {
  async getEvent(eventId) {
    const { data } = await axios.get(`${craftApiBaseUrl}/events/${eventId}`, opts);
    return data;
  },
  async getGlobals() {
    const { data } = await axios.get(`${craftApiBaseUrl}/globals`, opts);
    return data;
  },
  async getProducts(eventId) {
    const { data } = await axios.get(
      `${craftApiBaseUrl}/events/${eventId}/products`, opts
    );
    return data;
  },
  async getMerchPage(merchPageId) {
    const { data } = await axios.get(
      `${craftApiBaseUrl}/merch/${merchPageId}`, opts
    );
    return data;
  },
  async getTicketGroups(craftEventId) {
    const { data } = await axios.get(
      `/actions/lineup/ticket-groups?eventId=${craftEventId}`, opts
    );
    return data;
  },
  async createReservationWithCompanion(tickets, products, session, performanceId) {
    const data = {
      tickets,
      products,
      session,
      performanceId
    }
    data[CRAFT.CSRF_TOKEN_NAME] = CRAFT.CSRF_TOKEN_VALUE;
    const result = await axios.post(
      `/actions/lineup/accessibility/create-reservation-with-companion`,
      data
    );
    return result.data.data;
  },
  async attachFormToReservation(reservationId, formId, content) {
    const data = {
      content,
      reservationId,
      formId
    };
    data[CRAFT.CSRF_TOKEN_NAME] = CRAFT.CSRF_TOKEN_VALUE;
    const result = await axios.post(
      `/actions/lineup/accessibility/attach-form-to-reservation`,
      data
    );
    return result.data.data;
  }
};

export { craftApi as default };