
<div class="container-md mt-6 md:mt-8">
  <app-page-header :heading="pageContent.ticketGroupsHeading">
    <div class="pretty">
      <div class="trim" v-html="pageContent.ticketGroupsInstructions" />
      <p>Questions? <a href="#" @click="scrollToFaqs">Check our FAQs</a>.</p>
    </div>
  </app-page-header>
  <app-loader v-if="loading" />
  <div v-else class="flex flex-wrap md:-mx-4 mb-8 md:mb-12">
    <div class="w-full md:w-2/3 md:px-4">
      <filter-summary
        :performance-count="totalDatesCount"
        @toggleshowfilters="toggleShowMobileFilters"
        @resetquantity="setQuantity(null)"
      />
      <div v-if="!ticketGroupCount">
        <p>Sorry, no matching dates found</p>
        <app-button
          :variants="['white', 'small']"
          class="mt-4"
          @click="$store.commit('clearFilters')"
          >Clear filters</app-button
        >
      </div>
      <ticket-group-button
        v-for="(ticketGroup, key) in filteredTicketGroups"
        :key="key"
        :ticket-group="ticketGroup"
        :group-id="key"
        :performance-type-expanded="
          expandPerformanceType(ticketGroup.performanceType)
        "
        :default-time-slots="defaultTimeSlots"
        :all-performance-types="performanceTypes"
        :selected-quantity="selectedQuantity"
        :timeslot-options="{
          minAvailabilityForSoldOut,
          slotShowWarningThreshold,
          priceFilter,
          timeslotFilter,
        }"
        class="mb-2"
        @chosen="selectTicketGroup"
      />
    </div>
    <div
      v-if="areFiltersShown"
      :class="{ 'border-l border-gray-light': !isDesktop }"
      :style="stickyFilterStyle"
      class="
        self-start
        fixed right-0 top-0 bottom-0 w-full h-full
        sm:w-10/12
        overflow-y-auto
        md:sticky md:block md:w-1/3 md:h-auto md:top-0
        p-3 md:p-0 md:px-4
        z-40
        bg-white"
    >
      <filters
        :result-count="ticketGroupCount"
        @setquantity="setQuantity"
        @toggleshowfilters="toggleShowMobileFilters"
      />
    </div>
    <div v-else class="hidden md:block md:w-1/3 md:px-4">
      <app-button :variants="['small']" @click="toggleShowMobileFilters">
        Filter
        <template #icon>
          <icon icon="plus" class="w-2 mr-1" width="20" />
        </template>
      </app-button>
    </div>
  </div>
</div>
