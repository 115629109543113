<template>
  <app-modal max-w="xl">
    <h2 class="text-center">The price of your tickets has changed</h2>
    <p class="text-center">
      Ticket prices have increased on this date as it’s getting busier. We’ve
      updated your basket with the new total.
    </p>
    <div class="bg-gray-light p-3 flex flex-wrap justify-center">
      <app-button
        class="btn--gradient block w-full sm:w-auto sm:inline-block"
        @click="submit()"
        >OK</app-button
      >
    </div>
  </app-modal>
</template>
<script>
import AppButton from "@/js/components/shared/AppButton.vue";
import AppModal from "@/js/components/shared/AppModal.vue";

export default {
  components: {
    AppButton,
    AppModal,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
