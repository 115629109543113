<template>
  <div class="container-md mt-6 md:mt-8 mb-10 md:mb-12">
    <checkout-order-summary
      class="mb-6 md:mb-8"
      @change="refreshAll"
      @ticketRemoved="resetReservation"
    />
    <checkout-apply-discount-code
      v-if="showDiscountCodeUi"
      @change="refreshAll"
    />
    <section :class="{ 'mb-3': supportsWallet }">
      <div v-if="supportsWallet && totalPrice.afterDiscount > 0">
        <h5 class="mb-3">Instant checkout:</h5>
      </div>
      <div class="flex flex-wrap justify-center mb-5">
        <div class="w-full sm:w-1/2">
          <div ref="paymentRequestButton" />
        </div>
      </div>
    </section>
    <form @submit="submitCardForm">
      <section class="mb-3">
        <div>
          <h5
            v-if="supportsWallet && totalPrice.afterDiscount > 0"
            class="mb-3"
          >
            Or enter payment details manually:
          </h5>
          <h5 v-else class="mb-3">
            Enter
            {{ totalPrice.afterDiscount > 0 ? "payment" : "your" }} details:
          </h5>
          <div class="flex flex-wrap sm:-mx-3">
            <div class="w-full sm:w-1/2 sm:px-3">
              <form-input
                v-model="customerDetails.firstName"
                label="First Name"
                :input-attrs="{
                  placeholder: 'First Name',
                  name: 'fname',
                  autocomplete: 'given-name',
                  required: true,
                }"
                class="mb-3"
              />
            </div>
            <div class="w-full sm:w-1/2 sm:px-3">
              <form-input
                v-model="customerDetails.lastName"
                label="Last Name"
                :input-attrs="{
                  placeholder: 'Last Name',
                  name: 'lname',
                  autocomplete: 'family-name',
                  required: true,
                }"
                class="mb-3"
              />
            </div>
            <div class="w-full sm:w-1/2 sm:px-3">
              <form-input
                v-model="customerDetails.email"
                type="email"
                label="Email"
                :input-attrs="{
                  placeholder: 'Email',
                  name: 'email',
                  autocomplete: 'email',
                  required: true,
                }"
                class="mb-3"
              />
            </div>
            <div class="w-full sm:w-1/2 sm:px-3">
              <form-input
                v-model="customerDetails.telephone"
                type="tel"
                label="Mobile number"
                :input-attrs="{
                  placeholder: 'Mobile number',
                  name: 'phone',
                  autocomplete: 'tel',
                  required: true,
                }"
                class="mb-3"
              />
            </div>
          </div>
          <div v-if="showCardFields" class="mb-3">
            <h5 class="mb-3">Card information:</h5>

            <div class="flex flex-wrap sm:-mx-3">
              <div class="w-full sm:w-1/2 sm:px-3 mb-3">
                <div
                  ref="cardNumber"
                  :class="[style.formInputClasses, style.formWrapperClasses]"
                />
              </div>
              <div class="w-full sm:w-1/2 sm:px-3">
                <div class="flex flex-wrap sm:-mx-3">
                  <div class="w-full sm:w-1/2 sm:px-3 mb-3">
                    <div
                      ref="cardExpiry"
                      :class="[
                        style.formInputClasses,
                        style.formWrapperClasses,
                      ]"
                    />
                  </div>
                  <div class="w-full sm:w-1/2 sm:px-3">
                    <div
                      ref="cardCvc"
                      :class="[
                        style.formInputClasses,
                        style.formWrapperClasses,
                      ]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <app-callout v-if="cardError" type="warning" class="mb-5 md:mb-6">
            <p>Sorry, there was a problem with the details you entered:</p>
            <p>
              <strong>{{ cardError }}</strong>
            </p>
          </app-callout>
        </div>

        <!-- T's & C's / Buy -->
        <div class="mb-8">
          <span class="font-sans block text-xs leading-tight mb-5"
            >By clicking the Pay Now or Instant checkout button, I confirm that
            I have read and understood the
            <a :href="termsUrl" class="link" target="_blank"
              >Terms &amp; Conditions of Entry</a
            >. You can view our
            <a :href="privacyUrl" class="link" target="_blank"
              >privacy policy here</a
            >.</span
          >

          <div class="flex flex-wrap justify-center mb-5">
            <div class="w-full sm:w-1/2">
              <app-button
                ref="cardButton"
                type="submit"
                :attrs="{
                  disabled: !submitEnabled,
                }"
                :variants="['gradient', 'block']"
              >
                {{ totalPrice.afterDiscount > 0 ? "Pay Now" : "Confirm Order" }}
                <template #icon>
                  <icon icon="lock" class="w-3 mr-1 pr-px" width="20" />
                </template>
              </app-button>
            </div>
          </div>
          <!-- @todo link to Privacy policy -->
          <!-- <span class="font-sans block text-xs leading-tight">If you don’t want to receive email and discounts for new experiences from us please tick this box - View our <a :href="privacyUrl" class="link">privacy policy here</a>.</span> -->
        </div>
      </section>
    </form>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import ROUTES from "@/js/constants/routeNames.js";

import { CRAFT } from "@/js/services/config.js";
import Raygun from "@/js/services/raygun.js";
import stripe from "@/js/services/stripe.js";
import GoogleAnalytics from "@/js/services/googleAnalytics.js";

import AppButton from "@/js/components/shared/AppButton.vue";
import AppCallout from "@/js/components/shared/AppCallout.vue";
import CheckoutOrderSummary from "@/js/components/checkout/CheckoutOrderSummary.vue";
import CheckoutApplyDiscountCode from "@/js/components/checkout/CheckoutApplyDiscountCode.vue";
import Icon from "@/js/components/shared/Icon.vue";

import {
  component as FormInput,
  inputClasses as formInputClasses,
  wrapperClasses as formWrapperClasses,
} from "@/js/components/forms/FormInput.vue";

export default {
  components: {
    AppButton,
    AppCallout,
    CheckoutOrderSummary,
    CheckoutApplyDiscountCode,
    FormInput,
    Icon,
  },
  props: {},
  data() {
    return {
      termsUrl: CRAFT.TERMS_URL,
      privacyUrl: CRAFT.PRIVACY_URL,
      style: {
        formWrapperClasses,
        formInputClasses,
      },
      customerDetails: {
        firstName: null,
        telephone: null,
        lastName: null,
        email: null,
      },
      cardError: null,
      supportsPaymentRequest: null,
      stripe: {
        paymentRequest: null,
        elements: {
          cardNumber: null,
          cardExpiry: null,
          cardCvc: null,
          prButton: null,
        },
        fields: {
          cardNumber: {
            valid: false,
          },
          cardExpiry: {
            valid: false,
          },
          cardCvc: {
            valid: false,
          },
        },
        style: {
          base: {
            fontSize: "16px",
            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
            "::placeholder": {
              color: "#B3B3B3",
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["totalQuantity", "productsForCheckout"]),
    ...mapGetters({
      totalPrice: "eventTotalPrice",
    }),
    ...mapState([
      "companionTicket",
      "discountCodeError",
      "suppliedDiscountCode",
      "suppliedGiftVoucher",
      "urlDiscountCode",
      "urlGiftVoucher",
    ]),
    submitEnabled() {
      return (
        // must have items
        !!this.totalQuantity &&
        // must provide customer info
        this.validateCustomerDetails() &&
        // and must either
        // be a free order
        (this.totalPrice.afterDiscount === 0 ||
          // or provide payment info
          this.validatePaymentDetails())
      );
    },
    showCardFields() {
      return this.totalPrice.afterDiscount > 0;
    },
    showDiscountCodeUi() {
      return !this.companionTicket;
    },
    supportsApplePay() {
      return (
        this.supportsPaymentRequest && this.supportsPaymentRequest.applePay
      );
    },
    supportsGooglePay() {
      return (
        this.supportsPaymentRequest && this.supportsPaymentRequest.googlePay
      );
    },
    supportsWallet() {
      return this.supportsApplePay || this.supportsGooglePay;
    },
  },
  watch: {
    supportsPaymentRequest(doesSupport) {
      if (doesSupport && (doesSupport.applePay || doesSupport.googlePay)) {
        this.mountPaymentRequestBtn();
      }
    },
    totalPrice(newPrice, oldPrice) {
      if (newPrice.afterDiscount !== oldPrice.afterDiscount) {
        this.setupPaymentRequest();
      }
    },
  },
  async mounted() {
    if (this.applyUrlDiscounts()) {
      this.updateReservationFromBasket(true);
    }
    if (this.showCardFields) {
      this.$nextTick(() => {
        this.createStripeElements();
        this.setupPaymentRequest();
      });
    }
  },
  methods: {
    createStripeElements() {
      const elements = stripe.elements();

      Object.keys(this.stripe.fields).forEach((fieldId) => {
        const element = elements.create(fieldId, { style: this.stripe.style });
        element.mount(this.$refs[fieldId]);

        this.stripe.elements[fieldId] = element;

        element.on("change", ({ elementType, complete, empty }) => {
          this.stripe.fields[elementType].valid = !empty && complete;
        });
      });
    },
    mountPaymentRequestBtn() {
      const elements = stripe.elements();

      this.stripe.elements.prButton = elements.create("paymentRequestButton", {
        paymentRequest: this.stripe.paymentRequest,
        style: {
          paymentRequestButton: {
            // One of 'default', 'book', 'buy', or 'donate'
            // Defaults to 'default'
            type: "buy",
            theme: "dark",
            height: "44px",
          },
        },
      });

      this.stripe.elements.prButton.mount(this.$refs.paymentRequestButton);
    },
    async submitCardForm(e) {
      e.preventDefault();
      this.cardError = "";

      if (this.totalPrice.afterDiscount === 0) {
        await this.finaliseCardOrder(null, this.customerDetails);
        return;
      }

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: this.stripe.elements.cardNumber,
      });

      if (error) {
        this.cardError = error.message;
        return;
      }
      await this.finaliseCardOrder(paymentMethod, this.customerDetails);
    },
    cardElementChange({ error }) {
      this.cardError = error ? error.message : "";
    },
    async updateReservationFromBasket(applyPendingDiscount = false) {
      await this.$store.dispatch(
        "updateReservationFromBasket",
        applyPendingDiscount
      );
    },
    async refreshAll() {
      this.$store.commit("startLoading");
      this.updateReservationFromBasket(true);
      this.setupPaymentRequest();
      this.$store.commit("stopLoading");
    },
    async resetReservation() {
      this.$store.commit("startLoading");
      // Clear any remaining LU reservation
      await this.$store.dispatch("clearReservation");
      // Clear locally selected options
      this.$store.commit("clearSelectedOptions");
      this.$router.push({ name: ROUTES.QUANTITY });
      this.$store.commit("stopLoading");
    },
    async setupPaymentRequest() {
      if (this.stripe.paymentRequest) {
        this.stripe.paymentRequest.off("token");
      }

      this.stripe.paymentRequest = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        total: {
          label: "Ticket Purchase",
          amount: Math.round(this.totalPrice.afterDiscount * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      });

      // Check the availability of the Payment Request API
      this.supportsPaymentRequest = await this.stripe.paymentRequest.canMakePayment();

      if (this.supportsWallet) {
        this.stripe.paymentRequest.on(
          "paymentmethod",
          this.finalisePaymentRequestOrder
        );
      }
    },
    async finaliseCardOrder(paymentMethod = null, customer) {
      Raygun.recordBreadcrumb(
        "startFinaliseCardOrder",
        "info",
        {},
        "Checkout::finaliseCardOrder"
      );
      await this.beforeFinaliseOrder(customer);

      try {
        const orderDetails = await this.$store.dispatch(
          "completeCardOrder",
          paymentMethod
        );
        this.afterDidFinaliseOrder(orderDetails);
      } catch (e) {
        this.onFailedToFinaliseOrder(e);
      }
    },
    async finalisePaymentRequestOrder(event) {
      Raygun.recordBreadcrumb(
        "startPaymentRequestOrder",
        "info",
        {},
        "Checkout::finalisePaymentRequestOrder"
      );
      // @todo this will probably break for some names, but should be "good enough"
      // for now when combined together
      const name = event.payerName.split(" ");
      const lastName = name.pop();

      await this.beforeFinaliseOrder({
        firstName: name.join(" "),
        telephone: event.requestPayerPhone,
        lastName,
        email: event.payerEmail,
      });

      try {
        const orderDetails = await this.$store.dispatch(
          "completePaymentRequestOrder",
          event
        );
        this.afterDidFinaliseOrder(orderDetails);
      } catch (e) {
        this.onFailedToFinaliseOrder(e);
      }
    },
    async beforeFinaliseOrder(customer) {
      this.$store.commit("startLoading");
      // If an anonymous lineup user doesn't already exist, create one and return it
      return this.$store.dispatch("getAnonymousUserForSession", customer);
    },
    afterDidFinaliseOrder(orderDetails) {
      Raygun.recordBreadcrumb(
        "afterDidFinaliseOrder",
        "info",
        {},
        "Checkout::afterDidFinaliseOrder"
      );

      GoogleAnalytics.sendPurchase({
        order: orderDetails,
        productPurchases: this.productsForCheckout,
      });

      this.$router.push({ name: ROUTES.CONFIRMATION });
      this.$store.commit("stopLoading");
    },
    onFailedToFinaliseOrder(e) {
      Raygun.recordBreadcrumb("onFailedToFinaliseOrder", "error");

      Raygun.sendError(e, [{ vm: this }]);
      this.$store.commit("stopLoading");
      this.$store.commit("setGenericError", {
        title: "Your Purchase Couldn't Be Completed",
        body:
          "There was a problem finalising your purchase. Please check your details and try again.",
      });
    },
    validateCustomerDetails() {
      let formValid = true;

      // Require customer fields
      Object.values(this.customerDetails).forEach((value) => {
        if (!value) {
          formValid = false;
        }
      });

      return formValid;
    },
    validatePaymentDetails() {
      let formValid = true;

      // Check stripe fields valid
      Object.values(this.stripe.fields).forEach(({ valid }) => {
        if (!valid) {
          formValid = false;
        }
      });

      return formValid;
    },
    applyUrlDiscounts() {
      let didApplyCode = false;

      if (!this.suppliedDiscountCode && this.urlDiscountCode) {
        this.$store.commit("setDiscountCode", this.urlDiscountCode);
        didApplyCode = true;
      }
      if (!this.suppliedGiftVoucher && this.urlGiftVoucher) {
        this.$store.commit("setGiftVoucher", this.urlGiftVoucher);
        didApplyCode = true;
      }

      return didApplyCode;
    },
  },
};
</script>
