<template>
  <div :class="[isEnabled ? '' : disabledClasses]">
    <div v-if="!showAccessNeedsDetail" class="mb-4 md:mb-6">
      <h3 class="mb-1 md:mb-2 h5 font-sans font-bold">
        Does anyone in your group have special access needs?
      </h3>

      <div class="flex space-x-3">
        <div class="mb-1">
          <input
            id="yes-access-needs"
            v-model="hasAccessNeeds"
            type="radio"
            name="access-needs"
            :value="true"
            class="focus:ring focus:outline-none font-sans"
            @change="changehasAccessNeeds()"
          />
          <label for="yes-access-needs" class="ml-2 font-sans text-base"
            >Yes</label
          >
        </div>
        <div class="mb-1">
          <input
            id="no-access-needs"
            v-model="hasAccessNeeds"
            type="radio"
            name="access-needs"
            :value="false"
            class="focus:ring focus:outline-none font-sans"
            @change="changehasAccessNeeds()"
          />
          <label for="no-access-needs" class="ml-2 font-sans text-base"
            >No</label
          >
        </div>
      </div>
    </div>
    <div aria-live>
      <performance-access-needs-detail
        v-if="showAccessNeedsDetail"
        @cancel="cancelAccessNeeds"
        @submit="changehasAccessNeeds"
      />
      <div v-else-if="hasAccessNeeds" class="text-sm">
        <dl>
          <dt>Companion ticket requested?</dt>
          <dd class="font-bold">{{ !!companionTicket ? "Yes" : "No" }}</dd>
          <dt>Additional access needs information provided?</dt>
          <dd class="font-bold">{{ !!accessNeedsNotes ? "Yes" : "No" }}</dd>
        </dl>
        <a href="#" @click.prevent="() => (editingAccessNeeds = true)"
          >Edit access information</a
        >
      </div>
    </div>

    <div v-if="!showAccessNeedsDetail" class="text-center">
      <app-button
        class="btn--gradient block w-full sm:inline-block sm:w-auto"
        @click="buyNow"
        >Go to Checkout</app-button
      >
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import ROUTES from "@/js/constants/routeNames.js";

import GoogleAnalytics from "@/js/services/googleAnalytics.js";
import AppButton from "@/js/components/shared/AppButton.vue";

import PerformanceAccessNeedsDetail from "@/js/components/performances/PerformanceAccessNeedsDetail.vue";

export default {
  components: {
    AppButton,
    PerformanceAccessNeedsDetail,
  },
  data() {
    return {
      disabledClasses: ["pointer-events-none", "opacity-50"],
      hasAccessNeeds: false,
      editingAccessNeeds: false,
    };
  },
  computed: {
    ...mapState([
      "selectedPerformance",
      "selectedTicketGroupKey",
      "accessNeedsSet",
      "accessNeedsNotes",
      "companionTicket",
    ]),
    isEnabled() {
      return !!this.selectedPerformance;
    },
    showAccessNeedsDetail() {
      return (
        (this.hasAccessNeeds && !this.accessNeedsSet) || this.editingAccessNeeds
      );
    },
  },
  watch: {
    showAccessNeedsDetail(nowShown) {
      this.$emit("showaccessneeds", nowShown);
    },
  },
  async mounted() {
    this.hasAccessNeeds = this.accessNeedsSet;
  },
  methods: {
    changehasAccessNeeds() {
      GoogleAnalytics.sendEvent(
        "click",
        "Tickets",
        "Access needs",
        this.hasAccessNeeds ? "yes" : "no"
      );
      if (!this.hasAccessNeeds) {
        this.cancelAccessNeeds();
      }
      this.editingAccessNeeds = false;
    },
    async buyNow() {
      // @todo dedupe with TicketBasket
      // If lineup reservation is null, create one with the selected tickets and quantity
      try {
        this.$store.commit("startLoading");
        // Create a reservation
        await this.$store.dispatch("updateReservationFromBasket");
        this.$router.push({ name: ROUTES.PRODUCTS });
        this.$store.commit("stopLoading");
      } catch (e) {
        this.$store.commit("setGenericError", {
          title: "Your Tickets Couldn't Be Reserved ",
          body:
            "There was a problem whilst reserving your tickets. The availability of your selected date and time might have changed. Please try again.",
        });
        this.$store.commit("setSelectedPerformance", null);
        this.cancelAccessNeeds();
        this.$store.commit("stopLoading");
        // Refresh ticket availabilities for any selected ticket group
        if (this.selectedTicketGroupKey != null) {
          this.$store.dispatch("refreshLineupTicketAvailabilities", {
            ticketGroupKey: this.selectedTicketGroupKey,
          });
        }
      }
    },
    cancelAccessNeeds() {
      this.hasAccessNeeds = false;
      this.$store.commit("clearAccessNeeds");
    },
  },
};
</script>
