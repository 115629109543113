<template>
  <app-modal
    max-w="xl"
    :with-close-button="true"
    :is-full-screen-mobile="true"
    @close="resetPerformance"
  >
    <div v-if="!accessNeedsShown" class="mb-8">
      <h2>
        {{ formatTime(selectedPerformance.time, { inFormat: "HH:mm:ss" }) }}
        {{ formatDateWithYear(selectedDate) }}
      </h2>
      <p class="font-sans font-bold">
        {{ selectedQuantity }} {{ selectedPerformanceType.label }} ticket{{
          selectedQuantity > 1 ? `s` : ``
        }}
      </p>

      <div v-if="showTicketTypeSelector">
        <div
          v-for="(ticket, idx) in selectedPerformance.tickets"
          :key="ticket.id"
        >
          <hr v-if="idx > 0" class="my-4 opacity-25" />

          <div class="flex flex-wrap items-center my-1 font-sans">
            <label class="block w-auto">
              <span class="inline-block font-bold text-sm">{{
                expandTicketType(ticket.type).label
              }}</span>
              <span
                v-if="expandTicketType(ticket.type).caption"
                class="text-xs font-normal"
                ><br />{{ expandTicketType(ticket.type).caption }}</span
              >
            </label>
            <product-quantity
              :id="toKebabCase(ticket.type)"
              :current-quantity="ticketQuantity(ticket.id)"
              :label="ticket.type"
              class="ml-auto"
              style="width: 105px"
              @increment="addTicket(ticket.id)"
              @decrement="removeTicket(ticket.id)"
            />
          </div>
        </div>
      </div>
    </div>
    <performance-access-needs @showaccessneeds="trackAccessNeedsShown" />
  </app-modal>
</template>
<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";

import { toKebabCase } from "@/js/helpers/StringHelper.js";
import dateHelper from "@/js/helpers/DateHelper.js";

import AppModal from "@/js/components/shared/AppModal.vue";
import ProductQuantity from "@/js/components/products/ProductQuantity.vue";
import PerformanceAccessNeeds from "@/js/components/performances/PerformanceAccessNeeds.vue";

export default {
  components: {
    AppModal,
    ProductQuantity,
    PerformanceAccessNeeds,
  },
  mixins: [dateHelper],
  data() {
    return {
      accessNeedsShown: false,
    };
  },
  computed: {
    ...mapState([
      "selectedTicketGroup",
      "selectedQuantity",
      "selectedTickets",
      "selectedPerformance",
      "selectedTicketGroupKey",
    ]),
    ...mapGetters([
      "expandTicketType",
      "getDefaultTicket",
      "selectedPerformanceType",
    ]),
    selectedDate() {
      return moment(this.selectedTicketGroup.date);
    },
    defaultTicket() {
      return this.getDefaultTicket(this.selectedPerformance);
    },
    defaultTicketType() {
      return this.expandTicketType(this.defaultTicket.type);
    },
    showTicketTypeSelector() {
      return (
        this.selectedQuantity > this.defaultTicketType.minQuantity &&
        this.selectedPerformance.tickets.length > 1
      );
    },
  },
  methods: {
    ticketQuantity(ticketId) {
      return this.selectedTickets[ticketId] ?? 0;
    },
    addTicket(ticketId) {
      this.$store.dispatch("addTicket", { ticketId });
    },
    removeTicket(ticketId) {
      this.$store.dispatch("removeTicket", { ticketId });
    },
    resetPerformance() {
      this.$store.commit("setSelectedPerformance", null);
      this.$store.commit("clearAccessNeeds");
    },
    trackAccessNeedsShown(isShown) {
      this.accessNeedsShown = isShown;
    },
    toKebabCase,
  },
};
</script>
