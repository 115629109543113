<template>
  <a
    href="#"
    :title="title"
    :tabindex="isSoldOut || capacityLessThanSelectedQuantity ? `-1` : ``"
    :disabled="
      isSoldOut ||
        capacityLessThanSelectedQuantity ||
        performance.cachedAvailability
    "
    class="
        w-1/3
        sm:w-28
        md:w-1/6
        block
        px-1"
    @click.prevent="selectPerformance()"
  >
    <div
      :class="[wrapperClass]"
      class="performance-item text-center
        font-sans
        h-full
        py-2
        px-4
        border
        transition duration-200 ease-in-out
        focus:outline-none focus:ring
        group"
    >
      <span class="block font-bold font-display">{{
        formatTime(performance.time, { inFormat: "HH:mm:ss" })
      }}</span>
      <span v-if="performance.cachedAvailability" class="block text-xs">
        <app-loader
          wrapper-class="text-gray text-xs"
          :show-message="false"
        ></app-loader>
      </span>
      <template v-else>
        <span
          class="block text-xs"
          :class="{
            'text-u-alert-red': showRemainingTicketCount && !isSoldOut,
          }"
          >{{ availabilityMessage }}</span
        >
        <span
          v-if="!isSoldOut"
          class="block textlg"
          v-html="formatPriceShort(displayPrice)"
        />
      </template>
    </div>
  </a>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import dateHelper from "@/js/helpers/DateHelper.js";
import { formatPriceShort } from "@/js/helpers/NumberHelper.js";

import AppLoader from "@/js/components/shared/AppLoader.vue";

export default {
  components: {
    AppLoader,
  },
  mixins: [dateHelper],
  props: {
    performance: {
      required: true,
      type: Object,
    },
    availability: {
      required: true,
      type: Number,
    },
  },
  computed: {
    ...mapState([
      "minAvailabilityForSoldOut",
      "performanceShowWarningThreshold",
      "selectedPerformance",
      "selectedQuantity",
      "setQuantity",
    ]),
    ...mapGetters(["getDefaultTicket"]),
    title() {
      return `Select a ticket for ${this.performance.time}`;
    },
    defaultTicket() {
      return this.getDefaultTicket(this.performance);
    },
    displayPrice() {
      return this.defaultTicket.faceValueTotal;
    },
    isSoldOut() {
      return this.availability <= this.minAvailabilityForSoldOut;
    },
    availabilityMessage() {
      let result;

      if (this.isSoldOut) {
        result = `SOLD OUT`;
      } else if (this.showRemainingTicketCount) {
        result = `${this.availability} Left`;
      } else {
        result = `Available`;
      }

      return result;
    },
    showRemainingTicketCount() {
      let result = false;

      if (this.capacityLessThanSelectedQuantity) {
        result = true;
      }

      if (this.availability < this.performanceShowWarningThreshold) {
        result = true;
      }

      return result;
    },
    capacityLessThanSelectedQuantity() {
      return this.availability < this.selectedQuantity;
    },
    isSelectable() {
      return !this.isSoldOut && !this.capacityLessThanSelectedQuantity;
    },
    wrapperClass() {
      return {
        "performance-item--is-selected": this.isSelected,
        "performance-item--is-selectable": this.isSelectable,
        "performance-item--is-not-selectable": !this.isSelectable,
      };
    },
    isSelected() {
      return (
        this.selectedPerformance &&
        this.performance.id === this.selectedPerformance.id
      );
    },
  },
  methods: {
    formatPriceShort,
    selectPerformance() {
      if (
        this.isSoldOut ||
        this.capacityLessThanSelectedQuantity ||
        this.performance.cachedAvailability
      ) {
        return;
      }
      this.$emit("selected", this.performance);
    },
  },
};
</script>
