export const getParam = (paramName) => {
  const urlParams = new URLSearchParams(window.location.search);

  let result = urlParams.get(paramName);

  if (!result) {
    result = !!result;
  }

  return result;
};

export default {
  getParam,
};
