<template>
  <div class="loading" :class="wrapperClass">
    <icon icon="loading" class="loading__icon" />
    <div v-if="showMessage" class="w-full">
      <span class="loading__text">
        <slot>
          <span v-html="currentMessage" />
        </slot>
      </span>
    </div>
  </div>
</template>
<script>
import Raygun from "@/js/services/raygun.js";
import Icon from "@/js/components/shared/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    showMessage: {
      type: Boolean,
      default: true,
    },
    wrapperClass: {
      type: [Array, String],
      default: "text-sm",
    },
  },
  data() {
    return {
      currentMessage: null,
      errorThreshold: 75,
      messages: {
        0: "Loading, please wait&hellip;",
        10: "Still working&hellip;",
        20: "Still working, thanks for waiting&hellip;",
        30: "Sorry, this is taking longer than usual&hellip;",
        45: "Sorry, this is taking much longer than usual. Thanks for bearing with us&hellip;",
      },
      timers: [],
    };
  },
  mounted() {
    this.timers = Object.entries(this.messages).map(([seconds, message]) =>
      setTimeout(() => {
        this.currentMessage = message;
      }, seconds * 1000)
    );
    this.timers.push(
      setTimeout(() => {
        Raygun.sendError(new Error("AppLoader: user waited too long"), [
          { vm: this, lifetime: this.errorThreshold },
        ]);
      }, this.errorThreshold * 1000)
    );
  },
  beforeDestroy() {
    this.timers.forEach((timer) => clearTimeout(timer));
  },
};
</script>
