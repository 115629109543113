
<div class="container-md mt-6 md:mt-8 mb-10 md:mb-12">
  <h1>Thanks for booking {{ customer.firstName }}</h1>
  <div
    class="pretty trim mt-5"
    v-html="pageContent.confirmationIntroMessage"
  />
  <div class="mb-5">
    <div v-if="shouldShowConfirmationButton">
      <div class="sm:flex sm:flex-wrap items-center justify-center mt-8">
        <div class="w-full sm:w-1/2">
          <app-button
            tag-name="a"
            target="_blank"
            :href="pageContent.confirmationButton.url"
            :variants="['block', 'gradient']"
            >{{ pageContent.confirmationButton.text }}</app-button
          >
        </div>
      </div>
    </div>
  </div>
  <p class="mb-5 md:mb-8">
    We’ve sent your confirmation email and eTickets to the email address you
    used for this order.
  </p>
  <div class="flex flex-wrap">
    <div class="text-left w-full md:flex-1 mb-4 md:mb-0">
      <span class="h4 mb-1">Your order:</span>
      <span class="block text-base font-sans"
        >Order reference: <code>{{ order.reference }}</code></span
      >
    </div>
    <div class="text-left md:text-right w-full md:flex-1">
      <span class="h4 mb-1">{{ selectedDate }}, {{ selectedTime }}</span>
      <span class="block text-xs md:text-base font-sans"
        >{{ totalQuantity }} {{ totalQuantity > 1 ? "Items" : "Item" }}</span
      >
    </div>
  </div>
  <div class="py-4 md:py-8 md:px-12">
    <table class="mb-2 w-full">
      <checkout-order-summary-item
        v-for="(ticket, idx) in ticketsForCheckout"
        :key="idx"
        :product-id="ticket.lineupId"
        :has-removeables="false"
      >
        <template #label
          >{{ ticket.quantity }} &times; {{ ticket.title }} Tickets @
          <span v-html="formatPrice(ticket.price)"
        /></template>
        <template #value
          ><span v-html="formatPrice(ticket.price * ticket.quantity)"
        /></template>
      </checkout-order-summary-item>
      <checkout-order-summary-item
        v-for="product in productsForCheckout"
        :key="product.lineupId"
        :product-id="product.lineupId"
        :has-removeables="true"
        @remove="() => {}"
      >
        <template #label
          >{{ product.quantity }} &times; {{ product.title }} @
          <span v-html="formatPrice(product.price)"
        /></template>
        <template #value
          ><span v-html="formatPrice(product.price * product.quantity)"
        /></template>
      </checkout-order-summary-item>
      <tr>
        <td colspan="3">
          <hr class="opacity-10 my-6" />
        </td>
      </tr>
      <checkout-order-summary-item
        v-if="eventTotalPrice.discount"
        :has-removeables="false"
      >
        <template #label>Subtotal</template>
        <template #value
          ><span v-html="formatPrice(eventTotalPrice.beforeDiscount)"
        /></template>
      </checkout-order-summary-item>
      <checkout-order-summary-item
        v-if="eventTotalPrice.discount"
        :has-removeables="false"
      >
        <template #label>Your discount</template>
        <template #value
          ><span v-html="`- ${formatPrice(eventTotalPrice.discount)}`"
        /></template>
      </checkout-order-summary-item>
      <checkout-order-summary-item :has-removeables="false">
        <template #label><span class="font-bold">Order Total</span></template>
        <template #value
          ><span
            class="font-bold"
            v-html="formatPrice(eventTotalPrice.afterDiscount)"
        /></template>
      </checkout-order-summary-item>
    </table>
  </div>
  <!-- <div if="order.receiptUrl">
    <div class="sm:flex sm:flex-wrap items-center justify-center mt-4 mb:mt-8">
      <div class="w-full sm:w-1/2">
        <app-button
          tag-name="a"
          target="_blank"
          download="true"
          :href="order.receiptUrl"
          :variants="['block', 'gradient']">Download Your Tickets</app-button>
      </div>
    </div>
  </div> -->
</div>
