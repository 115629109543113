<template>
  <component
    :is="tagName"
    :class="wrapperClass"
    v-bind="attrs"
    @click="$emit('click')"
  >
    <span class="flex flex-wrap items-center justify-center">
      <span :class="{ 'block w-auto mr-2': hasIcon }">
        <slot name="icon" />
      </span>
      <span class="w-auto">
        <slot />
      </span>
    </span>
  </component>
</template>
<script>
export default {
  props: {
    attrs: {
      type: Object,
      default: () => ({}),
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    tagName: {
      type: String,
      default: "button",
      validator: (propValue) => ["a", "button", "input"].includes(propValue),
    },
    variants: {
      type: Array,
      default: () => [],
      validator: (variants) =>
        !variants.length ||
        variants.every((variant) =>
          ["small", "gradient", "black", "white", "block"].includes(variant)
        ),
    },
  },
  computed: {
    wrapperClass() {
      return ["btn", ...this.variants.map((v) => `btn--${v}`)];
    },
  },
};
</script>
