import moment from "moment";

const format = (dateLike, options = {}) => {
  const { outFormat = null, inFormat = null } = options;

  return moment(dateLike, inFormat).format(outFormat);
};

export const formatDate = (dateLike, options = {}) =>
  format(dateLike, { outFormat: "ddd D MMM", ...options });

export const formatDateWithYear = (dateLike, options = {}) =>
  format(dateLike, { outFormat: "ddd D MMM yyyy", ...options });

export const formatTime = (dateLike, options = {}) =>
  format(dateLike, { outFormat: "h:mma", ...options });

export const formatDotw = (dateLike, options = {}) =>
  format(dateLike, { outFormat: "dddd", ...options });

export const formatMonthFull = (dateLike, options = {}) =>
  format(dateLike, { outFormat: "MMMM", ...options });

export default {
  methods: {
    formatTime,
    formatDotw,
    formatMonthFull,
    formatDate,
    formatDateWithYear,
  },
};
