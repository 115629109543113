<template>
  <section class="container-md mt-6 md:mt-8">
    <div class=" flex flex-wrap">
      <div class="flex-1">
        <span class="h1 mb-0 font-sans mb-2 md:mb-3">{{
          formatDateWithYear(selectedDate)
        }}</span>
        <span class="block font-sans font-bold text-md md:text-lg mb-3"
          >{{ selectedQuantity }} x {{ selectedPerformanceType.label }} ticket{{
            selectedQuantity > 1 ? "s" : ""
          }}</span
        >
      </div>
    </div>

    <app-loader v-if="loading" />
    <div v-else>
      <div class="mb-5 md:mb-8 flex flex-wrap">
        <div class="flex-1">
          <span class="block font-sans leading-tight text-md md:text-lg"
            >
            <span class="font-semibold" v-html="selectedPriceRange"/>&nbsp;<span class="text-gray-500 text-xs" v-html="bookingFeeRange"/>
            </span>
        </div>
        <div class="w-auto">
          <app-button :variants="['small', 'white']" @click="startAgain">
            Start again
          </app-button>
        </div>
      </div>

      <div>
        <div class="mb-3 md:mb-5 flex flex-wrap items-end">
          <template v-if="performancesShown.length">
            <h1 class="md:flex-1 h4">Choose your time</h1>

            <div class="ml-auto md:w-auto">
              <app-toggle
                :is-checked="!hideUnavailable"
                class="font-sans uppercase leading-none font-semibold text-xs"
                label="Show all times"
                @toggle="toggleSoldOut"
              />
            </div>
          </template>
          <template v-else>
            <h1 class="w-full h4">Sorry, no matching timeslots found</h1>
            <app-button
              :variants="['white', 'small']"
              class="mt-4"
              @click="$router.back()"
              >Go back</app-button
            >
          </template>
        </div>
        <div class="flex flex-wrap mb-5 md:mb-8 -mx-1">
          <performance-item
            v-for="performance in performancesShown"
            :key="performance.ticketId"
            :performance="performance"
            :availability="
              adjustTotalAvailableForTicketId(
                performance.ticketId,
                performance.available
              )
            "
            class="mb-2"
            @selected="selectPerformance"
          />
        </div>
      </div>
    </div>
    <ticket-type-quantity-selection v-if="selectedPerformance" />
  </section>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";

import ROUTES from "@/js/constants/routeNames.js";

import dateHelper from "@/js/helpers/DateHelper.js";
import { formatPriceShort, formatPriceRange } from "@/js/helpers/NumberHelper.js";
import GoogleAnalytics from "@/js/services/googleAnalytics.js";

import AppLoader from "@/js/components/shared/AppLoader.vue";
import AppButton from "@/js/components/shared/AppButton.vue";
import AppToggle from "@/js/components/shared/AppToggle.vue";
import PerformanceItem from "@/js/components/performances/PerformanceItem.vue";
import TicketTypeQuantitySelection from "@/js/components/performances/TicketTypeQuantitySelection.vue";

export default {
  name: "Time",
  components: {
    AppButton,
    AppLoader,
    AppToggle,
    PerformanceItem,
    TicketTypeQuantitySelection,
  },
  mixins: [dateHelper],
  data() {
    return {
      loading: true,
      hideUnavailable: true,
      pollInterval: null,
      failedRefreshCount: 0,
      errorRequestCount: 2,
      pollWait: 15 * 1000,
    };
  },
  computed: {
    ...mapState([
      "selectedTicketGroup",
      "selectedQuantity",
      "selectedPerformance",
      "selectedTicketGroupKey",
      "minAvailabilityForSoldOut",
    ]),
    ...mapState({
      lineupReservation: ({ lineupApi }) => lineupApi.lineupReservation,
    }),
    ...mapGetters(["isMobile", "selectedPerformanceType", "getDefaultTicket", "eventTotalPrice"]),
    selectedDate() {
      return moment(this.selectedTicketGroup.date);
    },
    bookingFeeRange() {
      let result;
      if (!this.performancesShown.length) {
        result = ``;
      } else {
        const range = this.performancesShown.reduce((carry, performance) => {
          const defaultTicket = this.getDefaultTicket(performance);
          return {
            max: carry.max
                ? Math.max(carry.max, defaultTicket.outsideFeeTotal)
                : defaultTicket.outsideFeeTotal,
          };
        }, {});
        result = (range.max) ? `(+${ formatPriceShort(range.max)} fee)` : ''
      }
      return result;
    },
    selectedPriceRange() {
      let result;
      if (!this.performancesShown.length) {
        result = ``;
      } else {
        const range = this.performancesShown.reduce((carry, performance) => {
          const defaultTicket = this.getDefaultTicket(performance);
          return {
            min: carry.min
              ? Math.min(carry.min, defaultTicket.faceValueTotal)
              : defaultTicket.faceValueTotal,
            max: carry.max
              ? Math.max(carry.max, defaultTicket.faceValueTotal)
              : defaultTicket.faceValueTotal,
          };
        }, {});

        result = formatPriceRange(range.min, range.max);
      }
      return result;
    },
    performancesShown() {
      return this.selectedTicketGroup.performances.filter((p) =>
        this.hideUnavailable
          ? !!this.adjustTotalAvailableForTicketId(
              p.ticketId,
              p.available,
              this.hideUnavailable
            )
          : true
      );
    },
    hasRefreshError() {
      return this.failedRefreshCount > 0;
    },
    showRefreshError() {
      return (
        this.hasRefreshError &&
        !(this.failedRefreshCount % this.errorRequestCount)
      );
    },
  },
  watch: {
    showRefreshError(showError) {
      if (showError) {
        this.$store.commit("setGenericError", {
          title: "Couldn't Update Ticket Availability",
          body: `<span>Sorry, there was a problem whilst trying to refresh the availability of tickets for your selected date</span><br><br>
            <span>We'll keep trying, but if this error persists you may need to refresh the page and start again.</span>`,
        });
      }
    },
    hasRefreshError(hasErrorNow, hadErrorBefore) {
      if (!hasErrorNow && hadErrorBefore) {
        this.$store.commit("setGenericError", null);
      }
    },
  },
  async mounted() {
    this.hideUnavailable = this.isMobile;

    // Get the latest availability for this ticketGroup
    this.refreshAvailablity().finally(() => {
      this.startPollingForAvailabilityUpdates();
      this.loading = false;
    });
    this.loading = true;
  },
  async beforeDestroy() {
    this.stopPollingForAvailabilityUpdates();
  },
  methods: {
    selectPerformance(performance) {
      GoogleAnalytics.sendEvent(
        "click",
        "Tickets",
        "Event Time",
        performance.time
      );
      this.$store.commit("setSelectedPerformance", performance);
    },
    startAgain() {
      this.$store.commit("setSelectedPerformance", null);
      this.$router.push({ name: ROUTES.TICKETS });
    },
    toggleSoldOut() {
      this.hideUnavailable = !this.hideUnavailable;
    },
    startPollingForAvailabilityUpdates() {
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
      }

      this.pollInterval = setInterval(() => {
        this.refreshAvailablity();
      }, this.pollWait);
    },
    stopPollingForAvailabilityUpdates() {
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
      }
    },
    refreshAvailablity() {
      return this.$store
        .dispatch("refreshLineupTicketAvailabilities", {
          ticketGroupKey: this.selectedTicketGroupKey,
        })
        .then(() => {
          this.resetFailedRequestCount();
        })
        .catch((err) => {
          this.incrementFailedRequestCount();
          throw new Error(err);
        });
    },
    incrementFailedRequestCount() {
      this.failedRefreshCount += 1;
    },
    resetFailedRequestCount() {
      this.failedRefreshCount = 0;
      this.$store.commit("setGenericError", null);
    },
    // This is needed to prevent the ticket selection screen showing 0 availble when it's the current user who has the tickets
    // in reserve
    // @todo fix
    adjustTotalAvailableForTicketId(
      ticketId,
      currentlyAvailable,
      hideUnavailable
    ) {
      if (this.lineupReservation !== null) {
        if (
          this.lineupReservation.ticketReservations[0].ticket.id === ticketId
        ) {
          return (
            currentlyAvailable +
            this.lineupReservation.ticketReservations[0].quantity
          );
        }
      }
      if (
        currentlyAvailable <= this.minAvailabilityForSoldOut ||
        (hideUnavailable && currentlyAvailable < this.selectedQuantity)
      ) {
        currentlyAvailable = 0;
      }
      return currentlyAvailable;
    },
  },
};
</script>
