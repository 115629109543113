import lineupApi from "@/js/services/lineupApi.js";
import stripe from "@/js/services/stripe.js";

export default async function completeCardOrder(
  { commit, state },
  paymentMethod = {}
) {
  if (!state.sessionUser) {
    throw new Error("No anonymous user has been created yet.");
  }

  const paymentMethodId =
    paymentMethod && paymentMethod.id ? paymentMethod.id : null;

  let order = await lineupApi.convertSessionToOrder(
    state.lineupReservation.reference,
    state.sessionUser.accessToken,
    paymentMethodId
  );

  commit("setLineupOrder", order);

  if (order.requiresAction) {
    commit("startLoading");

    const { paymentIntent, error } = await stripe.handleCardAction(
      order.paymentIntentClientSecret
    );

    if (error) {
      commit("setLineupOrder", null);

      this.$store.commit("setGenericError", {
        title: "Your Purchase Wasn't Confirmed",
        body: `
                  <p>Sorry, there was a problem confirming your purchase</p>
                 ${
                   error.message ? `<p>The error was: ${error.message}</p>` : ""
                 }
                 `,
      });
      commit("stopLoading");

      return {};
    }

    order = await lineupApi.authoriseOrder(
      state.lineupOrder.id,
      paymentIntent.id,
      state.sessionUser.accessToken
    );

    commit("setLineupOrder", order);
  }

  commit("setTempFinalDiscount", state.lineupReservation.discount);
  commit("setLineupReservation", null);

  return order;
}
