
<header class="hero relative overflow-hidden py-3 bg-gray-light">
  <!-- 50% opacity black "mask" positioned over the top of the image to make text more legible -->
  <div
    v-if="currentBackground"
    class="hidden md:block absolute top-0 left-0 right-0 bottom-0 w-full h-full z-20 bg-black opacity-50 backdrop-blur"
  />
  <!-- Element with a background image positioned behind the hero (mask sits above it) -->
  <div
    v-if="currentBackground"
    :style="bgStyle"
    class="hidden md:block absolute top-0 left-0 right-0 bottom-0 z-10 w-full h-full bg-cover bg-center"
  />

  <div class="container-md relative z-30">
    <div class="flex flex-wrap items-start">
      <a
        v-if="event.link && event.thumb"
        :href="event.link"
        class="block w-20"
      >
        <img :src="event.thumb" alt="" class="block" />
      </a>
      <img
        v-else-if="event.thumb"
        :src="event.thumb"
        alt=""
        class="block w-20"
      />
      <div class="flex-1 pl-3 text-black md:text-white">
        <h2
          class="font-display text-base md:text-lg mb-1 leading-tight font-normal"
        >
          <a v-if="event.link" :href="event.link" v-html="event.title" />
          <span v-else v-html="event.title" />
        </h2>
        <div
          v-if="event.reviews.length"
          :class="[currentBackground ? 'text-white' : '']"
          class="mb-1"
        >
          <show-review
            v-for="(review, idx) in event.reviews"
            :key="idx"
            :url="review.link"
            :stars="review.stars"
            :count="review.totalReviews"
          />
        </div>
        <span
          v-if="event.address"
          class="text-xs font-sans leading-tight block"
          >{{ event.address }}</span
        >
      </div>

      <div class="w-auto-1 md:hidden pr-2 pt-2">
        <a
          href="#faqs"
          class="text-purle font-sans ml-auto text-sm group block focus:outline-none focus:ring faq-button"
          @click.prevent="scrollToTarget('#faqs')"
        >
          <span
            class="w-5 h-5 bg-black rounded-full flex items-center justify-center text-white font-display font-semibold text-base mx-auto faq-button__icon"
            >?</span
          >
          <span class="underline block text-xs faq-button__text">FAQs</span>
        </a>
      </div>
    </div>
  </div>
</header>
