
<div>
  <agile-slider
    v-if="slides && slides.length"
    ref="mainSlider"
    :autoplay="slides.length > 1"
    :autoplay-speed="5000"
    :nav-buttons="false"
    :dots="slides.length > 1"
    :speed="750"
    :class="{ 'mb-8 md:mb-12': slides.length < 2 }"
  >
    <slide
      v-for="(slide, idx) in slides"
      :key="idx"
      :image-url="slide.image.url"
      :alt="slide.image.alt"
      :caption="slide.caption"
    />
  </agile-slider>
  <article class="container-md">
    <app-page-header :heading="pageContent.productsHeading">
      <p v-html="pageContent.productsLeadInText" />
    </app-page-header>
    <section
      v-for="(group, idx) in productGroups"
      :key="idx"
      class="w-full mb-8 md:mb-12 mx-auto"
    >
      <h3 v-if="group.heading" class="mb-5">{{ group.heading }}</h3>
      <div
        v-if="group.description"
        class="pretty trim mb-5"
        v-html="group.description"
      ></div>
      <component :is="productGroupComponent(group.type)" :group="group" />
    </section>
  </article>
</div>
