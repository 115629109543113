/* eslint-disable no-param-reassign, no-underscore-dangle */

import BREAKPOINTS from "@/js/design/breakpoints.js";

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

function generateBreakpoints(breakpoints) {
  return Object.keys(breakpoints).reduce((acc, name) => {
    acc[name] = false;

    return acc;
  }, {});
}

function generateBreakpointGetters(breakpoints) {
  return Object.keys(breakpoints).reduce((acc, name) => {
    acc[`is${capitalize(name, true)}`] = (state) => state.breakpoints[name];

    return acc;
  }, {});
}

export default {
  state: {
    breakpoints: generateBreakpoints(BREAKPOINTS),
  },
  mutations: {
    SET_BREAKPOINT(state, { name, matches }) {
      state.breakpoints[name] = matches;
    },
  },
  getters: {
    ...generateBreakpointGetters(BREAKPOINTS),
    isMobile: (state, getters) => !getters.isTablet && !getters.isDesktop,
    isTablet: (state, getters) => getters.isSm && !getters.isDesktop,
    isDesktop: (state, getters) => getters.isMd,
  },
};
