
<portal to="modal">
  <div
    class="
    flex items-center justify-center
    fixed top-0 left-0 right-0 bottom-0 z-50
    bg-transparentBlackDark
    min-h-screen w-full
    pointer-events-none"
    :class="{
      'p-0 md:p-0': isFullScreenMobile,
      'p-5 md:p-0': !isFullScreenMobile,
    }"
  >
    <div
      :class="{
        [maxWidthClass]: true,
        'h-full md:h-auto': isFullScreenMobile,
        'h-auto md:h-auto': !isFullScreenMobile,
      }"
      class="w-full
      p-6 md:p-8
      relative
      overflow-auto
      bg-white ring
      pointer-events-auto
       "
      :style="{ maxHeight: isFullScreenMobile ? '100vh' : '90vh' }"
    >
      <div class="pretty">
        <slot />
      </div>
      <button
        v-if="withCloseButton"
        type="button"
        title="Close this modal"
        class="absolute top-0 right-0 p-2 sm:p-3 md:p-4"
        @click="$emit('close')"
      >
        <slot name="close">
          <icon
            icon="close"
            class="
                w-8 h-8
                text-black"
          />
        </slot>
      </button>
    </div>
  </div>
</portal>
