<template>
  <div
    class="w-14 h-14 bg-u-alert-red flex flex-wrap justify-center items-center rounded-full text-white -mt-6"
  >
    <div class="text-center">
      <span class="block leading-none uppercase text-xs">Save</span>
      <span class="block font-bold text-xl leading-none">{{ discount }}</span>
    </div>
  </div>
</template>
<script>
import { formatPrice } from "@/js/helpers/NumberHelper.js";

export default {
  props: {
    discount: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    formatPrice,
  },
};
</script>
