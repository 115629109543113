<template>
  <div class="font-sans">
    <label :for="id" class="sr-only">{{ label }}</label>
    <div class="flex flex-row w-full relative bg-transparent">
      <button
        :class="[`bg-${color}-light`]"
        :title="
          `Decrease quantity of ${label} ${
            currentQuantity > min ? `to ${currentQuantity - step}` : ``
          }`
        "
        :disabled="currentQuantity === min"
        type="button"
        class="w-24 h-10 cursor-pointer outline-none focus:ring flex flex-wrap justify-center items-center leading-none text-2xl font-bold border border-opacity-25 hover:border-opacity-50 focus:border-opacity-50 group border-black"
        @click="$emit('decrement')"
      >
        <span
          class="-mt-1 block opacity-25 group-hover:opacity-75 group-focus:opacity-75"
          >−</span
        >
      </button>
      <slot>
        <input
          :value="currentQuantity"
          type="text"
          :name="id"
          tabindex="-1"
          readonly="true"
          class="outline-none appearance-none focus:outline-none focus:none text-center w-full flex items-center border border-black border-opacity-25 border-r-0 border-l-0"
        />
      </slot>
      <button
        :class="[`bg-${color}-light`]"
        :title="`Increase quantity of ${label} to ${currentQuantity + step}`"
        class="w-24 h-10 cursor-pointer outline-none focus:ring flex flex-wrap justify-center items-center leading-none text-2xl font-bold border border-opacity-25 hover:border-opacity-50 focus:border-opacity-50 group border-black"
        @click="$emit('increment')"
      >
        <span
          class="-mt-1 block opacity-25 group-hover:opacity-75 group-focus:opacity-75"
          >+</span
        >
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: "purple",
      validator: (color) => ["purple", "blue", "yellow"].includes(color),
    },
    currentQuantity: {
      type: Number,
      required: true,
      validator: (n) => n >= 0 && Math.round(n) === n,
    },
  },
};
</script>
