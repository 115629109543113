
<div :class="wrapperClasses">
  <label class="sr-only" :for="id">{{ label }}</label>
  <input
    :id="id"
    ref="inputField"
    :value="value"
    v-bind="inputAttrs"
    :type="type"
    :class="inputClasses"
    @input="updateValue"
    @keydown.enter="$emit('enterkey')"
  />
</div>
