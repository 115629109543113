import initCheckout from "@/js/initCheckout.js";

import EventTickets from "@/js/EventTickets.vue";
import MerchPage from "@/js/MerchPage.vue";

import merchPageRouter from "@/js/router/merchPages.js";
import eventTicketRouter from "@/js/router/eventTickets.js";

import store from "@/js/store/store.js";

const APP_CONFIGS = [
  {
    el: "#merch-app",
    rootComponent: MerchPage,
    router: merchPageRouter,
    store,
  },
  {
    el: "#tickets-app",
    rootComponent: EventTickets,
    router: eventTicketRouter,
    store,
  },
];

window.addEventListener("DOMContentLoaded", () => {
  APP_CONFIGS.some((config) => {
    let app;

    if (document.querySelector(config.el)) {
      app = initCheckout(config);
    } else {
      app = false;
    }

    return app;
  });
});
