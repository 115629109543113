
<div :class="[isEnabled ? '' : disabledClasses]">
  <div v-if="!showAccessNeedsDetail" class="mb-4 md:mb-6">
    <h3 class="mb-1 md:mb-2 h5 font-sans font-bold">
      Does anyone in your group have special access needs?
    </h3>

    <div class="flex space-x-3">
      <div class="mb-1">
        <input
          id="yes-access-needs"
          v-model="hasAccessNeeds"
          type="radio"
          name="access-needs"
          :value="true"
          class="focus:ring focus:outline-none font-sans"
          @change="changehasAccessNeeds()"
        />
        <label for="yes-access-needs" class="ml-2 font-sans text-base"
          >Yes</label
        >
      </div>
      <div class="mb-1">
        <input
          id="no-access-needs"
          v-model="hasAccessNeeds"
          type="radio"
          name="access-needs"
          :value="false"
          class="focus:ring focus:outline-none font-sans"
          @change="changehasAccessNeeds()"
        />
        <label for="no-access-needs" class="ml-2 font-sans text-base"
          >No</label
        >
      </div>
    </div>
  </div>
  <div aria-live>
    <performance-access-needs-detail
      v-if="showAccessNeedsDetail"
      @cancel="cancelAccessNeeds"
      @submit="changehasAccessNeeds"
    />
    <div v-else-if="hasAccessNeeds" class="text-sm">
      <dl>
        <dt>Companion ticket requested?</dt>
        <dd class="font-bold">{{ !!companionTicket ? "Yes" : "No" }}</dd>
        <dt>Additional access needs information provided?</dt>
        <dd class="font-bold">{{ !!accessNeedsNotes ? "Yes" : "No" }}</dd>
      </dl>
      <a href="#" @click.prevent="() => (editingAccessNeeds = true)"
        >Edit access information</a
      >
    </div>
  </div>

  <div v-if="!showAccessNeedsDetail" class="text-center">
    <app-button
      class="btn--gradient block w-full sm:inline-block sm:w-auto"
      @click="buyNow"
      >Go to Checkout</app-button
    >
  </div>
</div>
