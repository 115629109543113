<template>
  <div>
    <div v-for="(product, idx) in group.products" :key="idx" class="mb-3">
      <product :product="product" />
    </div>
  </div>
</template>

<script>
import Product from "@/js/components/products/Product.vue";

export default {
  name: "ProductGroup",
  components: {
    Product,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
};
</script>
