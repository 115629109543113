const getPartsOf = (aTime) => aTime.split(":");

/**
 * Convert a time to a number of minutes
 * @param  {string} aTime 1:23
 * @return {int}       count of minutes
 */
export const timeInMinutes = (aTime) =>
  // hours
  parseInt(getPartsOf(aTime)[0], 10) * 60 +
  // minutes
  parseInt(getPartsOf(aTime)[1], 10);

export const formatTimeString = (timeString) => {
  const parts = timeString.split(":");

  return `${parts[0]}:${parts[1]}`;
};

export default {
  timeInMinutes,
  formatTimeString,
};
