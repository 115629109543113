/* eslint-disable */
import lineupApi from "@/js/services/lineupApi.js";
import craftApi from "@/js/services/craftApi.js";


import mutations from "@/js/store/modules/lineupApi/mutations.js";
import getters from "@/js/store/modules/lineupApi/getters.js";

import refreshLineupTicketAvailabilities from "@/js/store/modules/lineupApi/actions/refreshLineupTicketAvailabilities.js";
import updateReservationFromBasket from "@/js/store/modules/lineupApi/actions/updateReservationFromBasket.js";
import updateMerchReservationFromBasket from "@/js/store/modules/lineupApi/actions/updateMerchReservationFromBasket.js";
import completeCardOrder from "@/js/store/modules/lineupApi/actions/completeCardOrder.js";

const lineupApiStore = {
    state: () => ({
        lineupReservation: null,
        sessionUser: null,
        lineupOrder: null,
        lineupSession: null,
        tempFinalDiscount: null,
        paymentIntentClientSecret: null,
    }),
    mutations,
    getters,
    actions: {
        refreshLineupTicketAvailabilities,
        updateReservationFromBasket,
        updateMerchReservationFromBasket,
        completeCardOrder,
        async completePaymentRequestOrder(
            { commit, state }, { paymentMethod, complete }
        ) {
            if (!state.sessionUser) {
                throw new Error("No anonymous user has been created yet.");
            }

            const order = await lineupApi.convertSessionToOrder(
                state.lineupReservation.reference,
                state.sessionUser.accessToken,
                paymentMethod.id,
            );

            commit("setLineupOrder", order);
            commit("setTempFinalDiscount", state.lineupReservation.discount);
            commit("setLineupReservation", null);

            complete("success");

            return order;
        },
        async extendReservation({ commit, state, rootState, dispatch, rootGetters }) {
            const reservationDetails = await lineupApi.extendReservation(state.lineupReservation.reference);

            if (reservationDetails !== null) {
                commit("setLineupReservation", reservationDetails);
                commit("setLineupSession", reservationDetails.sessionId);
            }
        },
        async updateTicketPricesFromReservation({ commit }, payload) {
            payload.ticketReservations.forEach(({ ticket }) => {
                commit("updateTicketPrice", {
                    ticketId: ticket.id,
                    price: ticket.total,
                    fees: ticket.feeTotal,
                });
            });
        },
        async clearReservation({ commit, state }, payload) {
            if (!state.lineupReservation) {
                return;
            }
            try {
                await lineupApi.deleteReservation(state.lineupReservation.reference);
            } catch (e) {
                // In some circumstances the reservation may already have become invalid so let's squash this error
            }
            commit("clearLineupReservation");
        },
        async getAnonymousUserForSession({ commit, state }, customerDetails) {
            if (!state.sessionUser) {
                const userDetails = await lineupApi.createAnonymousUser(customerDetails);
                commit("setLineupUserDetails", userDetails);
                return state.sessionUser;
            }
            return state.sessionUser;
        },
    },
};

export { lineupApiStore as default };