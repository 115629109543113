<template>
  <div :class="wrapperClasses">
    <label class="sr-only" :for="id">{{ label }}</label>
    <input
      :id="id"
      ref="inputField"
      :value="value"
      v-bind="inputAttrs"
      :type="type"
      :class="inputClasses"
      @input="updateValue"
      @keydown.enter="$emit('enterkey')"
    />
  </div>
</template>
<script>
export const wrapperClasses = `border border-gray-dark border-opacity-50`;

export const inputClasses = `w-full block
          appearance-none
          p-3
          font-sans
          hover:border-black focus:border-black focus:outline-none focus:ring`;

export const component = {
  props: {
    value: {
      type: [String, Number, null],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
    },
    inputAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputClasses,
      wrapperClasses,
    };
  },
  computed: {
    id() {
      return `input-${this._uid}`;
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.$refs.inputField.value);
    },
  },
};

export default component;
</script>
