
<div class="relative z-0" :class="showBasket ? 'pb-38 sm:pb-24 md:pb-0' : ''">
  <app-site-header :with-nav="lineupReservation != null">
    <template #nav>
      <CountdownTimer />
    </template>
  </app-site-header>
  <app-modal v-if="appLoading">
    <app-loader />
  </app-modal>
  <div v-else class="mb-10 md:mb-12">
    <router-view />
  </div>
  <merch-basket v-if="showBasket" />
  <social-proof v-else />
  <reservation-has-expired-modal
    v-if="reservationHasExpired"
    @submit="dismissReservationHasExpired"
  />
  <reservation-extension-modal v-if="showReservationExtensionModal" />
  <clear-basket-confirmation-modal
    v-if="clearBasketConfirmationActive"
    @cancel="closeClearBasketConfirmationModal"
    @clear="clearBasketAndReset"
  />
  <generic-error-modal
    v-if="genericError"
    :title="genericError.title"
    :body="genericError.body"
    @dismiss="clearGenericError"
  />
  <faqs />
  <app-site-footer />
  <!--
    @todo sitewide loading state?
   -->
  <div class="z-50">
    <portal-target name="modal" />
  </div>
</div>
