
<header :class="withNav ? 'header px-5 py-1' : 'header px-5 py-2 md:py-5'">
  <div class="max-w-screen-2xl mx-auto">
    <div class="flex flex-wrap items-center justify-center">
      <div class="w-auto">
        <img
          :src="logo"
          alt="Layered Reality Logo"
          :class="withNav ? 'w-24' : 'w-24 md:w-28'"
        />
      </div>
      <div v-if="withNav" class="ml-auto w-auto">
        <slot name="nav" />
      </div>
    </div>
  </div>
</header>
