const shouldRound = (aNumber) => aNumber === Math.floor(aNumber);

export const formatUnitlessPrice = (aNumber, places = 2) =>
  parseFloat(Math.round(aNumber * 100) / 100, 10).toFixed(places);

export const formatPrice = (aNumber) =>
  `&pound;${formatUnitlessPrice(aNumber)}`;

export const formatPriceShort = (aNumber) => {
  const places = shouldRound(aNumber) ? 0 : 2;

  return `&pound;${formatUnitlessPrice(aNumber, places)}`;
};

export const formatPriceRange = (min, max) => {
  let result;

  if (min === max) {
    result = formatPriceShort(max);
  } else {
    result = `${formatPriceShort(min)}&ndash;${formatPriceShort(max)}`;
  }

  return result;
};

/**
 * Export a Vue mixin
 */
export default {
  methods: {
    formatPrice,
    formatPriceRange,
    formatPriceShort,
    formatUnitlessPrice,
  },
};
