
<article class="relative max-w-md lg:max-w-auto">
  <button
    :title="title"
    :class="{
      'cursor-not-allowed bg-gray-light': isDisabled,
      'hover:border-opacity-75 focus:border-opacity-75 focus:outline-none focus:ring': !isDisabled,
    }"
    :disabled="isDisabled"
    :style="highlightStyle"
    type="button"
    class="z-1 text-gray-dark font-sans border border-gray-dark border-opacity-10 block w-full transition duration-200 ease-in-out group"
    @click="setSelected"
  >
    <div class="flex flex-wrap">
      <div class="w-14 md:w-16 text-left bg-white p-2">
        <span class="block text-xs sm:text-sm leading-tight uppercase">
          {{ dayOfWeek }}<br />{{ dayOfMonth }} {{ month }}<br />{{
            year
          }}</span
        >
      </div>
      <div class="flex-1 p-2 text-left">
        <span
          class="block font-bold text-sm uppercase leading-tight"
          :class="{ 'opacity-50': isDisabled }"
          >{{ ticketGroup.displayName }}</span
        >
        <ul class="leading-tight">
          <li v-if="isSoldOut" class="uppercase opacity-50 text-sm">
            Sold out
          </li>
          <li v-else-if="isLowerAvailability" class="opacity-50 text-sm">
            Not available for {{ selectedQuantity }} ticket{{
              selectedQuantity !== 1 ? `s` : ``
            }}
          </li>
          <ticket-slot-availability
            v-for="(timeslot, idx) in timeslots"
            v-else
            :key="idx"
            :timeslot="timeslot"
            :options="timeslotOptions"
          />
        </ul>
        <span
          v-if="performanceType && performanceType.caption"
          class="block text-xs font-medium text-black mt-2 leading-tight w-11/12"
          v-html="`* ${performanceType.caption}`"
        />
      </div>
      <div v-if="isSoldOut" class="w-20 md:w-20 px-3" />
      <div
        v-else
        class="w-20 md:w-20 bg-white flex flex-wrap justify-center p-2"
      >
        <div class="text-center">
          <span class="block h-4">
            <span v-if="hasPriceRange" class="block text-xs opacity-50"
              >from</span
            >
          </span>
          <span
            class="block flex items-start font-semibold font-display leading-none text-center"
          >
            <span
              class="text-3xl"
              v-html="formatPriceShort(priceSource.minPrice)"
            />
            <span
              v-if="performanceType.caption"
              class="text-sm"
              title="performanceType.caption"
              >*</span
            >
          </span>
          <span class="block text-xs opacity-50">per ticket</span>
        </div>
      </div>
      <div class="w-auto flex flex-wrap items-center pr-2 bg-white">
        <span
          v-if="!isSoldOut"
          class="block font-bold text-black opacity-25 group-hover:opacity-100 transition duration-200 ease-in-out"
        >
          <icon icon="chevron-down" class="w-2 md:w-3" />
        </span>
      </div>
    </div>
  </button>
  <ticket-group-tooltip
    v-if="tooltipContent"
    ticket-group-type="ticketGroup.performanceType"
    :content="tooltipContent"
    class="absolute z-10"
    :style="{
      left: '150px',
      top: '5px',
    }"
  />
</article>
