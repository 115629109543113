
<li
  :class="{
    'opacity-50': isDisabled,
  }"
  class="flex flex-wrap text-xs sm:text-sm"
>
  <span class="flex-1 pr-3">{{ timeslot.from }} - {{ timeslot.to }}</span>
  <span class="w-auto">
    <span v-if="isSoldOut" class="uppercase">Sold out</span>
    <span
      v-else
      :class="{ 'text-u-alert-red block': showWarning }"
      v-html="scarcityText"
    />
  </span>
</li>
