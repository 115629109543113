import lineupApi from "@/js/services/lineupApi.js";

export default function refreshLineupTicketAvailabilities(
  { commit, rootState },
  { ticketGroupKey }
) {
  return new Promise((resolve, reject) => {
    const { event } = rootState.craftApi;

    if (!event) {
      resolve();
    }

    const { date } = event.ticketGroups[ticketGroupKey];

    // Get all ticket info for the event for the specified date
    lineupApi
      .getPerformancesOnDate(event.lineupEventId, date)
      .then((performancesOnDate) => {
        performancesOnDate.forEach((updatedPerformance) =>
          commit("updateTicketAvailability", {
            ticketGroupKey,
            updatedPerformance,
          })
        );
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}
