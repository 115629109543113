<template>
  <portal to="modal">
    <div
      class="
      flex items-center justify-center
      fixed top-0 left-0 right-0 bottom-0 z-50
      bg-transparentBlackDark
      min-h-screen w-full
      pointer-events-none"
      :class="{
        'p-0 md:p-0': isFullScreenMobile,
        'p-5 md:p-0': !isFullScreenMobile,
      }"
    >
      <div
        :class="{
          [maxWidthClass]: true,
          'h-full md:h-auto': isFullScreenMobile,
          'h-auto md:h-auto': !isFullScreenMobile,
        }"
        class="w-full
        p-6 md:p-8
        relative
        overflow-auto
        bg-white ring
        pointer-events-auto
         "
        :style="{ maxHeight: isFullScreenMobile ? '100vh' : '90vh' }"
      >
        <div class="pretty">
          <slot />
        </div>
        <button
          v-if="withCloseButton"
          type="button"
          title="Close this modal"
          class="absolute top-0 right-0 p-2 sm:p-3 md:p-4"
          @click="$emit('close')"
        >
          <slot name="close">
            <icon
              icon="close"
              class="
                  w-8 h-8
                  text-black"
            />
          </slot>
        </button>
      </div>
    </div>
  </portal>
</template>
<script>
import Icon from "@/js/components/shared/Icon.vue";

export default {
  name: "AppModal",
  components: {
    Icon,
  },
  props: {
    maxW: {
      type: String,
      default: "xs",
      validator: (val) => ["xs", "sm", "md", "lg", "xl"].includes(val),
    },
    withCloseButton: {
      type: Boolean,
      default: false,
    },
    isFullScreenMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    maxWidthClass() {
      let result;
      // @hack implemented this way so that purgecss doesn't get rid of our width classes
      switch (this.maxW) {
        case "xs":
        default:
          result = "md:max-w-xs";
          break;
        case "sm":
          result = "md:max-w-sm";
          break;
        case "md":
          result = "md:max-w-md";
          break;
        case "lg":
          result = "md:max-w-lg";
          break;
        case "xl":
          result = "md:max-w-xl";
          break;
      }
      return result;
    },
  },
  created() {
    document.body.style.overflow = "hidden";
  },
  beforeDestroy() {
    document.body.style.overflow = "visible";
  },
};
</script>
