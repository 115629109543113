
<div class="loading" :class="wrapperClass">
  <icon icon="loading" class="loading__icon" />
  <div v-if="showMessage" class="w-full">
    <span class="loading__text">
      <slot>
        <span v-html="currentMessage" />
      </slot>
    </span>
  </div>
</div>
