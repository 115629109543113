import Moment from "moment";

import {
  PRODUCT_RULE_DATE_RANGE,
  PRODUCT_RULE_DAYS_OF_THE_WEEK,
  PRODUCT_RULE_PERFORMANCE_TYPE,
  PRODUCT_RULE_TIME_RANGE,
} from "@/js/constants/productRuleTypes.js";

const toMoment = (date, time = "") => Moment.utc(`${date} ${time}`);
const toDotw = (date) => toMoment(date).day();

const testDateRange = (date, time, conditions) =>
  conditions.some(({ start, end }) => {
    let test = true;

    if (start && end) {
      test = toMoment(date, time).isBetween(
        Moment(start),
        Moment(end),
        "second",
        "[]"
      );
    } else if (start) {
      test = toMoment(date, time).isSameOrAfter(Moment(start), Moment(end));
    } else if (end) {
      test = toMoment(date, time).isBefore(Moment(end));
    }

    return test;
  });

const testTimeRange = (date, time, conditions) =>
  conditions.some(({ start, end }) =>
    toMoment(date, time).isBetween(
      toMoment(date, start),
      toMoment(date, end),
      "second",
      "[]"
    )
  );

const test = (
  [testType, conditions],
  { date, time, performanceTypeDisplayName }
) => {
  let result;

  switch (testType) {
    case PRODUCT_RULE_DATE_RANGE:
      result = testDateRange(date, time, conditions);
      break;
    case PRODUCT_RULE_TIME_RANGE:
      result = testTimeRange(date, time, conditions);
      break;
    case PRODUCT_RULE_DAYS_OF_THE_WEEK:
      result = conditions.includes(toDotw(date));
      break;
    case PRODUCT_RULE_PERFORMANCE_TYPE:
      result = conditions.includes(performanceTypeDisplayName.toUpperCase());
      break;
    default:
      result = true;
      break;
  }

  return result;
};

const testAll = (rules, ticket) => {
  const ruleList = Object.entries(rules);

  if (!ruleList.length) {
    return true;
  }

  return ruleList.every((rule) => test(rule, ticket));
};

const getPassingProducts = ({ products, rules, ticket }) =>
  testAll(rules, ticket) ? products : [];

export const filterProductsByRules = (productGroups, ticket) => {
  const matchingProducts = productGroups.reduce(
    (result, { products, rules }) =>
      result.concat(getPassingProducts({ products, rules, ticket })),
    []
  );

  // remove duplicates that passed multiple rule sets
  return matchingProducts.filter(
    ({ lineupId }, idx, arr) =>
      idx === arr.findIndex((p) => p.lineupId === lineupId)
  );
};
