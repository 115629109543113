
<section
  :class="[
    wrapperClasses,
    isExpanded ? 'accordion--is-expanded' : 'accordion',
  ]"
>
  <button
    :id="buttonId"
    :title="title"
    :aria-controls="contentId"
    type="button"
    class="p-3 flex flex-wrap items-center w-full text-left accordion__button focus:ring focus:outline-none"
    @click="toggle()"
  >
    <h3 :class="[headingClasses, 'flex-1 pr-3 mb-0 font-serif font-normal']">
      <slot name="heading" />
    </h3>
    <div class="w-auto accordion__content">
      <icon
        icon="chevron-vertical"
        :class="[isExpanded ? 'rotate-180' : 'rotate-0']"
        class="w-5 transform"
      />
    </div>
  </button>
  <div
    :id="contentId"
    :aria-expanded="isExpanded"
    :class="[isExpanded ? 'block pt-2 p-3' : 'hidden', contentClasses]"
    class="text-black text-base overflow-hidden pretty trim"
  >
    <slot />
  </div>
</section>
