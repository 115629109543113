<template>
  <div :class="type ? 'callout--' + type : ''" class="callout pretty trim">
    <span class="callout__icon" aria-hidden="true">!</span>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: [String, Boolean],
      default: false,
      validator: (value) =>
        [false, "warning", "danger", "success"].includes(value),
    },
  },
};
</script>
