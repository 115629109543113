
<section class="border border-gray mb-3">
  <app-accordion
    a11y-title="order summary"
    wrapper-classes=""
    content-classes=""
    :start-expanded="true"
  >
    <template #heading>
      <span class="flex-1 pr-3 font-display text-xl md:text-2xl h-12">
        Your order
        <span class="text-xs block font-sans">
          {{
            merchTotalQuantity > 1
              ? `${merchTotalQuantity} items`
              : `${merchTotalQuantity} item`
          }}, <span class="link">View Order</span></span
        >
      </span>
    </template>

    <table class="mb-2 w-full">
      <checkout-order-summary-item
        v-for="product in merchProductsForCheckout"
        :key="product.lineupId"
        :product-id="product.lineupId"
        :removeable="true"
        @remove="handleRemoveProduct"
      >
        <template #label>
          <template v-if="product.type === PRODUCT_TYPE_GIFT_VOUCHERS">
            Gift Voucher Purchase
          </template>
          <template v-else>
            {{ product.quantity }} &times; {{ product.title }} @
          </template>
        </template>
        <template #value>
          <template v-if="product.type === PRODUCT_TYPE_GIFT_VOUCHERS">
            <span v-html="formatPrice(product.price * product.quantity)" />
          </template>
          <template v-else>
            <span v-html="formatPrice(product.price)" />
          </template>
        </template>
      </checkout-order-summary-item>
      <checkout-order-summary-item
        v-if="merchTotalPrice.discount"
        :removeable="false"
      >
        <template #label>Your discount</template>
        <template #value
          ><span v-html="`- ${formatPrice(merchTotalPrice.discount)}`"
        /></template>
      </checkout-order-summary-item>
    </table>
  </app-accordion>
  <div class="pt-2 p-3">
    <table class="w-full">
      <checkout-order-summary-item :removeable="false">
        <template #label>
          <span id="merchTotalPrice" class="uppercase font-display text-lg"
            >Total</span
          >
        </template>
        <template #value>
          <span
            class="uppercase font-display font-semibold text-2xl"
            aria-labelled-by="merchTotalPrice"
            v-html="formatPrice(merchTotalPrice.afterDiscount)"
          />
        </template>
      </checkout-order-summary-item>
    </table>
  </div>
</section>
