<template>
  <button
    :title="title"
    :disabled="isDisabled"
    type="button"
    class="quantity
        w-20 sm:w-24 md:w-28
        m-2 md:m-4
        md:text-2xl"
    @click="selectQuantity"
  >
    {{ value }}
  </button>
</template>

<script>
import GoogleAnalytics from "@/js/services/googleAnalytics.js";
import ROUTES from "@/js/constants/routeNames.js";

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return `Select ${this.value} tickets`;
    },
  },
  methods: {
    selectQuantity() {
      if (!this.isDisabled) {
        GoogleAnalytics.sendEvent("click", "Tickets", "People", this.value);
        this.$store.commit("setQuantity", this.value);
        this.$router.push({ name: ROUTES.TICKETS });
      }
    },
  },
};
</script>
