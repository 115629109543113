import lineupApi from "@/js/services/lineupApi.js";
import craftApi from "@/js/services/craftApi.js";

import { LINEUP } from "@/js/services/config.js";

export default async function updateReservationFromBasket(
  { commit, state, rootState, dispatch, rootGetters },
  applyPendingDiscount = false
) {
  const tickets = Object.entries(rootState.selectedTickets).map(
    ([ticketId, quantity]) => ({
      ticketId: parseInt(ticketId, 10),
      quantity,
    })
  );

  const products = rootState.selectedProducts
    .map((x) => ({
      productId: x.lineupId,
      quantity: x.quantity,
    }))
    .filter((x) => x.quantity > 0);
  let reservationDetails = null;

  if (!rootState.companionTicket) {
    try {
      reservationDetails = await lineupApi.createReservation(
        tickets,
        products,
        state.lineupSession
      );
    } catch (e) {
      // Reservation failed - maybe because there are no tickets left
      throw new Error("Reservation could not be completed");
    }
  } else {
    try {
      reservationDetails = await craftApi.createReservationWithCompanion(
        tickets,
        products,
        state.lineupSession,
        rootState.selectedPerformance.id
      );
    } catch (e) {
      // Reservation failed - maybe because there are no tickets left
      throw new Error("Reservation could not be completed");
    }
  }

  // Apply any pending discount code
  if (applyPendingDiscount && rootState.suppliedDiscountCode) {
    try {
      reservationDetails = await lineupApi.applyDiscountToReservation(
        reservationDetails.reference,
        rootState.suppliedDiscountCode
      );
    } catch (e) {
      // Set discount error?
      if (e.response && e.response.data) {
        commit(
          "setDiscountCodeError",
          e.response.data._metadata.errors[0].messages
        );
      } else {
        commit(
          "setDiscountCodeError",
          "The supplied discount code was invalid"
        );
      }
    }
  }

  // Apply any pending gift voucher
  if (applyPendingDiscount && rootState.suppliedGiftVoucher) {
    try {
      reservationDetails = await lineupApi.applyDiscountToReservation(
        reservationDetails.reference,
        rootState.suppliedGiftVoucher
      );
    } catch (e) {
      // Set discount error?
      if (e.response && e.response.data) {
        commit(
          "setDiscountCodeError",
          e.response.data._metadata.errors[0].messages
        );
      } else {
        commit(
          "setDiscountCodeError",
          "The supplied discount code was invalid"
        );
      }
    }
  }

  if (rootState.accessNeedsSet === true) {
    await craftApi.attachFormToReservation(
      reservationDetails.reference,
      LINEUP.ACCESS_FORM_ID,
      {
        companionTicket: rootState.companionTicket,
        accessNotes: rootState.accessNeedsNotes,
      }
    );
  }

  if (reservationDetails !== null) {
    commit("setLineupReservation", reservationDetails);
    commit("setLineupSession", reservationDetails.sessionId);

    if (reservationDetails.total > rootGetters.eventTotalPrice.toDisplay) {
      commit("setTicketPriceChanged", true);
      // Update any tickets with the new price
      await dispatch("updateTicketPricesFromReservation", {
        eventId: reservationDetails.ticketReservations[0].event.id,
        ticketReservations: reservationDetails.ticketReservations,
      });
    }
  }
}
