<template>
  <div class>
    <app-hero-featured :event="craftEvent" :banner="pageContent.gateBanner" />
    <section class="container-md mt-6 md:mt-8">
      <div class="text-center mb-4 md:mb-6 xl:mb-8">
        <!-- Header -->
        <h1
          class="text-4xl sm:text-5xl md:text-6xl xl:text-7xl uppercase font-display font-semibold leading-tight block"
        >
          {{ pageContent.eventDisabledTitle }}
        </h1>
      </div>
      <div class="pretty" v-html="pageContent.eventDisabledMessage" />
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import AppHeroFeatured from "@/js/components/shared/AppHeroFeatured.vue";

export default {
  components: {
    AppHeroFeatured,
  },
  computed: {
    ...mapState({
      craftEvent: ({ craftApi }) => craftApi.event,
    }),
    ...mapGetters(["pageContent"]),
  },
};
</script>
