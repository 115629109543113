<template>
  <section
    :class="[
      wrapperClasses,
      isExpanded ? 'accordion--is-expanded' : 'accordion',
    ]"
  >
    <button
      :id="buttonId"
      :title="title"
      :aria-controls="contentId"
      type="button"
      class="p-3 flex flex-wrap items-center w-full text-left accordion__button focus:ring focus:outline-none"
      @click="toggle()"
    >
      <h3 :class="[headingClasses, 'flex-1 pr-3 mb-0 font-serif font-normal']">
        <slot name="heading" />
      </h3>
      <div class="w-auto accordion__content">
        <icon
          icon="chevron-vertical"
          :class="[isExpanded ? 'rotate-180' : 'rotate-0']"
          class="w-5 transform"
        />
      </div>
    </button>
    <div
      :id="contentId"
      :aria-expanded="isExpanded"
      :class="[isExpanded ? 'block pt-2 p-3' : 'hidden', contentClasses]"
      class="text-black text-base overflow-hidden pretty trim"
    >
      <slot />
    </div>
  </section>
</template>
<script>
import { toKebabCase } from "@/js/helpers/StringHelper.js";

import Icon from "@/js/components/shared/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    wrapperClasses: {
      type: String,
      default: "accordion",
    },
    headingClasses: {
      type: String,
      default: "text-base md:text-lg",
    },
    contentClasses: {
      type: String,
      default: "accordion__inner",
    },
    a11yTitle: {
      type: [String, null],
      default: null,
    },
    startExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    title() {
      let result;

      if (this.isExpanded) {
        result = `Hide section ${
          this.a11yTitle ? `about: ${this.a11yTitle}` : ``
        }`;
      } else {
        result = `Show more information ${
          this.a11yTitle ? `about: ${this.a11yTitle}` : ``
        }`;
      }

      return result;
    },
    contentId() {
      return `${toKebabCase(this.heading ?? "accordion")}-${this._uid}`;
    },
    buttonId() {
      return `${toKebabCase(this.heading ?? "accordion")}-${this._uid}`;
    },
  },
  mounted() {
    if (this.startExpanded) {
      this.isExpanded = true;
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
      this.$emit("toggle", this.isExpanded);
    },
  },
};
</script>
