/* eslint-disable no-shadow */
/* eslint-disable consistent-return */

import VueRouter from "@/js/router/baseRouter.js";

import ROUTES from "@/js/constants/routeNames.js";

import Store from "@/js/store/store.js";

import EventDisabled from "@/js/pages/EventDisabled.vue";
import MerchProducts from "@/js/pages/MerchProducts.vue";
import MerchCheckout from "@/js/pages/MerchCheckout.vue";
import MerchConfirmation from "@/js/pages/MerchConfirmation.vue";

import GoogleAnalytics from "@/js/services/googleAnalytics.js";
import Raygun from "@/js/services/raygun.js";

const routes = [
  { name: ROUTES.DISABLED, path: "/disabled", component: EventDisabled },
  { name: ROUTES.MERCH, path: "/", component: MerchProducts },
  { name: ROUTES.CHECKOUT, path: "/checkout", component: MerchCheckout },
  {
    name: ROUTES.CONFIRMATION,
    path: "/confirmation",
    component: MerchConfirmation,
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior(to, from, savedPosition) {
    let result;

    if (savedPosition) {
      result = savedPosition;
    } else {
      result = { x: 0, y: 0 };
    }

    return result;
  },
});

export function guards(to, from, next) {
  let shouldContinue;

  const route = { to, next };

  const env = {
    state: Store.state,
    commit: Store.commit,
    getters: Store.getters,
  };

  // Send everything to disabled if it is activated
  const requireIsNotDisabled = ({ next }, { ticketingIsDisabled }) => {
    if (ticketingIsDisabled) {
      next({ name: "disabled" });

      return false;
    }

    return true;
  };

  // Prevent going to disabled  if it isn't activated
  const requireIsNotEnabled = ({ next }, { ticketingIsDisabled }) => {
    if (!ticketingIsDisabled && to.name === "disabled") {
      next({ name: "gate" });

      return false;
    }

    return true;
  };

  const requireState = ({ next }, obj, property) => {
    if (!obj[property]) {
      next(false);

      return false;
    }

    return true;
  };

  switch (to.name) {
    case "disabled":
      shouldContinue = requireIsNotEnabled(route, env);
      break;
    case "merch":
      shouldContinue = requireIsNotDisabled(route, env);
      break;
    case "checkout":
      // @todo require items in basket
      shouldContinue =
        requireIsNotDisabled(route, env) &&
        requireState(route, env.state, "selectedProducts") &&
        !!env.state.selectedProducts.length;
      break;
    case "confirmation":
      shouldContinue =
        requireIsNotDisabled(route, env) &&
        requireState(route, env.getters, "completedOrder");
      break;
    default:
      shouldContinue = true;
      break;
  }

  if (!shouldContinue) {
    return false;
  }

  return next();
}

router.beforeEach(guards);

router.afterEach((to) => {
  GoogleAnalytics.setPage(to.path);
  Raygun.trackPageView(to.path);
  GoogleAnalytics.sendPageView();
});

export default router;
