<template>
  <div
    v-if="reservationExpiresAt"
    class="flex flex-wrap items-center bg-transparent flex-1 py-1 justify-center"
  >
    <span class="block font-display fonts-semibold leading-none pr-2 text-white"
      >Your order is being held for</span
    >

    <div class="countdown-timer">
      <span class="text-white font-display font-semibold leading-none">{{
        countdownString
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Moment from "moment";
import ROUTES from "@/js/constants/routeNames.js";

export default {
  components: {},
  props: {},
  data() {
    return {
      timer: null,
      expired: false,
      countdownString: "-",
      timeRemaining: 999999,
    };
  },
  computed: {
    ...mapState({
      lineupReservation: ({ lineupApi }) => lineupApi.lineupReservation,
    }),
    reservationExpiresAt() {
      let result;

      if (!this.lineupReservation) {
        result = null;
      } else {
        result = new Moment(this.lineupReservation.expireAt);
      }
      return result;
    },
  },
  watch: {
    async expired(isExpired) {
      if (isExpired) {
        // Clear any remaining LU reservation
        await this.$store.dispatch("clearReservation");
        // Clear locally selected options
        this.$store.commit("clearSelectedOptions");
        this.$store.commit("setReservationHasExpired", true);
        this.$router.push({ name: ROUTES.QUANTITY });
      }
    },
    timeRemaining(timeRemaining) {
      // This will only fire once because the timeRemaining will never fluctuate
      // between this number and the interval timer fires every 1 second
      if (timeRemaining === 60) {
        this.$store.commit("setShowReservationExtensionModal", true);
      }
    },
  },
  mounted() {
    this.startPolling();
  },
  async beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    startPolling() {
      this.timer = setInterval(() => {
        // Refresh local countdown representation
        const duration = Moment.duration(
          this.reservationExpiresAt.diff(new Moment())
        );
        if (duration.asSeconds() < 1) {
          this.expired = true;
          this.timeRemaining = 0;
          this.countdownString = `-`;
        } else {
          this.timeRemaining = Math.round(duration.asSeconds());
          this.countdownString = `${duration.minutes()}:${duration
            .seconds()
            .toString()
            .padStart(2, "0")}`;
        }
      }, 1000);
    },
  },
};
</script>
