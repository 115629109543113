
<div>
  <template v-if="availableEventProducts.length">
    <agile-slider
      v-if="slides && slides.length"
      ref="mainSlider"
      :autoplay="slides.length > 1"
      :autoplay-speed="5000"
      :nav-buttons="false"
      :dots="slides.length > 1"
      :speed="750"
      :class="{ 'mb-8 md:mb-12': slides.length < 2 }"
    >
      <slide
        v-for="(slide, idx) in slides"
        :key="idx"
        :image-url="slide.image.url"
        :alt="slide.image.alt"
        :caption="slide.caption"
      />
    </agile-slider>
    <div
      class="container-md"
      :class="{
        'mt-12': !slides || !slides.length,
      }"
    >
      <app-page-header :heading="pageContent.productsHeading">
        <h5 class="text-u-alert-red" v-html="pageContent.productsLeadInText" />
      </app-page-header>
      <div class="w-full mb-5 md:mb-8">
        <div
          v-for="product in availableEventProducts"
          :key="product.lineupId"
          class="mb-3"
        >
          <product :product="product" />
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="mt-8">
      <app-loader />
    </div>
  </template>
  <clear-basket-confirmation-modal
    v-if="showClearBasketConfirmation"
    @cancel="goBackToCheckout"
    @clear="clearBasketAndReset"
  />
</div>
