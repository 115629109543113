<template>
  <div v-if="event" class="relative bg-white md:bg-gray-light">
    <component
      :is="event.link ? 'a' : 'span'"
      :href="event.link ? event.link : null"
    >
      <picture class="w-full block">
        <source :srcset="banner.smallImage" media="(max-width: 639px)" />
        <source
          :srcset="banner.mediumImage"
          media="(min-width: 640px) and (max-width: 1023px)"
        />
        <source :srcset="banner.largeImage" media="(min-width: 1024px)" />
        <img :src="banner.smallImage" :alt="event.title" class="block w-full" />
      </picture>
    </component>
    <div v-if="event.reviews.length" class="absolute bottom-0 right-0 left-0">
      <div
        class="container-md flex justify-center relative z-30 text-white
          pb-8 md:pb-12 lg:pb-8"
      >
        <show-review
          v-for="(review, idx) in event.reviews"
          :key="idx"
          :url="review.link"
          :stars="review.stars"
          :count="review.totalReviews"
          wrapper-class="mr-2 inline-block text-white hover:text-u-highlight focus:text-u-highlight focus:outline-none focus:ring"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AppHero from "@/js/components/shared/AppHero.vue";

export default {
  extends: AppHero,
};
</script>
