export default {
  setLineupUserDetails(state, payload) {
    state.sessionUser = payload;
  },
  setLineupReservation(state, value) {
    state.lineupReservation = value;
  },
  setLineupSession(state, value) {
    state.lineupSession = value;
  },
  clearLineupReservation(state) {
    state.lineupReservation = null;
  },
  setLineupOrder(state, value) {
    state.lineupOrder = value;
  },
  setTempFinalDiscount(state, value) {
    state.tempFinalDiscount = value;
  },
  setPaymentIntentClientSecret(state, paymentIntentClientSecret) {
    state.paymentIntentClientSecret = paymentIntentClientSecret;
  },
};
