<template>
  <div class="container-md mt-6 md:mt-8 mb-10 md:mb-12">
    <h1>Thanks for booking {{ customer.firstName }}</h1>
    <div
      class="pretty trim mt-5"
      v-html="pageContent.confirmationIntroMessage"
    />
    <div class="mb-5">
      <div v-if="shouldShowConfirmationButton">
        <div class="sm:flex sm:flex-wrap items-center justify-center mt-8">
          <div class="w-full sm:w-1/2">
            <app-button
              tag-name="a"
              target="_blank"
              :href="pageContent.confirmationButtonUrl"
              :variants="['block', 'gradient']"
              >{{ pageContent.confirmationButtonText }}</app-button
            >
          </div>
        </div>
      </div>
    </div>
    <p class="mb-5 md:mb-8">
      We’ll send your confirmation email and any gift vouchers to the email
      address you used for this order.
    </p>
    <div class="flex flex-wrap">
      <div class="text-left w-full md:flex-1 mb-4 md:mb-0">
        <span class="h4 mb-1">Your order:</span>
        <span class="block text-base font-sans"
          >Order reference: <code>{{ order.reference }}</code></span
        >
      </div>
      <div class="text-left md:text-right w-full md:flex-1">
        <span class="block text-xs md:text-base font-sans"
          >{{ merchTotalQuantity }}
          {{ merchTotalQuantity > 1 ? "Items" : "Item" }}</span
        >
      </div>
    </div>
    <div class="py-4 md:py-8 md:px-12">
      <table class="mb-2 w-full">
        <checkout-order-summary-item
          v-for="product in merchProductsForCheckout"
          :key="product.lineupId"
          :product-id="product.lineupId"
          :removeable="false"
        >
          <template #label>
            <template v-if="product.type === PRODUCT_TYPE_GIFT_VOUCHERS">
              Gift Voucher Purchase
            </template>
            <template v-else>
              {{ product.quantity }} &times; {{ product.title }} @
            </template>
          </template>
          <template #value>
            <template v-if="product.type === PRODUCT_TYPE_GIFT_VOUCHERS">
              <span v-html="formatPrice(product.price * product.quantity)" />
            </template>
            <template v-else>
              <span v-html="formatPrice(product.price)" />
            </template>
          </template>
        </checkout-order-summary-item>
        <checkout-order-summary-item
          v-if="merchTotalPrice.discount"
          :has-removeables="false"
        >
          <template #label>Subtotal</template>
          <template #value
            ><span v-html="formatPrice(merchTotalPrice.beforeDiscount)"
          /></template>
        </checkout-order-summary-item>
        <checkout-order-summary-item
          v-if="merchTotalPrice.discount"
          :has-removeables="false"
        >
          <template #label>Your discount</template>
          <template #value
            ><span v-html="`- ${formatPrice(merchTotalPrice.discount)}`"
          /></template>
        </checkout-order-summary-item>
        <checkout-order-summary-item :has-removeables="false">
          <template #label><span class="font-bold">Order Total</span></template>
          <template #value
            ><span
              class="font-bold"
              v-html="formatPrice(merchTotalPrice.afterDiscount)"
          /></template>
        </checkout-order-summary-item>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import { PRODUCT_TYPE_GIFT_VOUCHERS } from "@/js/constants/productTypes.js";
import Confirmation from "@/js/pages/Confirmation.vue";

export default {
  extends: Confirmation,
  data: () => ({ PRODUCT_TYPE_GIFT_VOUCHERS }),
  computed: {
    ...mapGetters([
      "merchProductsForCheckout",
      "merchTotalQuantity",
      "merchTotalPrice",
    ]),
  },
};
</script>
