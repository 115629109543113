<template>
  <li
    :class="{
      'opacity-50': isDisabled,
    }"
    class="flex flex-wrap text-xs sm:text-sm"
  >
    <span class="flex-1 pr-3">{{ timeslot.from }} - {{ timeslot.to }}</span>
    <span class="w-auto">
      <span v-if="isSoldOut" class="uppercase">Sold out</span>
      <span
        v-else
        :class="{ 'text-u-alert-red block': showWarning }"
        v-html="scarcityText"
      />
    </span>
  </li>
</template>
<script>
export default {
  props: {
    timeslot: {
      required: true,
      type: Object,
    },
    options: {
      required: true,
      type: Object,
    },
  },
  computed: {
    isSoldOut() {
      return (
        this.timeslot.totalAvailableTicketCount <=
        this.options.minAvailabilityForSoldOut
      );
    },
    isDisabled() {
      return this.isSoldOut || this.hasInsuffcientAvailability;
    },
    hasInsuffcientAvailability() {
      return this.timeslot.availablePerformanceCount < 1;
    },
    showWarning() {
      return (
        this.timeslot.availablePerformanceCount <=
          this.options.slotShowWarningThreshold &&
        this.timeslot.availablePerformanceCount > 0
      );
    },
    scarcityText() {
      let result;

      if (this.hasInsuffcientAvailability) {
        result = `-`;
      } else if (
        this.timeslot.availablePerformanceCount >
        this.options.slotShowWarningThreshold
      ) {
        result = `Available`;
      } else {
        result = `Last Few`;
      }

      return result;
    },
  },
};
</script>
