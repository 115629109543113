<template>
  <div id="faqs" class="container-md mb-10 md:mb-12">
    <h2 class="block text-xl md:text-2xl font-display mb-2">FAQs</h2>
    <div class="mb-3">
      <app-accordion v-for="(item, idx) in items" :key="idx">
        <template #heading>
          {{ item.title }}
        </template>
        <div class="trim pretty" v-html="item.body" />
      </app-accordion>
    </div>
    <a :href="helpCentreUrl" class="link" target="_blank">
      See All
      <abbr title="Frequently Asked Questions">FAQs</abbr>
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { CRAFT } from "@/js/services/config.js";
import AppAccordion from "@/js/components/shared/AppAccordion.vue";

/**
 * @todo  wire up helpcentre url
 */
export default {
  components: {
    AppAccordion,
  },
  data() {
    return {
      helpCentreUrl: CRAFT.HELP_CENTRE_URL,
    };
  },
  computed: {
    ...mapState({
      faqs: ({ craftApi }) => craftApi.faqs,
    }),
    items() {
      return this.faqs?.length
        ? this.faqs.map((x) => {
            return {
              title: x.question,
              body: x.answer,
            };
          })
        : [];
    },
  },
};
</script>
