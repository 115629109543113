
<figure class="relative">
  <img
    :alt="props.alt"
    :src="props.imageUrl"
    class="w-full object-cover object-center"
  />

  <figcaption
    v-if="props.caption"
    class="absolute bottom-0 left-0 right-0 w-full bg-gradient-to-t from from-black to-transparent max-h-50% h-full flex flex-wrap items-end text-white py-3 font-sans"
  >
    <div class="container-md text-left">
      {{ props.caption }}
    </div>
  </figcaption>
</figure>
