
<div class="mb-5 md:mb-8">
  <section class="bg-gray-light pt-4 pb-2 md:pt-4 md:pb-6">
    <div class="container-md">
      <div class="flex flex-wrap">
        <div
          v-for="(item, idx) in items"
          :key="idx"
          class="w-1/2 md:w-1/4 text-center mb-4 md:mb-0"
        >
          <!-- @todo icon integration-->
          <icon
            v-if="item.icon"
            :icon="item.icon"
            :class="item.class"
            :width="item.width"
            :height="item.height"
          />
          <span
            v-if="item.heading"
            class="font-sans uppercase text-xs leading-tight block opacity-50"
            >{{ item.heading }}</span
          >
          <span
            v-if="item.subHeading"
            class="font-sans text-xs leading-tight block opacity-50"
            >{{ item.subHeading }}</span
          >
        </div>
      </div>
    </div>
  </section>
</div>
