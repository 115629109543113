<template>
  <tr class="w-full">
    <th
      scope="row"
      class=" font-sans text-xs md:text-base text-left font-normal align-baseline px-1"
      :style="labelStyle"
    >
      <slot name="label" />
    </th>
    <td
      class="w-24 md:w-48 font-sans text-xs md:text-base block text-right font-normal align-baseline px-1"
    >
      <slot name="value" />
    </td>
    <td
      v-if="hasRemoveables"
      class="text-center w-12 md:w-24 align-baseline px-1"
    >
      <button
        v-if="removeable"
        title="Remove this item from your order"
        class="p-2 text-gray hover:text-black focus:text-black outline-none focus:ring block"
        @click="$emit('remove', productId)"
      >
        <icon icon="remove" class="w-3" />
      </button>
    </td>
  </tr>
</template>
<script>
import Icon from "@/js/components/shared/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    productId: {
      type: [Number, null],
      default: null,
    },
    removeable: {
      type: Boolean,
      default: false,
    },
    hasRemoveables: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    labelStyle() {
      return this.hasRemoveables ? { width: "70%" } : { width: "80%" };
    },
  },
};
</script>
