<template>
  <div class="relative z-0" :class="showBasket ? 'pb-38 sm:pb-24 md:pb-0' : ''">
    <app-site-header :with-nav="lineupReservation != null">
      <template #nav>
        <CountdownTimer />
      </template>
    </app-site-header>
    <app-modal v-if="appLoading">
      <app-loader />
    </app-modal>
    <div v-else class="mb-10 md:mb-12">
      <router-view />
    </div>
    <merch-basket v-if="showBasket" />
    <social-proof v-else />
    <reservation-has-expired-modal
      v-if="reservationHasExpired"
      @submit="dismissReservationHasExpired"
    />
    <reservation-extension-modal v-if="showReservationExtensionModal" />
    <clear-basket-confirmation-modal
      v-if="clearBasketConfirmationActive"
      @cancel="closeClearBasketConfirmationModal"
      @clear="clearBasketAndReset"
    />
    <generic-error-modal
      v-if="genericError"
      :title="genericError.title"
      :body="genericError.body"
      @dismiss="clearGenericError"
    />
    <faqs />
    <app-site-footer />
    <!--
      @todo sitewide loading state?
     -->
    <div class="z-50">
      <portal-target name="modal" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ROUTES from "@/js/constants/routeNames.js";
import { CRAFT } from "@/js/services/config.js";

import { guards as applyGuards } from "@/js/router/eventTickets.js";

import AppLoader from "@/js/components/shared/AppLoader.vue";
import AppModal from "@/js/components/shared/AppModal.vue";
import ReservationHasExpiredModal from "@/js/components/shared/ReservationHasExpiredPopover.vue";
import ClearBasketConfirmationModal from "@/js/components/shared/ClearBasketConfirmationModal.vue";
import ReservationExtensionModal from "@/js/components/shared/ReservationExtensionModal.vue";
import GenericErrorModal from "@/js/components/shared/GenericErrorPopover.vue";
import AppSiteFooter from "@/js/components/shared/AppSiteFooter.vue";
import AppSiteHeader from "@/js/components/shared/AppSiteHeader.vue";
import MerchBasket from "@/js/components/shared/MerchBasket.vue";
import CountdownTimer from "@/js/components/shared/CountdownTimer.vue";
import Faqs from "@/js/components/shared/Faqs.vue";
import SocialProof from "@/js/components/shared/SocialProof.vue";

export default {
  components: {
    AppLoader,
    AppModal,
    ReservationHasExpiredModal,
    ClearBasketConfirmationModal,
    ReservationExtensionModal,
    GenericErrorModal,
    AppSiteFooter,
    AppSiteHeader,
    MerchBasket,
    CountdownTimer,
    Faqs,
    SocialProof,
  },
  computed: {
    ...mapState([
      "reservationHasExpired",
      "showReservationExtensionModal",
      "genericError",
      "clearBasketConfirmationActive",
      "selectedProducts",
    ]),
    ...mapState({
      lineupReservation: (state) => state.lineupApi.lineupReservation,
      appLoading: (state) => state.loading,
    }),
    showBasket() {
      return this.selectedProducts && this.$route.name === ROUTES.MERCH;
    },
  },

  async mounted() {
    // @todo move this to a created() hook?
    await this.$store.dispatch("refreshMerchPage", CRAFT.MERCH_PAGE_ID);
    await this.$store.dispatch("refreshGlobals");

    // @todo move this to a created() hook?
    this.enforceInitialRoute();

    // TODO: restore basket and session from local storage, otherwise go to a start page

    this.$store.commit("stopLoading");
  },
  methods: {
    dismissReservationHasExpired() {
      this.$store.commit("setReservationHasExpired", false);
      this.clearBasketAndReset();
    },
    clearGenericError() {
      this.$store.commit("setGenericError", null);
    },
    closeClearBasketConfirmationModal() {
      this.$store.commit("setClearBasketConfirmationActive", false);
    },
    async clearBasketAndReset() {
      await this.$store.dispatch("clearBasketAndReset");
      this.$router.push({ name: ROUTES.QUANTITY });
    },
    enforceInitialRoute() {
      let nextRouteName = this.$route.name;

      applyGuards(this.$route, null, (next) => {
        if (next) {
          nextRouteName = next.name;
        } else {
          nextRouteName = ROUTES.MERCH;
        }
      });

      if (nextRouteName !== this.$route.name) {
        this.$router.push({ name: nextRouteName });
      }
    },
  },
};
</script>
