
<div
  v-if="reservationExpiresAt"
  class="flex flex-wrap items-center bg-transparent flex-1 py-1 justify-center"
>
  <span class="block font-display fonts-semibold leading-none pr-2 text-white"
    >Your order is being held for</span
  >

  <div class="countdown-timer">
    <span class="text-white font-display font-semibold leading-none">{{
      countdownString
    }}</span>
  </div>
</div>
