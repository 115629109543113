
<component :is="tagName" :class="wrapperClass" @click="$emit('click')">
  <span class="pill__inner">
    <span class="pill__text">
      <slot />
    </span>
    <span class="pill__icon">
      <slot name="icon" />
    </span>
  </span>
</component>
