<template>
  <div class="mx-auto mb-8 md:mb-12">
    <div class="flex flex-wrap justify-center mb-5 md:mb-8 -mx-2 md:-mx-4">
      <!--       <button
        :class="{'is--selected': isDefault }"
        class="quantity text-sm w-1/4 lg:w-1/6 px-8 py-16 m-6"
        type="button" 
        @click="clear()"
      >
          Gift voucher not required
      </button> -->
      <div
        v-for="preset in group.presets"
        :key="preset.quantity"
        class="w-1/3 md:w-1/6 p-2"
      >
        <button
          :class="{ 'is--selected': preset === selectedPreset }"
          class="block quantity w-full"
          title="Select a custom gift voucher amount"
          type="button"
          @click="setUsePreset(preset)"
        >
          {{ preset.label }}
        </button>
      </div>
      <!--
      TD 04/12/20 Custom amounts disabled for now
      <button 
        :class="{'is--selected': useCustom }"
        class="quantity text-base w-1/4 lg:w-1/6 p-16 m-6"
        title="Select a custom gift voucher amount"
        type="button" 
        @click="setUseCustom"
      >
        Choose amount
      </button> -->
    </div>
    <!--
      TD 04/12/20 Custom amounts disabled for now
    <div v-if="useCustom">
      <product-quantity
        :current-quantity="currentQuantity"
        label="Custom Gift Voucher amount"
        id="custom-gift-voucher"
        @decrement="decrementQuantity()"
        @increment="incrementQuantity()" >
        <input
          :value="customValue"
          type="text"
          name="custom-gift-voucher"
          tabindex="-1"
          readonly="true"
          class="outline-none appearance-none focus:outline-none focus:none text-center w-full flex items-center border border-black border-opacity-25 border-r-0 border-l-0"
        />
      </product-quantity>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatUnitlessPrice } from "@/js/helpers/NumberHelper.js";

// import ProductQuantity from "@/js/components/products/ProductQuantity.vue";

export default {
  name: "MerchGiftVoucherGroup",
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  // components: {
  //   ProductQuantity,
  // },
  data() {
    return {
      selectedPreset: null,
      useCustom: false,
    };
  },
  computed: {
    ...mapState(["selectedProducts"]),
    productId() {
      return this.group.baseProductId;
    },
    isDefault() {
      return !this.selectedPreset && !this.useCustom;
    },
    customValue() {
      return `£${formatUnitlessPrice(
        this.currentQuantity * this.group.baseUnitValue
      )}`;
    },
    currentQuantity() {
      const prod = this.selectedProducts.find(
        (x) => x.lineupId === this.productId
      );

      return prod ? prod.quantity : 0;
    },
  },
  methods: {
    clear() {
      this.selectedPreset = null;
      this.useCustom = false;
      this.$store.commit("removeSelectedProduct", this.productId);
      this.$store.commit("unsetGiftVoucherProduct");
    },
    setUsePreset(preset) {
      this.selectedPreset = preset;
      this.useCustom = false;
      this.$store.commit("removeSelectedProduct", this.productId);
      this.$store.commit("incrementSelectedProduct", {
        productId: this.productId,
        quantity: preset.quantity,
      });
      this.$store.commit("setGiftVoucherProduct", this.productId);
    },
    setUseCustom() {
      this.selectedPreset = null;
      this.useCustom = true;
    },

    incrementQuantity() {
      this.$store.commit("incrementSelectedProduct", this.productId);
    },
    decrementQuantity() {
      this.$store.commit("decrementSelectedProduct", this.productId);
    },
  },
};
</script>
