
<component
  :is="tagName"
  :class="wrapperClass"
  v-bind="attrs"
  @click="$emit('click')"
>
  <span class="flex flex-wrap items-center justify-center">
    <span :class="{ 'block w-auto mr-2': hasIcon }">
      <slot name="icon" />
    </span>
    <span class="w-auto">
      <slot />
    </span>
  </span>
</component>
