<template>
  <app-modal max-w="xl">
    <h2 class="text-center" v-html="title" />
    <p class="text-center" v-html="body" />
    <div class="bg-gray-light p-3 flex flex-wrap justify-center">
      <app-button
        class="btn--gradient block w-full sm:w-auto sm:inline-block"
        @click="submit()"
        >OK</app-button
      >
    </div>
  </app-modal>
</template>
<script>
import AppButton from "@/js/components/shared/AppButton.vue";
import AppModal from "@/js/components/shared/AppModal.vue";

export default {
  components: {
    AppButton,
    AppModal,
  },
  props: {
    title: {
      type: String,
      default: "An error has occured",
    },
    body: {
      type: String,
      default: "An unknown error has occured",
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    submit() {
      this.$emit("dismiss");
    },
  },
};
</script>
