/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import moment from "moment";

import VueRouter from "@/js/router/baseRouter.js";

import ROUTES from "@/js/constants/routeNames.js";

import Store from "@/js/store/store.js";

import EventGate from "@/js/pages/EventGate.vue";
import EventDisabled from "@/js/pages/EventDisabled.vue";
import Quantity from "@/js/pages/Quantity.vue";
import Tickets from "@/js/pages/Tickets.vue";
import Time from "@/js/pages/Time.vue";
import EventProducts from "@/js/pages/EventProducts.vue";
import Checkout from "@/js/pages/Checkout.vue";
import Confirmation from "@/js/pages/Confirmation.vue";

import GoogleAnalytics from "@/js/services/googleAnalytics.js";
import Raygun from "@/js/services/raygun.js";

const routes = [
  { name: ROUTES.GATE, path: "/tickets/", component: EventGate },
  {
    name: ROUTES.DISABLED,
    path: "/tickets/disabled",
    component: EventDisabled,
  },
  { name: ROUTES.QUANTITY, path: "/tickets/quantity", component: Quantity },
  { name: ROUTES.TICKETS, path: "/tickets/date", component: Tickets },
  { name: ROUTES.TIME, path: "/tickets/time", component: Time },
  {
    name: ROUTES.PRODUCTS,
    path: "/tickets/products",
    component: EventProducts,
  },
  { name: ROUTES.CHECKOUT, path: "/tickets/checkout", component: Checkout },
  {
    name: ROUTES.CONFIRMATION,
    path: "/tickets/confirmation",
    component: Confirmation,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    let result;

    if (savedPosition) {
      result = savedPosition;
    } else {
      result = { x: 0, y: 0 };
    }

    return result;
  },
});

export function guards(to, from, next) {
  let shouldContinue;

  const { event } = Store.state.craftApi;
  const route = { to, next };

  // Just allow anything while we're loading
  if (event === null) {
    next();

    return;
  }

  const presaleOver =
    event && (!event.gated || moment(event.presaleEndDate).isBefore(moment()));
  const hasSuppliedAccessCode = Store.state.suppliedAccessCode !== null;

  const env = {
    event,
    presaleOver,
    hasSuppliedAccessCode,
    state: Store.state,
    getters: Store.getters,
    commit: Store.commit,

    ticketingIsDisabled: event && !event.ticketingJourneyEnabled,
    shouldRedirectToGate: !presaleOver && !hasSuppliedAccessCode,
  };

  // Send everything to gate if it is activated and has not passed
  const requireHasPassedGate = ({ next }, { shouldRedirectToGate }) => {
    if (shouldRedirectToGate) {
      next({ name: "gate", query: to.query });

      return false;
    }

    return true;
  };

  // Send everything to disabled if it is activated
  const requireIsNotDisabled = ({ next }, { ticketingIsDisabled }) => {
    if (ticketingIsDisabled) {
      next({ name: "disabled", query: to.query });

      return false;
    }

    return true;
  };

  // Prevent going to disabled  if it isn't activated
  const requireIsNotEnabled = ({ next }, { ticketingIsDisabled }) => {
    if (!ticketingIsDisabled && to.name === "disabled") {
      next({ name: "gate", query: to.query });

      return false;
    }

    return true;
  };

  // Prevent going back to the gate once access has been set or if it isn't activated
  const preventAccessGateAfterPass = (
    { next },
    { presaleOver, hasSuppliedAccessCode }
  ) => {
    if (to.name === "gate" && (presaleOver || hasSuppliedAccessCode)) {
      next(false);

      return false;
    }

    return true;
  };

  const requireState = ({ next }, obj, property) => {
    if (!obj[property]) {
      next(false);

      return false;
    }

    return true;
  };

  const requireBasketConfirmation = ({ next }, { commit, state }) => {
    if (state.lineupApi.lineupReservation) {
      commit("setClearBasketConfirmationActive", true);
      next(false);

      return false;
    }

    return true;
  };

  switch (to.name) {
    case "disabled":
      shouldContinue = requireIsNotEnabled(route, env);
      break;
    case "gate":
      shouldContinue =
        requireIsNotDisabled(route, env) &&
        preventAccessGateAfterPass(route, env) &&
        requireBasketConfirmation(route, env);
      break;
    case "quantity":
      shouldContinue =
        requireIsNotDisabled(route, env) && requireHasPassedGate(route, env);
      break;
    case "tickets":
      shouldContinue =
        requireIsNotDisabled(route, env) &&
        requireHasPassedGate(route, env) &&
        requireState(route, env.state, "selectedQuantity") &&
        requireBasketConfirmation(route, env);
      break;
    case "time":
      shouldContinue =
        requireIsNotDisabled(route, env) &&
        requireHasPassedGate(route, env) &&
        requireState(route, env.state, "selectedQuantity") &&
        requireState(route, env.state, "selectedTicketGroup") &&
        requireBasketConfirmation(route, env);
      break;
    case "products":
      shouldContinue =
        requireIsNotDisabled(route, env) &&
        requireHasPassedGate(route, env) &&
        requireState(route, env.state, "selectedQuantity") &&
        requireState(route, env.state, "selectedTicketGroup") &&
        requireState(route, env.state, "selectedPerformance") &&
        requireState(route, env.state, "selectedTickets");
      break;
    case "checkout":
      shouldContinue =
        requireIsNotDisabled(route, env) &&
        requireHasPassedGate(route, env) &&
        requireState(route, env.state, "selectedQuantity") &&
        requireState(route, env.state, "selectedTicketGroup") &&
        requireState(route, env.state, "selectedPerformance") &&
        requireState(route, env.state, "selectedTickets") &&
        requireState(route, env.state.lineupApi, "lineupReservation");
      break;
    case "confirmation":
      shouldContinue =
        requireIsNotDisabled(route, env) &&
        requireHasPassedGate(route, env) &&
        requireState(route, env.getters, "completedOrder");
      break;
    default:
      shouldContinue = true;
      break;
  }

  if (!shouldContinue) {
    GoogleAnalytics.sendEvent(
      "redirect",
      "Navigation",
      "RedirectFromNavigationGuard",
      `/${to.name}`
    );

    return false;
  }

  return next();
}

router.beforeEach(guards);

router.afterEach((to) => {
  const q = new URLSearchParams(to.query).toString();
  const path = `${to.path}${q ? `?${q}` : ""}`;

  GoogleAnalytics.setPage(path);
  Raygun.trackPageView(path);
  GoogleAnalytics.sendPageView();
});

export default router;
