
<section class="container-md">
  <div
    class="bg-gray-light border-t border-gray md:border-0 p-3 sm:flex sm:flex-wrap items-center md:mb-12 z-50 fixed md:relative bottom-0 left-0 right-0 z-10"
  >
    <div class="w-full sm:w-1/2 mb-3 sm:mb-0">
      <div class="mb-1">
        <span class="font-display block leading-none mb-0"
          >Total:
          <span
            class="text-2xl font-semibold"
            v-html="formatPriceShort(eventTotalPrice.toDisplay)"
        /></span>
        <span v-if="eventTotalPrice.bookingFee"
              class="font-sans inline-block text-xs leading-tight"
              v-html="`Includes a booking fee of ${formatPriceShort(eventTotalPrice.bookingFee)}`"
        />
        <span v-else
              class="font-sans inline-block text-xs leading-tight"
        >No booking fees</span>
        <span style="display: none">TicketBasket.js</span>
        <span class="font-sans inline-block text-xs leading-tight ml-8"
          >{{ selectedQuantity }} item(s)</span
        >
        <span
          v-if="companionTicket"
          class="font-sans inline-block text-xs leading-tight ml-8"
          >inc. 1 carer ticket</span
        >
      </div>

      <div class="flex flex-wrap mb-2 opacity-50">
        <icon icon="pay-visa" class="w-8 h-8 mr-3" width="30" />
        <icon icon="pay-mastercard" class="w-8 h-8 mr-3" width="30" />
        <icon
          icon="pay-apple"
          class="w-10 h-10 mr-3 relative -mt-1"
          width="30"
        />
        <icon
          icon="pay-google"
          class="w-10 h-10 mr-3 relative -mt-1"
          width="30"
        />
      </div>
    </div>
    <div class="w-full sm:w-1/2">
      <app-button :variants="['gradient', 'block']" @click="buyNow">
        {{ buttonText }}
        <template #icon>
          <icon icon="lock" class="w-3 md:w-4 mr-1" width="35" height="35" />
        </template>
      </app-button>
    </div>
  </div>
</section>
