import { formatTimeString, timeInMinutes } from "@/js/helpers/TimeHelper";

const getFirstPerformanceByTime = (performances) =>
  performances.reduce((earliest, aPerformance) => {
    if (timeInMinutes(aPerformance.time) < timeInMinutes(earliest.time)) {
      return aPerformance;
    }

    return earliest;
  }, performances[0]);

const getLastPerformanceByTime = (performances) =>
  performances.reduce((latest, aPerformance) => {
    if (timeInMinutes(aPerformance.time) > timeInMinutes(latest.time)) {
      return aPerformance;
    }

    return latest;
  }, performances[0]);

const getSlotPriceRange = (performances) =>
  performances.reduce(
    (carry, aPerformance) => {
      const defaultTicket = aPerformance.tickets.find(
        (aTicket) => aTicket.id === aPerformance.defaultTicketId
      );

      return {
        minPrice: Math.min(carry.minPrice, defaultTicket.price),
        maxPrice: Math.max(carry.maxPrice, defaultTicket.price),
      };
    },
    {
      minPrice: Infinity,
      maxPrice: 0,
    }
  );

const timeFilteredPerformances = (
  allPerformances,
  { slotStart, slotEnd },
  availabilityCallback
) =>
  allPerformances.reduce((availablePerformances, aPerformance) => {
    const startTimeInMinutes = timeInMinutes(aPerformance.time);

    if (
      // only count performances in the slot
      startTimeInMinutes >= slotStart &&
      startTimeInMinutes <= slotEnd &&
      availabilityCallback(aPerformance)
    ) {
      availablePerformances.push(aPerformance);
    }

    return availablePerformances;
  }, []);

const performancesWithAvailabilityFor = (
  performances,
  options,
  minAvailabilityForSoldOut
) =>
  timeFilteredPerformances(
    performances,
    options,
    ({ available }) => available > minAvailabilityForSoldOut
  );

const performancesWithSelectedQuantityFor = (
  performances,
  options,
  selectedQuantity
) =>
  timeFilteredPerformances(
    performances,
    options,
    ({ available }) => available >= selectedQuantity
  );

const availableSlotsFor = (
  aTicketGroup,
  { ticketTimeGroups, minAvailabilityForSoldOut, selectedQuantity }
) => {
  const dateParsed = new Date(aTicketGroup.date);

  return ticketTimeGroups
    .filter((slot) => slot.daysAvailable.includes(dateParsed.getDay()))
    .map((slot) => {
      const slotStart = timeInMinutes(slot.start);
      const slotEnd = timeInMinutes(slot.end);

      const performancesWithAvailability = performancesWithAvailabilityFor(
        aTicketGroup.performances,
        { slotStart, slotEnd },
        minAvailabilityForSoldOut
      );

      const performancesWithSelectedQuantity =
        performancesWithSelectedQuantityFor(
          aTicketGroup.performances,
          { slotStart, slotEnd },
          selectedQuantity
        );

      return {
        name: slot.name,
        ...getSlotPriceRange(performancesWithSelectedQuantity),
        from: formatTimeString(
          getFirstPerformanceByTime(performancesWithSelectedQuantity)?.time ??
            slot.start
        ),
        to: formatTimeString(
          getLastPerformanceByTime(performancesWithSelectedQuantity)?.time ??
            slot.end
        ),
        availablePerformances: performancesWithSelectedQuantity,
        availablePerformanceCount: performancesWithSelectedQuantity.length,
        totalAvailableTicketCount: performancesWithAvailability.length,
      };
    });
};

export const expandTimeSlots = (
  aTicketGroup,
  { ticketTimeGroups, minAvailabilityForSoldOut, selectedQuantity }
) => ({
  ...aTicketGroup,
  availableSlots: availableSlotsFor(aTicketGroup, {
    ticketTimeGroups,
    minAvailabilityForSoldOut,
    selectedQuantity,
  }),
});

export default {
  expandTimeSlots,
};
