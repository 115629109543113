
<div class="w-full md:w-2/3">
  <div class="flex flex-wrap">
    <div class="flex-1">
      <span
        v-if="performanceCount"
        class="text-lg font-sans font-bold md:text-xl block mb-2"
      >
        {{ performanceCount }} date{{ performanceCount !== 1 ? `s` : `` }}
        found for:
      </span>
    </div>
    <div class="w-auto ml-auto md:hidden">
      <app-button :variants="['small']" @click="$emit('toggleshowfilters')">
        Filter
        <template #icon>
          <icon icon="plus" class="w-2 mr-1" width="20" />
        </template>
      </app-button>
    </div>
  </div>
  <div class="mb-4">
    <app-pill class="mr-1" @click="$emit('resetquantity')">
      {{ decorateFilterValue({ facetId: "qnty", value: selectedQuantity }) }}
      <template #icon>
        <icon icon="cross" class="w-2" />
      </template>
    </app-pill>
    <app-pill
      v-for="filter in appliedFilters"
      :key="filter.value + '-' + filter.facetId"
      class="mr-1"
      @click="removeFilter(filter)"
    >
      <span v-html="decorateFilterValue(filter)" />
      <template #icon>
        <icon icon="cross" class="w-2" />
      </template>
    </app-pill>
  </div>
</div>
