
<a
  href="#"
  :title="title"
  :tabindex="isSoldOut || capacityLessThanSelectedQuantity ? `-1` : ``"
  :disabled="
    isSoldOut ||
      capacityLessThanSelectedQuantity ||
      performance.cachedAvailability
  "
  class="
      w-1/3
      sm:w-28
      md:w-1/6
      block
      px-1"
  @click.prevent="selectPerformance()"
>
  <div
    :class="[wrapperClass]"
    class="performance-item text-center
      font-sans
      h-full
      py-2
      px-4
      border
      transition duration-200 ease-in-out
      focus:outline-none focus:ring
      group"
  >
    <span class="block font-bold font-display">{{
      formatTime(performance.time, { inFormat: "HH:mm:ss" })
    }}</span>
    <span v-if="performance.cachedAvailability" class="block text-xs">
      <app-loader
        wrapper-class="text-gray text-xs"
        :show-message="false"
      ></app-loader>
    </span>
    <template v-else>
      <span
        class="block text-xs"
        :class="{
          'text-u-alert-red': showRemainingTicketCount && !isSoldOut,
        }"
        >{{ availabilityMessage }}</span
      >
      <span
        v-if="!isSoldOut"
        class="block textlg"
        v-html="formatPriceShort(displayPrice)"
      />
    </template>
  </div>
</a>
