<template>
  <component :is="tagName" :class="wrapperClass" @click="$emit('click')">
    <span class="pill__inner">
      <span class="pill__text">
        <slot />
      </span>
      <span class="pill__icon">
        <slot name="icon" />
      </span>
    </span>
  </component>
</template>
<script>
export default {
  props: {
    hasIcon: {
      type: Boolean,
      default: false,
    },
    tagName: {
      type: String,
      default: "button",
      validator: (propValue) => ["a", "button", "input"].includes(propValue),
    },
    variants: {
      type: Array,
      default: () => [],
      validator: (variants) =>
        !variants.length ||
        variants.every((variant) => ["active"].includes(variant)),
    },
  },
  computed: {
    wrapperClass() {
      return ["pill", ...this.variants.map((v) => `pill--${v}`)];
    },
  },
};
</script>
