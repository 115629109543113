
<section>
  <div class="text-black text-sm overflow-hidden pt-2 p-3">
    <h2>Tell us about your access&nbsp;needs</h2>
    <div class="mb-3">
      <label for="access-details" class="sr-only"
        >Please tell us what we can do to make your visit as easy as
        possible.</label
      >
      <textarea
        v-model="localAccessNeedsNotes"
        class="input"
        name="access-details"
        rows="3"
        placeholder="Please tell us what we can do to make your visit as easy as possible."
      />
    </div>
    <div
      class="pretty mb-5 md:mb-8"
      v-html="pageContent.accessNeedsMainDetails"
    />
    <app-callout v-if="hasError" class="mb-4" type="warning">
      <label for="access-details"
        >Please either complete the field with details of your access needs,
        or cancel your request by tapping "back".</label
      >
    </app-callout>
    <h2>{{ pageContent.accessNeedsCompanionPrompt }}</h2>
    <app-callout
      v-if="companionTicketSelectionDisabled"
      type="warning"
      class="mb-4 mt-6"
    >
      <div
        class="pretty trim"
        v-html="pageContent.accessNeedsCompanionSelectionDisabledMessage"
      />
    </app-callout>
    <div
      class="mb-8"
      :class="{ 'opacity-50': companionTicketSelectionDisabled }"
    >
      <div
        class="trim pretty mb-2"
        v-html="pageContent.accessNeedsCompanionCallout"
      />
      <div class="mb-2">
        <input
          id="yes-companion-access"
          v-model="localCompanionTicket"
          :disabled="companionTicketSelectionDisabled"
          :value="true"
          type="radio"
          name="access-needs-companion"
          class="focus:ring focus:outline-none"
        />
        <label for="yes-companion-access" class="text-base ml-2 font-sans">{{
          pageContent.accessNeedsCompanionYes
        }}</label>
      </div>
      <div class="mb-2">
        <input
          id="no-companion-access"
          v-model="localCompanionTicket"
          :disabled="companionTicketSelectionDisabled"
          :value="false"
          type="radio"
          name="access-needs-companion"
          class="focus:ring focus:outline-none"
        />
        <label for="no-companion-access" class="text-base ml-2 font-sans">{{
          pageContent.accessNeedsCompanionNo
        }}</label>
      </div>
    </div>
    <span
      class="text-sm pretty leading-tight font-sans block text-gray mb-5"
      v-html="pageContent.accessNeedsRedemptionInstructions"
    ></span>
  </div>
  <div class="sm:bg-gray-light sm:p-3 flex flex-wrap">
    <app-button
      class="btn--white block w-full sm:w-auto sm:inline-block mb-3 sm:mb-0"
      @click="cancel()"
      >Back</app-button
    >
    <app-button
      class="btn--gradient block w-full sm:w-auto sm:inline-block sm:ml-auto"
      @click="submit()"
      >Submit</app-button
    >
  </div>
</section>
