
<button
  :title="title"
  :disabled="isDisabled"
  type="button"
  class="quantity
      w-20 sm:w-24 md:w-28
      m-2 md:m-4
      md:text-2xl"
  @click="selectQuantity"
>
  {{ value }}
</button>
