<template>
  <component
    :is="wrapperElement"
    target="_blank"
    :href="url"
    :class="wrapperClass"
  >
    <div class="flex items-center">
      <div class="w-auto">
        <star-rating
          :rating="stars"
          :read-only="true"
          :increment="0.5"
          :star-size="16"
          :border-width="1"
          :show-rating="false"
          :active-color="starColor"
          :border-active-color="starColor"
          :border-color="starColor"
          inactive-color="rgba(255,255,255,0)"
          class="mr-1 inline-block"
        ></star-rating>
      </div>
      <span class="block font-sans text-xs underline mt-1 inline"
        >{{ count }} Reviews</span
      >
    </div>
  </component>
</template>
<script>
import range from "lodash.range";
import StarRating from "vue-star-rating";

import Icon from "@/js/components/shared/Icon.vue";

export default {
  components: {
    Icon,
    StarRating,
  },
  props: {
    url: {
      type: [String, null],
      default: null,
    },
    stars: {
      type: Number,
      required: true,
    },
    starColor: {
      type: String,
      default: "#FBAE17",
    },
    count: {
      type: [Number, null],
      default: null,
    },
    wrapperClass: {
      type: String,
      default:
        "mr-2 inline-block text-lr-brand-primary md:text-white hover:text-u-highlight focus:text-u-highlight focus:outline-none focus:ring",
    },
  },
  computed: {
    wrapperElement() {
      return this.url ? "a" : "div";
    },
    starCount() {
      return range(0, 5);
    },
  },
};
</script>
