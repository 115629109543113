<template>
  <div class="relative z-0" :class="showBasket ? 'pb-38 sm:pb-24 md:pb-0' : ''">
    <app-site-header v-if="showHero" :with-nav="lineupReservation != null">
      <template #nav>
        <CountdownTimer />
      </template>
    </app-site-header>
    <app-hero
      v-if="showHero && craftEvent"
      :event="craftEvent"
      :banner="craftEvent.unbrandedBanner"
    />
    <hr />
    <app-modal v-if="appLoading">
      <app-loader />
    </app-modal>
    <main v-else id="main" class="mb-10 md:mb-12">
      <router-view />
    </main>
    <ticket-basket v-if="showBasket" />
    <social-proof v-else />
    <price-changed-modal
      v-if="ticketPriceChanged"
      @submit="dismissTicketPriceChanged"
    />
    <reservation-has-expired-modal
      v-if="reservationHasExpired"
      @submit="dismissReservationHasExpired"
    />
    <reservation-extension-modal v-if="showReservationExtensionModal" />
    <clear-basket-confirmation-modal
      v-if="clearBasketConfirmationActive"
      @cancel="closeClearBasketConfirmationModal"
      @clear="clearBasketAndReset"
    />
    <generic-error-modal
      v-if="genericError"
      :title="genericError.title"
      :body="genericError.body"
      @dismiss="clearGenericError"
    />
    <faqs />
    <app-site-footer />
    <!--
      @todo sitewide loading state?
     -->
    <div class="z-50">
      <portal-target name="modal" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { guards as applyGuards } from "@/js/router/eventTickets.js";

import { getParam } from "@/js/services/urlParam.js";
import ROUTES from "@/js/constants/routeNames.js";
import { CRAFT } from "@/js/services/config.js";

import AppHero from "@/js/components/shared/AppHero.vue";
import AppLoader from "@/js/components/shared/AppLoader.vue";
import AppModal from "@/js/components/shared/AppModal.vue";
import PriceChangedModal from "@/js/components/shared/PriceHasChangedPopover.vue";
import ReservationHasExpiredModal from "@/js/components/shared/ReservationHasExpiredPopover.vue";
import ClearBasketConfirmationModal from "@/js/components/shared/ClearBasketConfirmationModal.vue";
import ReservationExtensionModal from "@/js/components/shared/ReservationExtensionModal.vue";
import GenericErrorModal from "@/js/components/shared/GenericErrorPopover.vue";
import AppSiteFooter from "@/js/components/shared/AppSiteFooter.vue";
import AppSiteHeader from "@/js/components/shared/AppSiteHeader.vue";
import TicketBasket from "@/js/components/shared/TicketBasket.vue";
import CountdownTimer from "@/js/components/shared/CountdownTimer.vue";
import Faqs from "@/js/components/shared/Faqs.vue";
import SocialProof from "@/js/components/shared/SocialProof.vue";

export default {
  components: {
    AppHero,
    AppLoader,
    AppModal,
    PriceChangedModal,
    ReservationHasExpiredModal,
    ClearBasketConfirmationModal,
    ReservationExtensionModal,
    GenericErrorModal,
    AppSiteFooter,
    AppSiteHeader,
    TicketBasket,
    CountdownTimer,
    Faqs,
    SocialProof,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      craftEvent: (state) => state.craftApi.event,
      appLoading: (state) => state.loading,
      eventGated: (state) =>
        (state.craftApi?.event?.gated ?? "false") === "true",
      unlocked: (state) => state.suppliedDiscountCode !== null,
      selectedQuantity: (state) => state.selectedQuantity,
      selectedPerformance: (state) => state.selectedPerformance,
      lineupReservation: (state) => state.lineupApi.lineupReservation,
      ticketPriceChanged: (state) => state.ticketPriceChanged,
      reservationHasExpired: (state) => state.reservationHasExpired,
      showReservationExtensionModal: (state) =>
        state.showReservationExtensionModal,
      genericError: (state) => state.genericError,
      clearBasketConfirmationActive: (state) =>
        state.clearBasketConfirmationActive,
    }),
    ...mapState([
      "suppliedAccessCode",
      "discountCodeUrlParamName",
      "giftVoucherUrlParamName",
    ]),
    showHero() {
      let shouldShow;
      switch (this.$route.name) {
        case ROUTES.DISABLED:
        case ROUTES.GATE:
          shouldShow = false;
          break;
        default:
          shouldShow = true;
      }

      return shouldShow;
    },
    showBasket() {
      return (
        this.$route.name !== ROUTES.CHECKOUT &&
        this.$route.name !== ROUTES.CONFIRMATION &&
        this.ticketQuantity !== null &&
        this.selectedPerformance !== null
      );
    },
  },

  async mounted() {
    // @todo move this to a created() hook?
    await this.$store.dispatch("refreshCraftEvent", CRAFT.EVENT_ID);
    await this.$store.dispatch("refreshGlobals");

    // Get dc / gv from the URL if supplied
    const dc = getParam(this.discountCodeUrlParamName);
    const gv = getParam(this.giftVoucherUrlParamName);

    if (dc) {
      this.$store.commit("setUrlDiscountCode", dc.toUpperCase());
    }
    if (gv) {
      this.$store.commit("setUrlGiftVoucher", gv.toUpperCase());
    }

    // @todo move this to a created() hook?
    this.enforceInitialRoute();

    // TODO: restore basket and session from local storage, otherwise go to a start page

    this.$store.commit("stopLoading");
  },
  methods: {
    async getCraftEvent() {
      await this.$store.dispatch("refreshCraftEvent", CRAFT.EVENT_ID);
    },
    dismissTicketPriceChanged() {
      this.$store.commit("setTicketPriceChanged", false);
    },
    dismissReservationHasExpired() {
      this.$store.commit("setReservationHasExpired", false);
      this.clearBasketAndReset();
    },
    clearGenericError() {
      this.$store.commit("setGenericError", null);
    },
    closeClearBasketConfirmationModal() {
      this.$store.commit("setClearBasketConfirmationActive", false);
    },
    async clearBasketAndReset() {
      await this.$store.dispatch("clearBasketAndReset");
      this.$router.push({ name: ROUTES.QUANTITY });
    },
    enforceInitialRoute() {
      let nextRouteName = this.$route.name;

      applyGuards(this.$route, null, (next) => {
        if (next) {
          nextRouteName = next.name;
        } else {
          nextRouteName = ROUTES.QUANTITY;
        }
      });

      if (nextRouteName !== this.$route.name) {
        this.$router
          .push({ name: nextRouteName, query: this.$route.query })
          .catch(() => {});
      }
    },
  },
};
</script>
