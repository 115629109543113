import lineupApi from "@/js/services/lineupApi.js";

export default async function updateMerchReservationFromBasket(
  { commit, state, rootState },
  applyPendingDiscount = false
) {
  let reservationDetails = null;
  const products = rootState.selectedProducts
    .map((x) => ({
      productId: x.lineupId,
      quantity: x.quantity,
    }))
    .filter((x) => x.quantity > 0);

  try {
    reservationDetails = await lineupApi.createReservation(
      [],
      products,
      state.lineupSession
    );
  } catch (e) {
    // Reservation failed - maybe because there are no tickets left
    throw new Error("Products could not be added");
  }

  // Apply any pending discount code + GV
  ["suppliedDiscountCode", "suppliedGiftVoucher"].forEach(
    async (discountType) => {
      if (applyPendingDiscount && !!rootState[discountType]) {
        try {
          reservationDetails = await lineupApi.applyDiscountToReservation(
            reservationDetails.reference,
            rootState[discountType]
          );
        } catch (e) {
          // Set discount error?
          if (e.response && e.response.data) {
            commit(
              "setDiscountCodeError",
              e.response.data._metadata.errors[0].messages
            );
          } else {
            commit(
              "setDiscountCodeError",
              "The supplied discount code was invalid"
            );
          }
        }
      }
    }
  );

  if (reservationDetails !== null) {
    commit("setLineupReservation", reservationDetails);
    commit("setLineupSession", reservationDetails.sessionId);
  }
}
