
<div class="container-md mt-6 md:mt-8 mb-10 md:mb-12">
  <checkout-order-summary
    class="mb-6 md:mb-8"
    @change="refreshAll"
    @ticketRemoved="resetReservation"
  />
  <checkout-apply-discount-code
    v-if="showDiscountCodeUi"
    @change="refreshAll"
  />
  <section :class="{ 'mb-3': supportsWallet }">
    <div v-if="supportsWallet && totalPrice.afterDiscount > 0">
      <h5 class="mb-3">Instant checkout:</h5>
    </div>
    <div class="flex flex-wrap justify-center mb-5">
      <div class="w-full sm:w-1/2">
        <div ref="paymentRequestButton" />
      </div>
    </div>
  </section>
  <form @submit="submitCardForm">
    <section class="mb-3">
      <div>
        <h5
          v-if="supportsWallet && totalPrice.afterDiscount > 0"
          class="mb-3"
        >
          Or enter payment details manually:
        </h5>
        <h5 v-else class="mb-3">
          Enter
          {{ totalPrice.afterDiscount > 0 ? "payment" : "your" }} details:
        </h5>
        <div class="flex flex-wrap sm:-mx-3">
          <div class="w-full sm:w-1/2 sm:px-3">
            <form-input
              v-model="customerDetails.firstName"
              label="First Name"
              :input-attrs="{
                placeholder: 'First Name',
                name: 'fname',
                autocomplete: 'given-name',
                required: true,
              }"
              class="mb-3"
            />
          </div>
          <div class="w-full sm:w-1/2 sm:px-3">
            <form-input
              v-model="customerDetails.lastName"
              label="Last Name"
              :input-attrs="{
                placeholder: 'Last Name',
                name: 'lname',
                autocomplete: 'family-name',
                required: true,
              }"
              class="mb-3"
            />
          </div>
          <div class="w-full sm:w-1/2 sm:px-3">
            <form-input
              v-model="customerDetails.email"
              type="email"
              label="Email"
              :input-attrs="{
                placeholder: 'Email',
                name: 'email',
                autocomplete: 'email',
                required: true,
              }"
              class="mb-3"
            />
          </div>
          <div class="w-full sm:w-1/2 sm:px-3">
            <form-input
              v-model="customerDetails.telephone"
              type="tel"
              label="Mobile number"
              :input-attrs="{
                placeholder: 'Mobile number',
                name: 'phone',
                autocomplete: 'tel',
                required: true,
              }"
              class="mb-3"
            />
          </div>
        </div>
        <div v-if="showCardFields" class="mb-3">
          <h5 class="mb-3">Card information:</h5>

          <div class="flex flex-wrap sm:-mx-3">
            <div class="w-full sm:w-1/2 sm:px-3 mb-3">
              <div
                ref="cardNumber"
                :class="[style.formInputClasses, style.formWrapperClasses]"
              />
            </div>
            <div class="w-full sm:w-1/2 sm:px-3">
              <div class="flex flex-wrap sm:-mx-3">
                <div class="w-full sm:w-1/2 sm:px-3 mb-3">
                  <div
                    ref="cardExpiry"
                    :class="[
                      style.formInputClasses,
                      style.formWrapperClasses,
                    ]"
                  />
                </div>
                <div class="w-full sm:w-1/2 sm:px-3">
                  <div
                    ref="cardCvc"
                    :class="[
                      style.formInputClasses,
                      style.formWrapperClasses,
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-callout v-if="cardError" type="warning" class="mb-5 md:mb-6">
          <p>Sorry, there was a problem with the details you entered:</p>
          <p>
            <strong>{{ cardError }}</strong>
          </p>
        </app-callout>
      </div>

      <!-- T's & C's / Buy -->
      <div class="mb-8">
        <span class="font-sans block text-xs leading-tight mb-5"
          >By clicking the Pay Now or Instant checkout button, I confirm that
          I have read and understood the
          <a :href="termsUrl" class="link" target="_blank"
            >Terms &amp; Conditions of Entry</a
          >. You can view our
          <a :href="privacyUrl" class="link" target="_blank"
            >privacy policy here</a
          >.</span
        >

        <div class="flex flex-wrap justify-center mb-5">
          <div class="w-full sm:w-1/2">
            <app-button
              ref="cardButton"
              type="submit"
              :attrs="{
                disabled: !submitEnabled,
              }"
              :variants="['gradient', 'block']"
            >
              {{ totalPrice.afterDiscount > 0 ? "Pay Now" : "Confirm Order" }}
              <template #icon>
                <icon icon="lock" class="w-3 mr-1 pr-px" width="20" />
              </template>
            </app-button>
          </div>
        </div>
        <!-- @todo link to Privacy policy -->
        <!-- <span class="font-sans block text-xs leading-tight">If you don’t want to receive email and discounts for new experiences from us please tick this box - View our <a :href="privacyUrl" class="link">privacy policy here</a>.</span> -->
      </div>
    </section>
  </form>
</div>
