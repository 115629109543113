import axios from "axios";

import { LINEUP } from "@/js/services/config.js";

const basicAuth = {
  username: LINEUP.API_KEY,
  password: "",
};

const lineupApi = {
  async getPerformancesOnDate(eventId, date) {
    const startDate = `${date}T00:00:00`;
    const endDate = `${date}T23:59:59`;
    const result = await axios.get(
      `${LINEUP.BASE_URL}/v1/event/${eventId}/performances/?expand=tickets&resultsPerPage=500&gte=${startDate}&lte=${endDate}`,
      {
        auth: basicAuth,
      }
    );

    return result.data.data;
  },
  async checkDiscountCode(code) {
    try {
      const result = await axios.get(
        `/actions/lineup/discount-code/check?code=${code}`
      );

      return result.data;
    } catch (e) {
      if (e.response && e.response.status >= 500) {
        throw e;
      }

      return false;
    }
  },
  async createReservation(tickets, products, sessionId = null) {
    const data = {
      basket: {
        tickets,
        products,
      },
    };

    if (sessionId !== null) {
      data.sessionId = sessionId;
    }

    const result = await axios.post(`${LINEUP.BASE_URL}/reservation/`, data, {
      auth: basicAuth,
    });

    /** * REMOVE ME - Used for testing ticket price changes when a reservation is returned *** */
    // result.data.data.ticketReservations[0].ticket.price = result.data.data.ticketReservations[0].ticket.price + 2;
    // result.data.data.ticketReservations[0].ticket.total = result.data.data.ticketReservations[0].ticket.total + 2;
    // result.data.data.total = result.data.data.total + 2;
    // result.data.data.price = result.data.data.price + 2;
    /** * REMOVE ME *** */

    return result.data.data;
  },
  async extendReservation(reservationId) {
    const result = await axios.put(
      `${LINEUP.BASE_URL}/reservation/${reservationId}/`,
      {},
      {
        auth: basicAuth,
      }
    );

    return result.data.data;
  },
  async deleteReservation(reservationId) {
    await axios.delete(`${LINEUP.BASE_URL}/reservation/${reservationId}/`, {
      auth: basicAuth,
    });

    return true;
  },
  async createAnonymousUser(customerDetails) {
    const result = await axios.post(
      `${LINEUP.BASE_URL}/v1/customer/`,
      customerDetails,
      {
        auth: basicAuth,
      }
    );

    return result.data.data;
  },
  // eslint-disable-next-line default-param-last
  async convertSessionToOrder(
    reservationId,
    userAuthToken,
    paymentMethodId = null
  ) {
    const requestData = {
      reservationId,
    };

    if (paymentMethodId) {
      requestData.paymentMethod = paymentMethodId;
    }

    const result = await axios.post(
      `${LINEUP.BASE_URL}/v1/order/`,
      requestData,
      {
        headers: { Authorization: `Bearer ${userAuthToken}` },
      }
    );

    return result.data.data;
  },
  async authoriseOrder(id, paymentIntentId, userAuthToken) {
    const result = await axios.post(
      `${LINEUP.BASE_URL}/v1/order/`,
      {
        id,
        paymentIntentId,
      },
      {
        headers: { Authorization: `Bearer ${userAuthToken}` },
      }
    );

    return result.data.data;
  },
  async applyDiscountToReservation(reservationId, discountCode) {
    const data = {
      discountCode,
    };
    const result = await axios.post(
      `${LINEUP.BASE_URL}/reservation/${reservationId}/discount/`,
      data,
      {
        auth: basicAuth,
      }
    );

    return result.data.data;
  },
  async attachFormToReservation(reservationId, formId, content) {
    const data = {
      companion_ticket: content.companionTicket,
      access_notes: content.accessNotes,
    };
    const result = await axios.post(
      `${LINEUP.BASE_URL}/reservation/${reservationId}/form/${formId}/`,
      data,
      {
        auth: basicAuth,
      }
    );

    return result.data.data;
  },
};

// eslint-disable-next-line no-restricted-exports
export { lineupApi as default };
