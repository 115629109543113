
<app-modal max-w="xl">
  <h2 class="text-center">Your ticket reservation is about to expire</h2>
  <p class="text-center">
    Would you like to extend your reservation of these tickets?
  </p>
  <div
    class="bg-gray-light p-3 flex flex-wrap justify-center items-center -mx-6"
  >
    <div class="sm:w-1/2 mb-2 sm:mb-0 sm:pr-2">
      <app-button :variants="['gradient', 'block']" @click="extend()"
        >Yes, continue shopping</app-button
      >
    </div>
    <div class="w-full sm:w-1/2 sm:pl-2">
      <app-button :variants="['block', 'white']" @click="cancel()"
        >No, let my basket expire</app-button
      >
    </div>
  </div>
</app-modal>
