// event ticket routes
export default {
  GATE: "gate",
  DISABLED: "disabled",
  QUANTITY: "quantity",
  TICKETS: "tickets",
  MERCH: "tickets",
  TIME: "time",
  PRODUCTS: "products",
  CHECKOUT: "checkout",
  CONFIRMATION: "confirmation",
};
