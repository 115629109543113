<template>
  <app-accordion
    class="mb-6 md:mb-8"
    :start-expanded="false"
    content-classes=""
  >
    <template #heading>
      <span v-if="hasDiscountApplied">Discount code or voucher applied</span>
      <span v-else>Use a discount code or gift voucher</span>
    </template>
    <app-callout
      v-if="hasChecked && !localDiscountCode"
      type="warning"
      class="mb-4"
    >
      <p>Please enter a discount or gift voucher code</p>
    </app-callout>
    <app-callout v-else-if="discountCodeError" type="warning" class="mb-4">
      <p>{{ discountCodeError }}</p>
    </app-callout>
    <div v-else-if="hasDiscountApplied" mb="4">
      <p>Applied discount / gift vouchers:</p>
      <app-pill
        v-if="suppliedDiscountCode && !companionTicket"
        class="mr-2 text-base mb-4"
        @click="removeDiscountCode"
      >
        <code>{{ suppliedDiscountCode }}</code>
        <template #icon>
          <icon v-if="canRemoveDiscount" icon="cross" class="w-2" />
        </template>
      </app-pill>
      <app-pill
        v-if="suppliedGiftVoucher"
        class="mr-2 mb-4 text-base"
        @click="removeGiftVoucher"
      >
        <code>{{ suppliedGiftVoucher }}</code>
        <template #icon>
          <icon icon="cross" class="w-2" />
        </template>
      </app-pill>

      <!-- <div v-if="allowDiscountRemoval">
        <app-button @click="removeDiscount()">Remove discount</app-button>
      </div> -->
    </div>
    <app-apply-discount-code
      v-if="allowSubmitCodes"
      :value="localDiscountCode"
      :btn-variants="['black']"
      @submit="applyGiftVoucher"
    >
      Apply
    </app-apply-discount-code>
  </app-accordion>
</template>
<script>
import { mapState } from "vuex";
import GoogleAnalytics from "@/js/services/googleAnalytics.js";

import AppAccordion from "@/js/components/shared/AppAccordion.vue";
import AppApplyDiscountCode from "@/js/components/shared/AppApplyDiscountCode.vue";
import AppCallout from "@/js/components/shared/AppCallout.vue";
import AppPill from "@/js/components/shared/AppPill.vue";
import Icon from "@/js/components/shared/Icon.vue";

export default {
  components: {
    AppAccordion,
    AppApplyDiscountCode,
    // AppButton,
    AppCallout,
    AppPill,
    Icon,
  },
  data() {
    return {
      hasChecked: false,
      localDiscountCode: "",
      localGiftVoucher: "",
    };
  },
  computed: {
    ...mapState([
      "companionTicket",
      "discountCodeError",
      "suppliedDiscountCode",
      "suppliedGiftVoucher",
      "urlDiscountCode",
      "urlGiftVoucher",
    ]),
    ...mapState({
      isGated: ({ craftApi }) => !craftApi.event.gated,
    }),
    canRemoveDiscount() {
      let result = true;
      if (
        this.isGated &&
        this.suppliedDiscountCode === this.suppliedAccessCode
      ) {
        result = false;
      } else if (this.companionTicket) {
        result = false;
      }

      return result;
    },
    // allowGiftVoucherRemoval: ({ craftApi }) => !craftApi.event.gated,
    hasDiscountApplied() {
      return !!this.suppliedDiscountCode || !!this.suppliedGiftVoucher;
    },
    allowSubmitCodes() {
      return this.discountCodeError || !this.suppliedGiftVoucher;
    },
  },
  mounted() {},
  methods: {
    async applyGiftVoucher(discountCode) {
      // Set it on the global state and then try to sync the reservation
      // TODO: Show a loader while it's happening

      GoogleAnalytics.sendEvent(
        "click",
        "Tickets",
        "Discount code",
        discountCode
      );

      this.localDiscountCode = discountCode;
      this.hasChecked = true;
      this.$store.commit("setGiftVoucher", discountCode);
      this.$emit("change");
    },
    async removeDiscountCode() {
      // Remove from the global state and then refresh the reservation
      if (this.canRemoveDiscount) {
        // need to also stop tracking URL discount if that's what's being removed,
        // or will be automatically reapplied when checkout re-rendered
        if (this.suppliedDiscountCode === this.urlDiscountCode) {
          this.$store.commit("setUrlDiscountCode", null);
        }
        this.$store.commit("setDiscountCode", null);
        this.localDiscountCode = "";
        this.$emit("change");
      }
    },
    async removeGiftVoucher() {
      // need to also stop tracking URL discount if that's what's being removed,
      // or will be automatically reapplied when checkout re-rendered
      if (this.suppliedGiftVoucher === this.urlGiftVoucher) {
        this.$store.commit("setUrlGiftVoucher", null);
      }
      // Remove from the global state and then refresh the reservation
      this.$store.commit("setGiftVoucher", null);
      this.localDiscountCode = "";
      this.$emit("change");
    },
  },
};
</script>
