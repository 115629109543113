/* eslint-disable */
import Vue from "vue";
import range from "lodash.range";

import craftApi from "@/js/services/craftApi.js";

import {
  filterProductsByRules,
} from "@/js/helpers/ProductHelper.js";

import {
  PRODUCT_TYPE_GIFT_VOUCHERS,
  PRODUCT_TYPE_PRODUCTS,
} from "@/js/constants/productTypes.js";

import filterHelpers from "@/js/helpers/FilterHelper.js";

import {
  expandTimeSlots,
} from "@/js/helpers/TicketGroupHelper.js";

const craftApiStore = {
  state: () => ({
    event: null,
    products: [],
    faqs: [],
    navContent: {},
    merchPageContent: null,
    ticketAvailabilityPatches: {},
  }),
  mutations: {
    setCraftEvent(state, newEvent) {
      if (state.event) {
        console.warn("Overwriting local Craft event!");
      }
      state.event = newEvent;
      state.faqs = newEvent.faqs;
    },
    setProducts(state, products) {
      state.products = products;
    },
    setNavContent(state, navContent) {
      state.navContent = navContent;
    },
    setMerchPageContent(state, merchPageContent) {
      state.merchPageContent = merchPageContent;
      state.faqs = merchPageContent.faqs;
    },
    addTicketGroupsToEvent(state, { ticketGroups }) {
      Vue.set(state.event, "ticketGroups", ticketGroups);
    },
    updateTicketAvailability(state, { ticketGroupKey, updatedPerformance }) {

      if (!state.event) {
        return;
      }

      const ticketGroup = state.event.ticketGroups[ticketGroupKey];

      const existingPerformance = ticketGroup.performances.find((p) => p.id === updatedPerformance.id);

      if (!existingPerformance) {
        return;
      }

      existingPerformance.availability = updatedPerformance.availability;
      existingPerformance.cachedAvailability = false;
      existingPerformance.updatedPerformance = updatedPerformance;

      updatedPerformance.tickets.forEach((ticket) => {
        const existingTicket = existingPerformance.tickets.find((t) => t.id === ticket.id);

        if (existingTicket) {
          existingTicket.price = ticket.faceValueTotal;
          existingTicket.total = ticket.total;
          existingTicket.available = ticket.inventoryLeft;
          existingTicket.cachedAvailability = false;
          existingTicket.faceValueTotal = ticket.faceValueTotal;
          existingTicket.outsideFeeTotal = ticket.outsideFeeTotal
        }
      });

      // @todo test working
      // Track patches to availability, to be applied over cached
      // bulk availability state from Craft
      state.ticketAvailabilityPatches = {
        ...state.ticketAvailabilityPatches,
        [ticketGroupKey]: {
          performances: ticketGroup.performances,
        },
      };
    },

    updateTicketPrice(state, payload) {
      Object.values(state.event.ticketGroups).forEach((group) => {
        const performance = group.performances.find(
          (p) => p.ticketId === payload.ticketId
        );

        if (performance) {
          performance.price = payload.price;
          performance.fees = payload.fees;
        }
      });
    },
  },
  actions: {
    async getTicketGroupsForEvent({ commit }, eventId) {
      const { ticketGroups } = await craftApi.getTicketGroups(eventId);

      commit("addTicketGroupsToEvent", { ticketGroups });
    },
    async refreshCraftEvent({ commit, state, rootState }, eventId) {
      return new Promise(async (resolve, reject) => {
        const event = await craftApi.getEvent(eventId);
        commit("setCraftEvent", event);
        resolve();
      });
    },
    async refreshProducts({ commit, state, rootState }, eventId) {
      return new Promise(async (resolve, reject) => {
        const products = await craftApi.getProducts(eventId);
        commit("setProducts", products);
        resolve();
      });
    },
    async refreshMerchPage({ commit, state, rootState }, merchPageId) {
      return new Promise(async (resolve, reject) => {
        const merchPageContent = await craftApi.getMerchPage(merchPageId);
        commit("setMerchPageContent", merchPageContent);
        resolve();
      });
    },
    async refreshGlobals({ commit, state, rootState }, eventId) {
      return new Promise(async (resolve, reject) => {
        const globals = await craftApi.getGlobals();

        globals.data.forEach(({ handle, data }) => {
          let mutation = null;

          switch (handle) {
            case "navigation":
              mutation = "setNavContent"
              break;
            default:
              break;
          }

          if (mutation) {
            commit(mutation, data);
          }
        });

        resolve();
      });
    },
  },
  getters: {
    pageContent: ({ event, hideSoldOutTicketGroups }) => event.pageContent,

    filteredTicketGroups({ event }, { ticketGroups }, rootState, { timeslotFilter }) {
      const {
        minAvailabilityForSoldOut,
        selectedQuantity,
        hideSoldOutTicketGroups,
      } = rootState;

      let filteredTicketGroups = Object.entries(ticketGroups ?? {})
                                  .reduce((result, [key, aTicketGroup]) => {
                                    result[key]= {
                                      ...expandTimeSlots(
                                          aTicketGroup,
                                          {
                                            ticketTimeGroups: event.ticketTimeGroups,
                                            minAvailabilityForSoldOut,
                                            selectedQuantity
                                          }),
                                    }
                                    return result;
                                  }, {});

      if (rootState.hideSoldOutTicketGroups) {
        filteredTicketGroups = filterHelpers.hasAvailability(
          filteredTicketGroups,
          selectedQuantity
        );
      }
      rootState.appliedFilters.forEach((filter) => {
        filteredTicketGroups = filterHelpers[filter.facetId](
          filteredTicketGroups,
          filter.value,
          {
            event,
            minAvailability: selectedQuantity,
            timeslotFilter,
          }
        );
      });
      return filteredTicketGroups;
    },
    ticketQuantities({ event }) {
      if (!event) {
        return [];
      }

      const {
        maximumTicketQuantityToDisplay,
        maximumGroupSize,
        minimumGroupSize,
        onlyAllowMultiplesOf,
      } = event;

      let quants = [];
      for (let i = 1; i <= maximumTicketQuantityToDisplay; i++) {
        if (i % onlyAllowMultiplesOf == 0) {
          quants.push({
            value: i,
            isDisabled:
              i > maximumGroupSize ||
              i < minimumGroupSize
          })
        }
      }
      return quants;
    },
    availableTicketQuanities(state, { ticketQuantities }) {
      return ticketQuantities
        .filter((qnty) => !qnty.isDisabled)
        .map(({ value }) => value);
    },
    availableMerchProducts(state) {
      const grps = state.merchPageContent?.productGroups ?? [];

      return grps.reduce((allProducts, grp) => {
        switch (grp.type) {
          case PRODUCT_TYPE_GIFT_VOUCHERS:
            return allProducts.concat([{
              type: PRODUCT_TYPE_GIFT_VOUCHERS,
              lineupId: grp.baseProductId,
              price: grp.baseUnitValue,
              title: "Gift Voucher",
            }]);
            break;
          case PRODUCT_TYPE_PRODUCTS:
          default:
            return allProducts.concat(grp.products);
            break;
        }

      }, []);
    },
    availableEventProducts(state, getters, { selectedTicketGroup, selectedPerformance }) {
      let result = [];

      if (selectedTicketGroup && selectedPerformance) {
        result = filterProductsByRules(state.products, {
          time: selectedPerformance.time,
          date: selectedTicketGroup.date,
          performanceTypeDisplayName: selectedTicketGroup.displayName,
        })
      }

      return result;
    },
    allTicketTypes({ event }) {
      let result = [];

      if ( event ) {
        result = event.performanceTypes.reduce((carry, performanceType) => carry.concat(performanceType.ticketTypes), []);
      }
      return result;
    },
    expandTicketType: (state, { allTicketTypes }) => (ticketTypeHandle) => allTicketTypes.find(
          ({ handle }) => handle.toUpperCase() === ticketTypeHandle.toUpperCase()),
    expandPerformanceType: (state) => (performanceTypeHandle) => state.event.performanceTypes.find(
          ({ performanceType }) => performanceType.toUpperCase() === performanceTypeHandle.toUpperCase()),
    // Ticket groups are for all tickets grouped by date and type (title?)
    // We have to do this manually because the front end is arranged to select ticket in the opposite order to their
    // actual storage hierarchy
    ticketGroups(state) {
      if (!state.event) {
        return {};
      }

      const ticketGroups = { ...state.event.ticketGroups };

      // Apply any patches to bulk availability that have been retrieved
      // from LU API direct over the bulk availability that was retrieved
      // from the (cached) Craft API
      Object.entries(state.ticketAvailabilityPatches).forEach(
        ([patchKey, { performances }]) => {
          const ticketGroup = { ...ticketGroups[patchKey] };
          if (ticketGroup && performances) {
            ticketGroup.performances = performances;
            ticketGroups[patchKey] = ticketGroup;
          }
        }
      );

      return ticketGroups;
    },
    performanceTypes(state) {
      if (!state.event) {
        return {};
      }

      return state.event.performanceTypes.reduce((result, performanceType) => {
        return {
          ...result,
          [performanceType.performanceType]: performanceType,
        };
      }, {});
    }
  },
};

export { craftApiStore as default };
