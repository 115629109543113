
<div
  :class="{
    'bg-u-highlight-lighter border border-u-highlight-light mt-6':
      product.highlighted,
  }"
  class="p-3 bg-white font-sans block items-end relative flex"
>
  <product-discount-sticker
    v-if="product.saveBadgeContent"
    :discount="product.saveBadgeContent"
    class="absolute top-0 right-0"
  />

  <div class="w-full sm:flex-1 mb-4 sm:mb-0 sm:pr-4">
    <div class="mb-1">
      <span class="block font-bold">{{ product.title }}</span>
      <p
        v-if="product.description"
        class="text-xs pretty"
        v-html="product.description"
      />
    </div>

    <template v-if="product.discountText">
      <span
        class="inline text-2xl leading-none text-gray line-through"
        v-html="product.discountText"
      />
      <span
        class="inline text-2xl text-u-alert-red font-bold ml-5 leading-none"
        v-html="formatPriceShort(product.price)"
      />
    </template>
    <span
      v-else
      class="inline text-2xl leading-none font-bold text-gray-dark"
      v-html="formatPriceShort(product.price)"
    />
  </div>
  <product-quantity
    :id="toKebabCase(product.title)"
    :current-quantity="currentQuantityForProduct(product.lineupId)"
    :label="product.title"
    :color="product.highlighted ? 'yellow' : null"
    style="width: 105px"
    @decrement="decrementProduct(product.lineupId)"
    @increment="incrementProduct(product.lineupId)"
  />
</div>
