<template>
  <div class="flex flex-wrap w-full">
    <div class="w-full md:flex-1 mb-2 md:mb-0">
      <form-input
        v-model="localValue"
        :input-attrs="{ placeholder: 'Enter a discount code' }"
        label="Enter a discount code"
        @enterkey="submit"
      />
    </div>
    <app-button
      class="w-full md:w-auto block md:flex items-center"
      :variants="btnVariants"
      :disabled="!localValue || !isValidPattern"
      :class="btnClasses"
      @click="submit"
    >
      <slot />
    </app-button>
  </div>
</template>
<script>
import AppButton from "@/js/components/shared/AppButton.vue";
import FormInput from "@/js/components/forms/FormInput.vue";

export default {
  components: {
    AppButton,
    FormInput,
  },
  props: {
    value: {
      type: [String, Number, null],
      required: false,
      default: () => null,
    },
    btnVariants: {
      type: [Array],
      default: () => [],
    },
    btnClasses: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      localValue: "",
      pattern: /^[A-Za-z0-9]+$/,
      minlength: 1,
      maxlength: 128,
      patternError: `That doesn't look like a valid code, please check and try again`,
    };
  },
  computed: {
    isValidPattern() {
      const val = this.localValue?.trim();

      return (
        val &&
        val.length >= this.minlength &&
        val.length <= this.maxlength &&
        val.match(this.pattern)
      );
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    isValidPattern(isValid) {
      if (isValid) {
        this.$emit("changevalidity", { valid: true });
      } else {
        this.$emit("changevalidity", {
          valid: false,
          errorMessage: this.patternError,
        });
      }
    },
    localValue(newValue) {
      // reset error when code deleted
      if (!newValue || newValue.trim().length < this.minlength) {
        this.$emit("changevalidity", { valid: true });
      }
    },
  },
  mounted() {
    this.localValue = this.value?.trim();
  },
  methods: {
    submit() {
      if (this.isValidPattern) {
        this.$emit("submit", this.localValue.trim());
      }
    },
  },
};
</script>
