<script>
import { mapGetters, mapState } from "vuex";

import Checkout from "@/js/pages/Checkout.vue";
import MerchCheckoutOrderSummary from "@/js/components/checkout/MerchCheckoutOrderSummary.vue";

export default {
  components: {
    CheckoutOrderSummary: MerchCheckoutOrderSummary,
  },
  extends: Checkout,
  computed: {
    ...mapGetters({
      totalPrice: "merchTotalPrice",
    }),
    ...mapState(["giftVoucherProductId"]),
    showDiscountCodeUi() {
      return !this.giftVoucherProductId;
    },
  },
  methods: {
    async updateReservationFromBasket(applyPendingDiscount = false) {
      await this.$store.dispatch(
        "updateMerchReservationFromBasket",
        applyPendingDiscount
      );
    },
  },
};
</script>
