<template>
  <div class="w-full md:w-2/3">
    <div class="flex flex-wrap">
      <div class="flex-1">
        <span
          v-if="performanceCount"
          class="text-lg font-sans font-bold md:text-xl block mb-2"
        >
          {{ performanceCount }} date{{ performanceCount !== 1 ? `s` : `` }}
          found for:
        </span>
      </div>
      <div class="w-auto ml-auto md:hidden">
        <app-button :variants="['small']" @click="$emit('toggleshowfilters')">
          Filter
          <template #icon>
            <icon icon="plus" class="w-2 mr-1" width="20" />
          </template>
        </app-button>
      </div>
    </div>
    <div class="mb-4">
      <app-pill class="mr-1" @click="$emit('resetquantity')">
        {{ decorateFilterValue({ facetId: "qnty", value: selectedQuantity }) }}
        <template #icon>
          <icon icon="cross" class="w-2" />
        </template>
      </app-pill>
      <app-pill
        v-for="filter in appliedFilters"
        :key="filter.value + '-' + filter.facetId"
        class="mr-1"
        @click="removeFilter(filter)"
      >
        <span v-html="decorateFilterValue(filter)" />
        <template #icon>
          <icon icon="cross" class="w-2" />
        </template>
      </app-pill>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import AppButton from "@/js/components/shared/AppButton.vue";
import AppPill from "@/js/components/shared/AppPill.vue";
import Icon from "@/js/components/shared/Icon.vue";

export default {
  components: {
    AppButton,
    AppPill,
    Icon,
  },
  props: {
    performanceCount: {
      type: [Number, null],
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState(["selectedQuantity", "appliedFilters"]),
    ...mapGetters(["quantityFacet", "facets"]),
  },
  methods: {
    decorateFilterValue({ facetId, value }) {
      return this.getFacetById(facetId).formatValue(value);
    },
    getFacetById(id) {
      return [this.quantityFacet, ...this.facets].find((f) => f.id === id);
    },
    removeFilter({ facetId }) {
      this.$store.commit("removeFilterById", facetId);
    },
  },
};
</script>
