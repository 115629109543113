<template>
  <div class>
    <app-hero-featured :event="craftEvent" :banner="pageContent.gateBanner" />
    <section class="container-md mt-6 md:mt-8">
      <div class="text-center mb-4 md:mb-6 xl:mb-8">
        <!-- Header -->
        <h1
          class="text-4xl sm:text-5xl md:text-6xl xl:text-7xl uppercase font-display font-semibold leading-tight block"
        >
          {{ pageContent.gateTitle }}
        </h1>
        <span
          class="text-lg sm:text-xl md:text-3xl xl:text-4xl font-display block leading-tight"
          >Book Now - ends at {{ show.presaleEnd.format("h:mma") }} sharp
          {{ show.presaleEnd.format("ddd D MMM") }}</span
        >
      </div>
      <!-- Countdown -->
      <div class="mb-4 md:mb-8">
        <presale-countdown :end-time="show.presaleEnd" />
      </div>

      <div class="pretty text-center">
        <p v-html="promptText"></p>
      </div>

      <div class="flex flex-wrap mb-8 w-10/12 mx-auto">
        <app-apply-discount-code
          :btn-classes="['mt-3', 'md:mt-0']"
          :btn-variants="['gradient']"
          :value="inputCode"
          @submit="checkDiscountCode"
          @changevalidity="handleLocalValidityChange"
        >
          Find Tickets
        </app-apply-discount-code>
      </div>
      <app-loader v-if="loading" />
      <app-callout v-if="errorMessage" type="warning">
        <p>{{ errorMessage }}</p>
      </app-callout>
      <app-callout v-else-if="localErrorMessage && !loading" type="warning">
        <p>{{ localErrorMessage }}</p>
      </app-callout>
    </section>
    <!-- @todo not sure if these details need to be event specific - also might need to be free HTML to include notification form -->
    <section
      class="container-md mt-6 md:mt-8 pretty"
      v-html="pageContent.gateInstructions"
    ></section>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";

import lineup from "@/js/services/lineupApi";
import ROUTES from "@/js/constants/routeNames.js";

import GoogleAnalytics from "@/js/services/googleAnalytics.js";

import AppApplyDiscountCode from "@/js/components/shared/AppApplyDiscountCode.vue";
import AppCallout from "@/js/components/shared/AppCallout.vue";
import AppHeroFeatured from "@/js/components/shared/AppHeroFeatured.vue";
import AppLoader from "@/js/components/shared/AppLoader.vue";

import PresaleCountdown from "@/js/components/presale/PresaleCountdown.vue";

export default {
  name: "EventGate",
  components: {
    AppApplyDiscountCode,
    AppCallout,
    AppLoader,
    AppHeroFeatured,
    PresaleCountdown,
  },
  data() {
    return {
      errorMessage: null,
      localErrorMessage: null,
      loading: false,
      presaleOver: false,
      ticker: () => {},
    };
  },
  computed: {
    ...mapState({
      craftEvent: (state) => state.craftApi.event,
      appLoading: (state) => state.loading,
    }),
    ...mapState([
      "urlDiscountCode",
      "urlGiftVoucher",
      "suppliedDiscountCode",
      "suppliedGiftVoucher",
    ]),
    ...mapGetters(["pageContent"]),
    show() {
      if (!this.craftEvent) {
        return {};
      }
      return {
        title: this.craftEvent.title,
        presaleEnd: moment(this.craftEvent.presaleEndDate),
        heroImage: {
          url: this.craftEvent.largeImage,
        },
        reviews: this.craftEvent.reviews.map((review) => {
          return {
            url: review.link,
            stars: review.stars,
            count: review.totalReviews,
          };
        }),
      };
    },
    promptText() {
      return this.pageContent.gatePrompt.replace(
        "%title%",
        `<strong>${this.show.title}</strong>`
      );
    },
    inputCode() {
      return (
        this.suppliedDiscountCode ||
        this.suppliedGiftVoucher ||
        this.urlDiscountCode ||
        this.urlGiftVoucher ||
        null
      );
    },
  },
  watch: {
    presaleOver(isOver) {
      if (isOver) {
        this.$router.push({ name: ROUTES.QUANTITY });
      }
    },
  },
  async mounted() {
    this.updatePresaleOver();

    if (this.presaleOver) {
      this.$router.push({ name: ROUTES.QUANTITY });
    } else {
      this.ticker = setInterval(this.updatePresaleOver, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.ticker);
  },
  methods: {
    async checkDiscountCode(newCode) {
      this.errorMessage = null;
      this.loading = true;

      GoogleAnalytics.sendEvent("click", "Tickets", "Discount Code", newCode);
      // Send discount code to LU API
      // If valid set state's unlocked to true and store the code somewhere
      try {
        const { valid, message } = await lineup.checkDiscountCode(newCode);

        if (valid) {
          this.$store.commit("setAccessCode", newCode);
          this.$store.commit("setDiscountCodeError", null);

          // update out the url code if a different one was entered at the gate
          if (this.urlGiftVoucher && newCode === this.urlGiftVoucher) {
            this.$store.commit("setUrlGiftVoucher", newCode);
          } else if (
            !this.urlDiscountCode ||
            (this.urlDiscountCode &&
              newCode !== this.urlGiftVoucher &&
              newCode !== this.urlDiscountCode)
          ) {
            this.$store.commit("setUrlDiscountCode", newCode);
          }

          this.$router.push({ name: ROUTES.QUANTITY });
        } else {
          this.errorMessage =
            message ||
            "Sorry, the code you entered is invalid, please check and try again";
        }
      } catch (e) {
        this.errorMessage =
          "There was a problem checking your code. Please try again in a few seconds.";
      }
      this.loading = false;
    },
    handleLocalValidityChange({ valid, errorMessage }) {
      if (valid) {
        this.localErrorMessage = null;
      } else {
        this.localErrorMessage = errorMessage;
      }
    },
    updatePresaleOver() {
      this.presaleOver =
        this.craftEvent &&
        (!this.craftEvent.gated ||
          moment(this.craftEvent.presaleEndDate).isBefore(moment()));
    },
  },
};
</script>
