<template>
  <div>
    <template v-if="availableEventProducts.length">
      <agile-slider
        v-if="slides && slides.length"
        ref="mainSlider"
        :autoplay="slides.length > 1"
        :autoplay-speed="5000"
        :nav-buttons="false"
        :dots="slides.length > 1"
        :speed="750"
        :class="{ 'mb-8 md:mb-12': slides.length < 2 }"
      >
        <slide
          v-for="(slide, idx) in slides"
          :key="idx"
          :image-url="slide.image.url"
          :alt="slide.image.alt"
          :caption="slide.caption"
        />
      </agile-slider>
      <div
        class="container-md"
        :class="{
          'mt-12': !slides || !slides.length,
        }"
      >
        <app-page-header :heading="pageContent.productsHeading">
          <h5 class="text-u-alert-red" v-html="pageContent.productsLeadInText" />
        </app-page-header>
        <div class="w-full mb-5 md:mb-8">
          <div
            v-for="product in availableEventProducts"
            :key="product.lineupId"
            class="mb-3"
          >
            <product :product="product" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mt-8">
        <app-loader />
      </div>
    </template>
    <clear-basket-confirmation-modal
      v-if="showClearBasketConfirmation"
      @cancel="goBackToCheckout"
      @clear="clearBasketAndReset"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueAgile as AgileSlider } from "vue-agile";

import ROUTES from "@/js/constants/routeNames.js";
import { CRAFT } from "@/js/services/config.js";

import ClearBasketConfirmationModal from "@/js/components/shared/ClearBasketConfirmationModal.vue";
import AppLoader from "@/js/components/shared/AppLoader.vue";
import AppPageHeader from "@/js/components/shared/AppPageHeader.vue";
import Slide from "@/js/components/shared/SliderSlide.vue";
import Product from "@/js/components/products/Product.vue";

export default {
  name: "Products",
  components: {
    AppLoader,
    AgileSlider,
    AppPageHeader,
    ClearBasketConfirmationModal,
    Slide,
    Product,
  },
  data() {
    return {
      loading: true,
      prevRoute: null,
      showClearBasketConfirmation: false,
    };
  },
  computed: {
    ...mapGetters(["availableEventProducts", "pageContent"]),
    slides() {
      return this.pageContent.productsSlideshow;
    },
  },
  async beforeMount() {
    await this.$store.dispatch("refreshProducts", CRAFT.EVENT_ID);

    //  bypass the page if no matching products
    if (!this.availableEventProducts.length) {
      switch (this.prevRoute.name) {
        case ROUTES.CHECKOUT:
          this.showClearBasketConfirmation = true;
          break;
        case ROUTES.TIME:
        default:
          this.$router.push({ name: ROUTES.CHECKOUT });
          break;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  methods: {
    async clearBasketAndReset() {
      await this.$store.dispatch("clearBasketAndReset");
      this.$router.push({ name: ROUTES.QUANTITY });
    },
    goBackToCheckout() {
      this.$router.push({ name: ROUTES.CHECKOUT });
    },
  },
};
</script>
