const { rg4js, APP } = window;

export default {
  isEnabled: !!rg4js,
  trackPageView(path) {
    this.trackEvent({
      type: "pageView",
      path,
    });
  },
  trackEvent({ type, ...params }) {
    params.tags = [APP.ENVIRONMENT, ...(params.tags || [])];

    if (this.isEnabled) {
      rg4js("trackEvent", { type, ...params });
    } else {
      console.log("[raygun-disabled] trackEvent", { type, ...params });
    }
  },
  sendError(error, customData = [], tags = []) {
    if (this.isEnabled) {
      rg4js("send", {
        error,
        customData,
        tags: [APP.ENVIRONMENT, ...tags],
      });
    } else if (APP.DEBUG) {
      console.error("[raygun-disabled] trackEvent", error, customData, tags);
    }
  },
  recordBreadcrumb(message, level = "info", metadata = {}, location = null) {
    if (this.isEnabled) {
      rg4js("recordBreadcrumb", {
        message,
        level,
        metadata,
        location,
        tags: [APP.ENVIRONMENT],
      });
    } else if (APP.DEBUG) {
      console.log(
        "[raygun-disabled] trackEvent",
        message,
        level,
        metadata,
        location
      );
    }
  },
};
