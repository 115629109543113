
<app-modal max-w="xl">
  <h2 class="text-center" v-html="title" />
  <p class="text-center" v-html="body" />
  <div class="bg-gray-light p-3 flex flex-wrap justify-center">
    <app-button
      class="btn--gradient block w-full sm:w-auto sm:inline-block"
      @click="submit()"
      >OK</app-button
    >
  </div>
</app-modal>
