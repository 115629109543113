
<component
  :is="wrapperElement"
  target="_blank"
  :href="url"
  :class="wrapperClass"
>
  <div class="flex items-center">
    <div class="w-auto">
      <star-rating
        :rating="stars"
        :read-only="true"
        :increment="0.5"
        :star-size="16"
        :border-width="1"
        :show-rating="false"
        :active-color="starColor"
        :border-active-color="starColor"
        :border-color="starColor"
        inactive-color="rgba(255,255,255,0)"
        class="mr-1 inline-block"
      ></star-rating>
    </div>
    <span class="block font-sans text-xs underline mt-1 inline"
      >{{ count }} Reviews</span
    >
  </div>
</component>
