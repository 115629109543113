
<section class="container-md mt-6 md:mt-8">
  <app-page-header :heading="pageContent.ticketQuantityHeading">
    <app-callout>
      <div class="trim pretty" v-html="pageContent.ticketQuantityInfoBox" />
    </app-callout>
  </app-page-header>
  <div class="sm:w-11/12 mx-auto mb-8 md:mb-12">
    <div class="flex flex-wrap justify-center">
      <ticket-quantity-button
        v-for="quantity in ticketQuantities"
        :key="quantity.value"
        :value="quantity.value"
        :is-disabled="quantity.isDisabled"
      />
    </div>
  </div>
</section>
