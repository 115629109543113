import { timeInMinutes } from "@/js/helpers/TimeHelper.js";

import { formatDotw, formatMonthFull } from "@/js/helpers/DateHelper.js";

export const isInPriceRange = (ticketLike, { min, max }) =>
  ticketLike.maxPrice >= min && ticketLike.minPrice <= max;

export default {
  hasAvailability: (ticketGroups = {}, minAvailability = 0) =>
    Object.entries(ticketGroups).reduce((result, [key, aTicketGroup]) => {
      const matchCount = aTicketGroup.performances.filter(
        ({ available }) => available >= minAvailability
      ).length;

      if (matchCount) {
        result[key] = aTicketGroup;
      }

      return result;
    }, {}),
  dotw: (ticketGroups, dotw) =>
    Object.entries(ticketGroups).reduce((result, [key, aTicketGroup]) => {
      if (formatDotw(aTicketGroup.date) === dotw) {
        result[key] = aTicketGroup;
      }

      return result;
    }, {}),
  month: (ticketGroups, month) =>
    Object.entries(ticketGroups).reduce((result, [key, aTicketGroup]) => {
      if (formatMonthFull(aTicketGroup.date) === month) {
        result[key] = aTicketGroup;
      }

      return result;
    }, {}),
  timeslot: (ticketGroups, value, { event, minAvailability }) => {
    const slot = event.ticketTimeGroups.find((x) => x.name === value);

    return Object.entries(ticketGroups).reduce(
      (result, [key, aTicketGroup]) => {
        const matchCount = aTicketGroup.performances.filter(
          (p) =>
            timeInMinutes(p.time) >= timeInMinutes(slot.start) &&
            timeInMinutes(p.time) < timeInMinutes(slot.end) &&
            // ?? Should we filter by availability of tickets?
            // Or just availability of performance?
            p.available >= minAvailability
        ).length;

        if (matchCount) {
          result[key] = ticketGroups[key];
        }

        return result;
      },
      {}
    );
  },
  performanceType: (ticketGroups, displayName) =>
    Object.entries(ticketGroups).reduce((result, [key, aTicketGroup]) => {
      if (aTicketGroup.displayName === displayName) {
        result[key] = aTicketGroup;
      }

      return result;
    }, {}),
  price: (ticketGroups, priceContraints, { timeslotFilter }) =>
    Object.entries(ticketGroups).reduce((result, [key, aTicketGroup]) => {
      let matchingTimeslots = aTicketGroup.availableSlots.filter((timeslot) =>
        isInPriceRange(timeslot, priceContraints)
      );

      if (timeslotFilter?.value) {
        matchingTimeslots = matchingTimeslots.filter(
          (timeslot) => timeslot.name === timeslotFilter.value
        );
      }

      if (matchingTimeslots.length) {
        result[key] = aTicketGroup;
      }

      return result;
    }, {}),
};
