
<app-accordion
  class="mb-6 md:mb-8"
  :start-expanded="false"
  content-classes=""
>
  <template #heading>
    <span v-if="hasDiscountApplied">Discount code or voucher applied</span>
    <span v-else>Use a discount code or gift voucher</span>
  </template>
  <app-callout
    v-if="hasChecked && !localDiscountCode"
    type="warning"
    class="mb-4"
  >
    <p>Please enter a discount or gift voucher code</p>
  </app-callout>
  <app-callout v-else-if="discountCodeError" type="warning" class="mb-4">
    <p>{{ discountCodeError }}</p>
  </app-callout>
  <div v-else-if="hasDiscountApplied" mb="4">
    <p>Applied discount / gift vouchers:</p>
    <app-pill
      v-if="suppliedDiscountCode && !companionTicket"
      class="mr-2 text-base mb-4"
      @click="removeDiscountCode"
    >
      <code>{{ suppliedDiscountCode }}</code>
      <template #icon>
        <icon v-if="canRemoveDiscount" icon="cross" class="w-2" />
      </template>
    </app-pill>
    <app-pill
      v-if="suppliedGiftVoucher"
      class="mr-2 mb-4 text-base"
      @click="removeGiftVoucher"
    >
      <code>{{ suppliedGiftVoucher }}</code>
      <template #icon>
        <icon icon="cross" class="w-2" />
      </template>
    </app-pill>

    <!-- <div v-if="allowDiscountRemoval">
      <app-button @click="removeDiscount()">Remove discount</app-button>
    </div> -->
  </div>
  <app-apply-discount-code
    v-if="allowSubmitCodes"
    :value="localDiscountCode"
    :btn-variants="['black']"
    @submit="applyGiftVoucher"
  >
    Apply
  </app-apply-discount-code>
</app-accordion>
