// Vendor
import Vue from "vue";
import VueRouter from "vue-router";
import PortalVue from "portal-vue";

// need to explicitly import one locale as
// disabling bundling of all locales
import "moment";
import "moment/locale/en-gb";

// Assets
import "@/assets/styles/tickets.postcss";
import initLrApp from "@/js/initLrApp.js";
import setLoaded from "@/lib/setLoaded.js";

import "@/js/services/stripe.js";
import Raygun from "@/js/services/raygun.js";

// Boostrap
Vue.use(VueRouter);
Vue.use(PortalVue);

const isProduction = process.env.NODE_ENV === "production";

if (isProduction && Raygun.isEnabled) {
  Vue.config.errorHandler = (err, vm, info) => {
    Raygun.sendError(err, [{ vm, info }]);
  };
}

const { APP } = window;

initLrApp(APP);

Vue.config.productionTip = false;

export default function initCheckout({ el, rootComponent, store, router }) {
  // eslint-disable-next-line no-new
  APP.$vm = new Vue({
    el,
    store,
    router,
    render: (h) => h(rootComponent),
  });

  setLoaded(APP);

  window.addEventListener("beforeunload", (e) => {
    // Reservation started but not completed
    if (
      APP.$vm.$store.state.lineupApi.lineupReservation &&
      !APP.$vm.$store.getters.completedOrder
    ) {
      e.preventDefault();
      e.returnValue = true;

      return true;
    }

    delete e.returnValue;

    return false;
  });

  return APP.$vm;
}
