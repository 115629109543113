<template>
  <div class="">
    <!-- The button trigger for the tooltip -->
    <button
      v-if="content"
      :id="buttonId"
      :aria-pressed="isShown"
      :class="{ 'opacity-100': isShown }"
      class="text-lr-brand-primary-dark opacity-25 hover:opacity-100 focus:opacity-100 p-1 focus:outline-none focus:ring group relative z-10"
      type="button"
      :aria-controls="contentId"
      :aria-label="`Toggle More Information about ${ticketGroupType}`"
      @click="toggle"
    >
      <icon icon="tooltip" class="w-4 h-4" />
    </button>
    <div
      v-show="isShown"
      v-if="content"
      :id="contentId"
      :aria-labelledby="buttonId"
      class="
        absolute z-30 top-0 left-0
        z-50
        w-64 sm:w-auto
        p-3
        mt-6
        -ml-32
        sm:-ml-48 sm:-mr-32
        bg-lr-brand-primary-dark
        text-xs font-sans text-white
        pretty trim"
      @click="toggle"
    >
      <button type="button" title="Close tooltip">
        <icon
          icon="cross"
          class="
          w-4 h-4
          absolute top-0 right-0 mr-2 mt-2
          text-white "
        />
      </button>
      <div v-html="content" />
    </div>
  </div>
</template>
<script>
import Icon from "@/js/components/shared/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    ticketGroupType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShown: false,
    };
  },
  computed: {
    buttonId() {
      return `tooltip-button-${this.$root._uid}`;
    },
    contentId() {
      return `tooltip-${this.$root._uid}`;
    },
  },
  methods: {
    toggle() {
      this.isShown = !this.isShown;
    },
  },
};
</script>
