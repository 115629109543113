
<div class="w-full pt-2">
  <div v-if="!isDesktop" class="flex flex-wrap mb-5">
    <app-button :variants="['small']" @click="$emit('toggleshowfilters')">
      Hide filters
      <template #icon>
        <icon icon="minus" class="w-2 mr-1" width="20" />
      </template>
    </app-button>
    <app-button
      :variants="['small', 'white']"
      :disabled="!appliedFilters.length"
      :class="{ 'ml-auto': !isDesktop }"
      @click="clearFilters"
    >
      Clear filters
    </app-button>
  </div>
  <div class="mb-6">
    <app-toggle
      :is-checked="!hideSoldOutTicketGroups"
      label="Show all dates"
      class="block font-sans uppercase leading-none font-semibold text-xs"
      @toggle="toggleSoldOut"
    />
  </div>
  <div
    v-for="facet in facets"
    :key="facet.id"
    class="border-gray border-t border-opacity-25"
  >
    <facet-filter
      :facet="facet"
      :current-values="getCurrentValues(facet)"
      @togglefacetValue="handleFilterChange"
    />
  </div>
  <div class="border-t border-b border-gray border-opacity-25 mb-4">
    <facet-filter
      :facet="quantityFacet"
      :current-values="[selectedQuantity]"
      @togglefacetValue="({ value }) => $emit('setquantity', value)"
    />
  </div>

  <div v-if="!isDesktop">
    <app-button
      v-if="resultCount"
      :variants="['block']"
      class="mb-4"
      @click="$emit('toggleshowfilters')"
      >Show {{ resultCount }} dates</app-button
    >
    <app-button v-else :variants="['block', 'black']" @click="clearFilters"
      >No dates found. Clear filters</app-button
    >
  </div>
  <div class="flex flex-wrap mb-5">
    <app-button
      v-if="isDesktop"
      :variants="['small', 'white']"
      :disabled="!appliedFilters.length"
      :class="{ 'ml-auto': !isDesktop }"
      @click="clearFilters"
    >
      Clear filters
    </app-button>
  </div>
</div>
