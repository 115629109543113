<template>
  <header class="mb-5 md:mb-6 pretty">
    <h1 v-html="heading" />
    <slot />
  </header>
</template>
<script>
export default {
  props: {
    heading: {
      required: true,
      type: String,
    },
  },
};
</script>
