
<div class="flex flex-wrap w-full">
  <div class="w-full md:flex-1 mb-2 md:mb-0">
    <form-input
      v-model="localValue"
      :input-attrs="{ placeholder: 'Enter a discount code' }"
      label="Enter a discount code"
      @enterkey="submit"
    />
  </div>
  <app-button
    class="w-full md:w-auto block md:flex items-center"
    :variants="btnVariants"
    :disabled="!localValue || !isValidPattern"
    :class="btnClasses"
    @click="submit"
  >
    <slot />
  </app-button>
</div>
