<template>
  <section>
    <h4>
      <button
        type="button"
        :aria-controls="contentId"
        class="
          font-sans uppercase leading-none font-semibold text-xs
          flex flex-wrap items-center w-full text-left
          pb-2
          pt-2
          pl-1
          pr-1
          facet-item

        "
        @click="toggleExpanded"
      >
        <div class="flex-1">{{ facet.label }}</div>
        <div class="w-auto">
          <icon
            icon="chevron-vertical"
            :class="[isExpanded ? 'rotate-180' : 'rotate-0']"
            class="w-5 transform"
          />
        </div>
      </button>
    </h4>
    <ul v-show="isExpanded" :id="contentId" class="pb-2">
      <li
        v-for="value in facet.availableValues"
        :key="facet.getKey(value)"
        class="block"
      >
        <button
          :class="[
            itemClass,
            facet.isActive(value, currentValues) ? 'facet-item--is-active' : '',
          ]"
          type="button"
          class="px-1"
          :title="`Toggle filter ${facet.label}: ${facet.formatTitle(value)}`"
          @click="toggleFacet(facet, value)"
          v-html="facet.formatValue(value)"
        />
      </li>
    </ul>
  </section>
</template>
<script>
import GoogleAnalytics from "@/js/services/googleAnalytics.js";
import Icon from "@/js/components/shared/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    facet: {
      type: Object,
      required: true,
    },
    currentValues: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    contentId() {
      return `facet-values-${this.facet.id}`;
    },
    itemClass() {
      return `facet-item`;
    },
  },
  created() {
    this.isExpanded = this.facet.isExpanded;
  },
  methods: {
    toggleFacet(facet, value) {
      GoogleAnalytics.sendEvent(
        "click",
        "Tickets",
        "Filter",
        `${facet.formatValue(value)}`
      );
      this.$emit("togglefacetValue", { facet, value });
    },
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
