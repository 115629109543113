<template>
  <app-modal max-w="xl">
    <h2 class="text-left">You have tickets in your basket</h2>
    <p class="text-left">
      Would you like to continue with your purchase and complete your order?
    </p>
    <div class="w-full">
      <app-button class="btn--gradient block w-full mb-4" @click="cancel()">
        Yes, continue purchase
        <template #icon>
          <icon icon="lock" class="w-3 mr-1" width="20" />
        </template>
      </app-button>
      <app-button class="btn--white block w-full mb-3" @click="clear()"
        >No, cancel and start again</app-button
      >
      <p class="font-sans text-center mb-0 pb-0">
        This will cancel your order and release your tickets.
      </p>
    </div>
  </app-modal>
</template>
<script>
import AppButton from "@/js/components/shared/AppButton.vue";
import AppModal from "@/js/components/shared/AppModal.vue";
import Icon from "@/js/components/shared/Icon.vue";

export default {
  components: {
    AppButton,
    AppModal,
    Icon,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    clear() {
      this.$emit("clear");
    },
  },
};
</script>
