/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";

import lineupApi from "./modules/lineupApi/index.js";
import craftApi from "./modules/craftApi.js";
import ui from "./modules/ui.js";

import ROUTES from "@/js/constants/routeNames.js";

import breakpointPlugin from "./plugins/breakpoint.js";

Vue.use(Vuex);


const isPlainObject = (obj) =>
    Object.prototype.toString.call(obj) === '[object Object]';

const getByLineupId = (arr, id) =>
    arr.find(x => x.lineupId === id);

const baseFacet = {
    component: "FacetFilter",
    valuePrefix: () => "",
    valueSuffix: () => "",
    formatValue(value) {
        return `${this.valuePrefix(value)}${value}${this.valueSuffix(value)}`;
    },
    formatTitle(value) { return this.formatValue(value) },
    getKey: (value) => value,
    isActive: (value, currentValues) => currentValues.includes(value),
};

const store = {
    modules: {
        lineupApi,
        craftApi,
        ui,
    },
    plugins: [ breakpointPlugin ],
    state: () => ({
        loading: true,
        // ticket selection
        selectedQuantity: null,
        selectedPerformance: null,
        selectedTickets: {},
        selectedTicketGroup: null,
        selectedTicketGroupKey: null,
        selectedProducts: [],
        // ticket filters
        hideSoldOutTicketGroups: true,
        appliedFilters: [],
        // access needs
        companionTicket: false,
        accessNeedsNotes: null,
        accessNeedsSet: false,
        // reservation/ticket UI state
        ticketPriceChanged: false,
        reservationHasExpired: false,
        showReservationExtensionModal: false,
        clearBasketConfirmationActive: false,
        genericError: null,
        // Thresholds for qnty warning states
        minAvailabilityForSoldOut: 0,
        // Factor added to the selectedQuantity to determine whether to show
        // a warning state for availabilty
        slotShowWarningThreshold: 3,
        performanceShowWarningThreshold: 8,
        // voucher / gate access codes
        suppliedAccessCode: null,
        urlDiscountCode: null,
        urlGiftVoucher: null,
        suppliedDiscountCode: null,
        suppliedGiftVoucher: null,
        discountCodeError: null,
        discountCodeUrlParamName: "dc",
        giftVoucherUrlParamName: "gv",
        giftVoucherProductId: null,
    }),
    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setAccessCode(state, value) {
            state.suppliedAccessCode = value;
        },
        setUrlDiscountCode(state, value) {
            state.urlDiscountCode = value;
        },
        setUrlGiftVoucher(state, value) {
            state.urlGiftVoucher = value;
        },
        setDiscountCode(state, value) {
            state.suppliedDiscountCode = value;
        },
        setGiftVoucher(state, value) {
            state.suppliedGiftVoucher = value;
        },
        setDiscountCodeError(state, value) {
            state.discountCodeError = value;
        },
        setQuantity(state, value) {
            state.selectedQuantity = value;
        },
        setSelectedPerformance(state, performance) {
            state.selectedPerformance = performance;

            if (performance) {
                Vue.set(state.selectedTickets, performance.defaultTicketId, state.selectedQuantity);
            } else {
                state.selectedTickets = {};
            }
        },
        setSelectedTickets(state, tickets) {
            if (!tickets) {
                tickets = {};
            }
            state.selectedTickets = tickets;
        },
        setSelectedTicketGroup(state, payload) {
            state.selectedTicketGroup = payload.obj;
            state.selectedTicketGroupKey = payload.key;
        },
        setHideSoldOutTicketGroups(state, shouldShow) {
            state.hideSoldOutTicketGroups = shouldShow;
        },
        clearAccessNeeds(state) {
            state.companionTicket = false;
            state.accessNeedsNotes = null;
            state.accessNeedsSet = false;
        },
        setAccessNeeds(state, value) {
            state.companionTicket = value.companionTicket;
            state.accessNeedsNotes = value.accessNeedsNotes;
            state.accessNeedsSet = true;
        },
        setTicketPriceChanged(state, value) {
            state.ticketPriceChanged = value;
        },
        setReservationHasExpired(state, value) {
            if (value) {
                state.showReservationExtensionModal = false;
            }
            state.reservationHasExpired = value;
        },
        setShowReservationExtensionModal(state, value) {
            state.showReservationExtensionModal = value;
        },
        setGiftVoucherProduct(state, productId) {
            state.giftVoucherProductId = productId;
        },
        unsetGiftVoucherProduct(state) {
            state.giftVoucherProductId = null;
        },
        removeSelectedProduct(state, productId) {
            state.selectedProducts = state.selectedProducts.filter(x => x.lineupId !== productId);
        },
        decrementSelectedProduct(state, productId) {
        let interestedProduct = getByLineupId(state.selectedProducts, productId);

            if (!interestedProduct) {
                return;
            }

            const filtered = state.selectedProducts.filter(x => x !== interestedProduct);

            interestedProduct = { ...interestedProduct };
            interestedProduct.quantity--;

            if (interestedProduct.quantity < 0) {
                interestedProduct.quantity = 0;
            }
            filtered.push(interestedProduct);
            state.selectedProducts = filtered;
        },
        incrementSelectedProduct(state, payload) {
            let productId = payload;
            let quantity = 1;

            if (isPlainObject(payload)) {
                productId = payload.productId;

                if (payload.quantity) {
                    quantity = payload.quantity;
                }
            }

            let interestedProduct = getByLineupId(state.selectedProducts, productId);

            if (!interestedProduct) {
                interestedProduct = {
                    lineupId: productId,
                    quantity,
                }
                state.selectedProducts.push(interestedProduct);
            } else {
                const filtered = state.selectedProducts.filter(x => x !== interestedProduct);

                interestedProduct = { ...interestedProduct };
                interestedProduct.quantity += quantity;
                filtered.push(interestedProduct);
                state.selectedProducts = filtered;
            }
        },
        setFilterValue(state, payload) {
            let existing = state.appliedFilters
                                .find(({ facetId }) => facetId === payload.facetId);

            const newFilters = state.appliedFilters.filter(x => x !== existing);

            if (existing) {
                if (existing.value === payload.value) {
                    existing = null;
                } else {
                    existing.value = payload.value;
                }
            } else {
                existing = {
                    facetId: payload.facetId,
                    value: payload.value
                }
            }

            state.appliedFilters = newFilters.concat([existing]).filter(Boolean);
        },
        unsetFilterValue(state, payload) {
            let existing = state.appliedFilters.find(x => x.facetId == payload.facetId);
            if (existing) {
                existing.value = payload.value;
            } else {
                existing = {
                    facetId: payload.facetId,
                    value: payload.value
                }
            }

            let newFilters = state.appliedFilters.filter(x => x.facetId != payload.facetId)
            newFilters.push(existing);
            state.appliedFilters = newFilters;
        },
        removeFilterById(state, payload) {
            let filtered = state.appliedFilters.filter(x => x.facetId != payload);
            state.appliedFilters = filtered;
        },
        clearFilters(state) {
            state.appliedFilters = [];
        },
        clearSelectedOptions(state) {
            state.selectedPerformance = null;
            state.selectedTickets = {};
            state.selectedTicketGroup = null;
            state.selectedTicketGroupKey = null;
            state.selectedProducts = [];
            state.accessNeeds = false;
            state.accessNeedsNotes = null;
            state.accessNeedsSet = false;
            state.selectedQuantity = null;
            state.appliedFilters = [];
            state.companionTicket = false;
            state.ticketPriceChanged = false;
        },
        setGenericError(state, value) {
            state.genericError = value;
        },
        setClearBasketConfirmationActive(state, value) {
            state.clearBasketConfirmationActive = value;
        },
    },
    getters: {
        ticketCost: ({
            selectedPerformance,
            selectedTickets,
        }) => {
            const getTicketPrice = (ticketId) =>
                selectedPerformance.tickets.find((t) => t.id === ticketId).price;

            return Object.entries(selectedTickets).reduce((carry, [ticketId, quantity]) => {
                return carry + (getTicketPrice(parseInt(ticketId, 10)) * quantity);
            }, 0);
        },
        eventProductCost: ({ selectedProducts }, { availableEventProducts }) => {
            return selectedProducts.reduce((total, prod) => {
                return (
                    total +
                    getByLineupId(
                        availableEventProducts,
                        prod.lineupId
                    ).price *
                    prod.quantity
                );
            }, 0);
        },
        eventTotalPrice: ({
            lineupApi,
            selectedQuantity,
            companionTicket
        },{
            eventProductCost,
            ticketCost,
        }) => {

            let basketCost = ticketCost + eventProductCost;
            let withDiscount = basketCost;
            let totalDiscount = 0;
            let bookingFee = 0;
            let subtotal = 0;

            if (lineupApi.lineupReservation) {
                bookingFee = lineupApi.lineupReservation.feeTotal  ?? 0;
                totalDiscount = lineupApi.lineupReservation.discount.totalFeeDiscount + lineupApi.lineupReservation.discount.totalTicketDiscount;
            } else if (lineupApi.tempFinalDiscount) {
                bookingFee = lineupApi.tempFinalDiscount.feeTotal  ?? 0;
                totalDiscount = lineupApi.tempFinalDiscount.totalFeeDiscount + lineupApi.tempFinalDiscount.totalTicketDiscount;
            }
            basketCost += bookingFee
            withDiscount = basketCost - totalDiscount;
            subtotal = withDiscount - bookingFee;

            // Displayed in the basket, required so that we can reduce the total price with the companion ticket taken
            // into account but before we have any discount details back from the LU reservation
            // @todo account for companion
            const toDisplay = ticketCost + eventProductCost;

            let returnData = {
                ticketCost,
                toDisplay: basketCost,
                productCost: eventProductCost,
                beforeDiscount: basketCost,
                discount: totalDiscount,
                afterDiscount: withDiscount,
                subtotal,
                bookingFee: bookingFee
            }
            return returnData;
        },
        totalQuantity(state, { ticketsForCheckout, productsForCheckout }) {
            return [...ticketsForCheckout, ...productsForCheckout].reduce((total, item) =>
                total += item.quantity, 0);
        },
        merchTotalPrice({ selectedProducts, lineupApi }, { availableMerchProducts }) {
            const basketCost = selectedProducts.reduce((total, prod) => {
                return (
                    total +
                    availableMerchProducts.find(
                        (x) => x.lineupId === prod.lineupId
                    ).price *
                    prod.quantity
                );
            }, 0);

            let totalDiscount = 0;
            let withDiscount = basketCost;

            if (lineupApi.lineupReservation) {
                totalDiscount = lineupApi.lineupReservation.discount.totalFeeDiscount
                                + lineupApi.lineupReservation.discount.totalTicketDiscount;
                withDiscount = basketCost - totalDiscount;
            } else if (lineupApi.tempFinalDiscount) {
                totalDiscount = lineupApi.tempFinalDiscount.totalFeeDiscount + lineupApi.tempFinalDiscount.totalTicketDiscount;
                withDiscount = basketCost - totalDiscount;
            }

            return {
                toDisplay: withDiscount,
                beforeDiscount: basketCost,
                discount: totalDiscount,
                afterDiscount: withDiscount,
            };
        },
        merchTotalQuantity(state, { productsForCheckout }) {
            return [...productsForCheckout].reduce((total, item) => {

                if (item.lineupId === state.giftVoucherProductId) {
                    total += 1;
                } else {
                    total += item.quantity;
                }

                return total;
            }, 0);
        },
        selectedPerformanceType(
                { selectedTicketGroup },
                getters,
                rootState,
                { expandPerformanceType }
            ) {
            if (selectedTicketGroup) {
                return expandPerformanceType(selectedTicketGroup.performanceType);
            }
        },
        getDefaultTicket: () => (performance) => {
            return performance.tickets.find((t) => t.id === performance.defaultTicketId);
        },
        ticketsForCheckout({ selectedTickets }, { getTicket }, rootState, { expandTicketType }) {
            let result = [];

            if (selectedTickets) {
                result = Object.entries(selectedTickets)
                            .map(([ticketId, quantity]) => {
                                const ticket = getTicket(ticketId);
                                const ticketType = expandTicketType(ticket.type);
                                return {
                                    title: ticketType.label,
                                    price: ticket.price,
                                    lineupId: ticket.id,
                                    quantity: quantity,
                                    total: ticket.total,
                                    faceValueTotal: ticket.faceValueTotal,
                                    outsideFeeTotal: ticket.outsideFeeTotal
                                };
                            });
            }

            return result;
        },
        getTicket: ({ selectedPerformance }) => (ticketId) =>
            selectedPerformance.tickets.find(
                (t) => t.id === parseInt(ticketId, 10)
            ),
        productsForCheckout({ selectedProducts }, { availableEventProducts }) {
            return selectedProducts.map((p) => ({
                ...p,
                ...getByLineupId(availableEventProducts, p.lineupId),
            }));
        },
        merchProductsForCheckout({ selectedProducts }, { availableMerchProducts }) {
            return selectedProducts.map((p) => ({
                ...p,
                ...getByLineupId(availableMerchProducts, p.lineupId),
            }));
        },
        quantityFacet(state, getters, rootState, { ticketQuantities, availableTicketQuanities }) {
            return {
                ...baseFacet,
                isExpanded: false,
                label: "Number of tickets",
                id: "qnty",
                valueSuffix: (qnty) => ` Ticket${qnty === 1 ? `` : `s`}`,
                allValues: ticketQuantities.map((qnty) => qnty.value),
                availableValues: availableTicketQuanities,
            };
        },
        facets({ craftApi }) {
            const { event } = craftApi;

            if (!event) {
                return [];
            }

            const days = [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
            ];

            const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];

            const priceRanges = [
                {
                    min: 0,
                    max: 55,
                    label: `Up to &pound;55`,
                    title: `Up to £55`,
                },{
                    min: 0,
                    max: 70,
                    label: `Up to &pound;70`,
                    title: `Up to £70`,
                },{
                    min: 0,
                    max: 100,
                    label: `Up to &pound;100`,
                    title: `Up to £100`,
                },{
                    min: 100,
                    max: 150,
                    label: `&pound;100-&pound;150`,
                    title: `£100-£150`,
                },
            ];

            const performanceTypes = [...new Set(Object.keys(event.ticketGroups).map(key => {
                return event.ticketGroups[key].displayName;
            }))].sort()



            return [ {
                ...baseFacet,
                    label: "Performance Type",
                    id: "performanceType",
                    isExpanded: true,
                    allValues: performanceTypes,
                    availableValues: performanceTypes,
                },{
                ...baseFacet,
                    label: "Price",
                    isExpanded: true,
                    id: "price",
                    component: "BucketFacetFilter",
                    options: {
                        buckets: priceRanges,
                    },
                    formatValue: ({ label }) => label,
                    formatTitle: (value) => value.title ?? value.label,
                    getKey: ({ min, max }) => `${min}-${max}`,
                    allValues: priceRanges,
                    availableValues: priceRanges,
                    isActive: ({ label }, currentValues) =>
                        currentValues.map((v) => v.label)
                                     .includes(label),
                },{
                    ...baseFacet,
                    label: "Time of Day",
                    id: "timeslot",
                    allValues: event.ticketTimeGroups.map(x => x.name),
                    availableValues: event.ticketTimeGroups.map(x => x.name),
                },{
                    ...baseFacet,
                    label: "Day of the week",
                    id: "dotw",
                    allValues: days,
                    //The `Set` stuff reduces the array to a set of uniques before sorting
                    availableValues: [
                        ...new Set(
                            Object.keys(event.ticketGroups)
                                  .map(key => moment(event.ticketGroups[key].date).format('dddd'))
                            )].sort((a, b) => days.indexOf(a) - days.indexOf(b)),
                }, {
                    ...baseFacet,
                    label: "Month",
                    id: "month",
                    allValues: months,
                    availableValues: [
                        ...new Set(
                            Object.keys(event.ticketGroups)
                                  .map(key => moment(event.ticketGroups[key].date).format('MMMM'))
                            )].sort((a, b) => months.indexOf(a) - months.indexOf(b)),
                }
            ];
        },
        timeslotFilter(state, { getFilterById }) {
            return getFilterById("timeslot");
        },
        priceFilter(state, { getFilterById }) {
            return getFilterById("price");
        },
        getFilterById: ({ appliedFilters }) => (facetId) => {
            return appliedFilters.find((f) => f.facetId === facetId);
        },
    },
    actions: {
        toggleFacetValue({ state, commit }, { facet, value }) {
            commit('setFilterValue', {
                facetId: facet.id,
                value: value
            });
        },
        async clearBasketAndReset({ state, commit, dispatch }) {
            await dispatch('clearReservation');
            commit('clearSelectedOptions');
            commit('setClearBasketConfirmationActive', false);
        },
        addTicket({ state, commit, getters }, { ticketId }) {
            const getTicket = (id) => state.selectedPerformance.tickets.find((t) => t.id === id);
            const defaultTicketId = state.selectedPerformance.defaultTicketId;

            const minQuantityFor = (ticket) => {
                const { minQuantity = 0 } = getters.expandTicketType(ticket.type);

                return minQuantity;
            };

            const ticketToIncrement = getTicket(ticketId);

            if (!ticketToIncrement) {
                throw new Error(`Ticket id ${ticketId} not valid for performance ${state.performance.id}`);
            }

            let ticketIdToDecrement = defaultTicketId;

            if (ticketId === defaultTicketId) {
                ticketIdToDecrement = Object.entries(state.selectedTickets)
                                            .map(([id, qnty]) => [parseInt(id), qnty])
                                            .filter(([id, qnty]) => id !== defaultTicketId && qnty > 0)
                                            .map(([id]) => id)[0] ?? null;

            }

            const currentQnty = state.selectedTickets[ticketId] ?? 0;

            if (
                !ticketIdToDecrement
                // don't allow decrement of default ticket type below minimum
                || state.selectedTickets[ticketIdToDecrement] === minQuantityFor(getTicket(ticketIdToDecrement))
                // don't allow increment beyond selected qnty
                || (currentQnty === state.selectedQuantity)
                // don't allow increment beyond max avail qnty for this ticket type
                || (currentQnty === ticketToIncrement.available)
            ) {
                return;
            } else {
                commit("setSelectedTickets", {
                    ...state.selectedTickets,
                    [ticketId]: currentQnty + 1,
                    [ticketIdToDecrement]: state.selectedTickets[ticketIdToDecrement] - 1,
                });
            }
        },
        removeTicket({ state, commit, getters, rootState }, { ticketId }) {
            const ticket = state.selectedPerformance.tickets.find((t) => t.id === ticketId);

            if (!ticket) {
                throw new Error(`Ticket id ${ticketId} not valid for performance ${state.performance.id}`);
            }

            const minQuantityFor = (ticket) => {
                const { minQuantity = 0 } = getters.expandTicketType(ticket.type);

                return minQuantity;
            };

            const defaultTicketId = state.selectedPerformance.defaultTicketId;

            // if decrementing a subticket, increment the default
            let ticketIdToIncrement = defaultTicketId;

            // else if decrementing the default ticket, increment the first other incrementable ticket
            if (ticketId === defaultTicketId) {
                ticketIdToIncrement = state.selectedPerformance.tickets
                                            .filter(({ id }) => id !== defaultTicketId)
                                            .filter(({ available, id }) => available > (state.selectedTickets[id] ?? 0))
                                            .map(({ id }) => id)[0] ?? null;
            }

            // only decrement if we can add elsewhere and doing so won't take us below min qnty
            if (!ticketIdToIncrement
                || state.selectedTickets[ticketId] === minQuantityFor(ticket)
                || !state.selectedTickets[ticketId]
            ) {
                return;
            } else {
                commit("setSelectedTickets", {
                    ...state.selectedTickets,
                    [ticketId]: state.selectedTickets[ticketId] - 1,
                    [ticketIdToIncrement]: (state.selectedTickets[ticketIdToIncrement] ?? 0) + 1,
                });
            }
        },
    },
    strict: process.env.NODE_ENV !== 'production',
}

export default new Vuex.Store(store);