/* eslint-disable */
const defaultDuration = 500;


const isString = (str) => typeof str === "string" || str instanceof String;

function easeInOutQuad(opts) {

    let {currentTime, start, change, duration} = opts;

    currentTime = currentTime / (duration / 2);
    if (currentTime < 1) {
        return change / 2 * currentTime * currentTime + start;
    }
    currentTime--;

    return - change / 2 * (currentTime * (currentTime - 2) - 1) + start;
}

export function getOffsetToBody(elem) {

    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docElem = document.documentElement;

    const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;

    const clientTop = docElem.clientTop || body.clientTop || 0;
    const clientLeft = docElem.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return {
        top: Math.round(top),
        left: Math.round(left),
    };
};


export function scrollTo(element, to, duration) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;

    let currentTime = 0;

    function animateScroll(opts){

        let {currentTime, start, change, duration, increment} = opts;

        currentTime += increment;

        let val = easeInOutQuad({currentTime, start, change, duration});

        element.scrollTop = val;

        if(currentTime < duration) {
            setTimeout(function(){
                animateScroll({currentTime, start, change, duration, increment});
            }, increment);
        }
    }

    animateScroll({currentTime, start, change, duration, increment});
}



export function scrollToTarget(selector, {
        duration = defaultDuration,
        offset = 0,
    } = {}) {

    return new Promise((resolve, reject) => {

        let target;

        if (isString(selector)) {
            target = document.querySelector(selector);
        } else if (selector instanceof Element) {
            target = selector;
        } else {
            reject(`[scroll-to] no element for ${selector}`);
        }

        const targetOffset = getOffsetToBody(target);

        scrollTo(document.body, targetOffset.top - offset, duration);
        scrollTo(document.documentElement, targetOffset.top - offset, duration);

        setTimeout(() => resolve(), duration);
    });
}

export function scrollToTop(duration) {
    duration = duration || defaultDuration;

    scrollToTarget('body', duration);
}


export default {
    methods: {
        scrollToTarget,
        scrollToTop,
    },
};