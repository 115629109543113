
<div class>
  <app-hero-featured :event="craftEvent" :banner="pageContent.gateBanner" />
  <section class="container-md mt-6 md:mt-8">
    <div class="text-center mb-4 md:mb-6 xl:mb-8">
      <!-- Header -->
      <h1
        class="text-4xl sm:text-5xl md:text-6xl xl:text-7xl uppercase font-display font-semibold leading-tight block"
      >
        {{ pageContent.gateTitle }}
      </h1>
      <span
        class="text-lg sm:text-xl md:text-3xl xl:text-4xl font-display block leading-tight"
        >Book Now - ends at {{ show.presaleEnd.format("h:mma") }} sharp
        {{ show.presaleEnd.format("ddd D MMM") }}</span
      >
    </div>
    <!-- Countdown -->
    <div class="mb-4 md:mb-8">
      <presale-countdown :end-time="show.presaleEnd" />
    </div>

    <div class="pretty text-center">
      <p v-html="promptText"></p>
    </div>

    <div class="flex flex-wrap mb-8 w-10/12 mx-auto">
      <app-apply-discount-code
        :btn-classes="['mt-3', 'md:mt-0']"
        :btn-variants="['gradient']"
        :value="inputCode"
        @submit="checkDiscountCode"
        @changevalidity="handleLocalValidityChange"
      >
        Find Tickets
      </app-apply-discount-code>
    </div>
    <app-loader v-if="loading" />
    <app-callout v-if="errorMessage" type="warning">
      <p>{{ errorMessage }}</p>
    </app-callout>
    <app-callout v-else-if="localErrorMessage && !loading" type="warning">
      <p>{{ localErrorMessage }}</p>
    </app-callout>
  </section>
  <!-- @todo not sure if these details need to be event specific - also might need to be free HTML to include notification form -->
  <section
    class="container-md mt-6 md:mt-8 pretty"
    v-html="pageContent.gateInstructions"
  ></section>
</div>
