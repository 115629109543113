
<footer class="bg-gray-light py-8">
  <section class="container-md">
    <nav v-if="footerNavItems"
        aria-labelledby="footermenulabel" class="mb-3 md:mb-5 grid gap-6 grid-cols-1 xs:grid-cols-2">
      <h3 id="footermenulabel" class="sr-only">Footer Navigation</h3>

      <div
        v-for="(group, grpIdx) in footerNavItems.groups"
        :key="grpIdx">
        <h4 class="text-dark text-base md:text-md font-bold mb-6" v-if="group.heading" v-html="group.heading" />
        <ul class="">
          <li
            v-for="(item, idx) in group.links"
            :key="item.url"
            class="my-2"
          >
            <a
              :href="item.url"
              target="_blank"
              class="text-xs md:text-sm block hover:underline focus:underline"
              v-html="item.text"
            />
          </li>
        </ul>
      </div>
    </nav>
    <small class="text-gray-dark text-2xs font-sans block mb-1">
      <div v-html="footerSmallText" />
    </small>
  </section>
</footer>
