
<section class="container-md mt-6 md:mt-8">
  <div class=" flex flex-wrap">
    <div class="flex-1">
      <span class="h1 mb-0 font-sans mb-2 md:mb-3">{{
        formatDateWithYear(selectedDate)
      }}</span>
      <span class="block font-sans font-bold text-md md:text-lg mb-3"
        >{{ selectedQuantity }} x {{ selectedPerformanceType.label }} ticket{{
          selectedQuantity > 1 ? "s" : ""
        }}</span
      >
    </div>
  </div>

  <app-loader v-if="loading" />
  <div v-else>
    <div class="mb-5 md:mb-8 flex flex-wrap">
      <div class="flex-1">
        <span class="block font-sans leading-tight text-md md:text-lg"
          >
          <span class="font-semibold" v-html="selectedPriceRange"/>&nbsp;<span class="text-gray-500 text-xs" v-html="bookingFeeRange"/>
          </span>
      </div>
      <div class="w-auto">
        <app-button :variants="['small', 'white']" @click="startAgain">
          Start again
        </app-button>
      </div>
    </div>

    <div>
      <div class="mb-3 md:mb-5 flex flex-wrap items-end">
        <template v-if="performancesShown.length">
          <h1 class="md:flex-1 h4">Choose your time</h1>

          <div class="ml-auto md:w-auto">
            <app-toggle
              :is-checked="!hideUnavailable"
              class="font-sans uppercase leading-none font-semibold text-xs"
              label="Show all times"
              @toggle="toggleSoldOut"
            />
          </div>
        </template>
        <template v-else>
          <h1 class="w-full h4">Sorry, no matching timeslots found</h1>
          <app-button
            :variants="['white', 'small']"
            class="mt-4"
            @click="$router.back()"
            >Go back</app-button
          >
        </template>
      </div>
      <div class="flex flex-wrap mb-5 md:mb-8 -mx-1">
        <performance-item
          v-for="performance in performancesShown"
          :key="performance.ticketId"
          :performance="performance"
          :availability="
            adjustTotalAvailableForTicketId(
              performance.ticketId,
              performance.available
            )
          "
          class="mb-2"
          @selected="selectPerformance"
        />
      </div>
    </div>
  </div>
  <ticket-type-quantity-selection v-if="selectedPerformance" />
</section>
